import { ReactNode } from 'react'
import { Control, Controller, FieldValues } from 'react-hook-form'

import { AutoResizingTextField, NewCheckBox } from '@/components'
import { AceEditorWithoutForm } from '@/components/AceEditor/AceEditor'
import { useTableContext } from '@/components/NewTable'
import { Select } from '@/components/UberForm'
import { ItemPropertyType } from '@/enums'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'
import { TableColumnFlat } from '@/store/modules/table/types'
import { TableColumnDefinition } from '@/types'

import { getUnavailableOptionClass } from './helper'

interface FormFieldsProps {
	control: Control<FieldValues, any>
	fieldName: string
	fieldProp: TableColumnDefinition<any>
	selectOptions?: TableColumnFlat[]
}

export const formFieldSwitch: Record<
	ItemPropertyType,
	(props: FormFieldsProps) => ReactNode
> = {
	[ItemPropertyType.ACE_EDITOR]: ({ control, fieldName }) => {
		const {
			state: { editMode },
		} = useDetailTabContext()

		return (
			<Controller
				control={control}
				name={fieldName}
				render={({ field }) => {
					return <AceEditorWithoutForm disabled={!editMode} {...field} />
				}}
			/>
		)
	},
	[ItemPropertyType.CHECKBOX]: ({ control, fieldName }) => {
		const {
			state: { editMode },
		} = useDetailTabContext()
		return (
			<Controller
				control={control}
				name={fieldName}
				render={({ field }) => (
					<NewCheckBox
						className="justify-start"
						checked={field.value}
						disabled={!editMode}
						{...field}
					/>
				)}
			/>
		)
	},
	[ItemPropertyType.OPTION]: ({
		control,
		fieldProp,
		fieldName,
		selectOptions,
	}) => {
		const {
			state: { editMode },
		} = useDetailTabContext()

		const { invalidColumnCodes, selectedRow } = useTableContext()

		const className = getUnavailableOptionClass(
			selectedRow.columnCode,
			invalidColumnCodes,
		)
		return (
			<Controller
				defaultValue={null}
				control={control}
				name={fieldName}
				render={({ field }) => (
					<Select
						className={className}
						options={selectOptions}
						valueKey={fieldProp.optionValue}
						labelKey={fieldProp.optionLabel}
						clearable
						disabled={!editMode}
						{...field}
					/>
				)}
			/>
		)
	},
	[ItemPropertyType.TEXTAREA]: ({ control, fieldName }) => {
		const {
			state: { editMode },
		} = useDetailTabContext()
		return (
			<Controller
				control={control}
				name={fieldName}
				render={({ field }) => (
					<AutoResizingTextField
						className="w-full"
						disabled={!editMode}
						{...field}
					/>
				)}
			/>
		)
	},
	[ItemPropertyType.RIGHT_ACTION]: () => <></>,
}
