import {
	faDatabase,
	faDownload,
	faServer,
	faTimes,
} from '@fortawesome/free-solid-svg-icons'
import FileSaver from 'file-saver'
import { useCallback, useMemo, useState } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import {
	createDeploy,
	generateAndPushToGit,
	generateSql,
	generateSqlPreviewEnvironment,
	getEnvironmentsWithLastDeploymentPerObjectType,
} from 'src/endpoints'

import { Button, Loader, Message, MessageModal } from '@/components'
import { AceEditorWithoutForm } from '@/components/AceEditor/AceEditor'
import { Modal } from '@/components/Modal/Modal'
import { DDL_MODAL_ID } from '@/constants'
import { getApiRef, useApiRequest } from '@/endpoints/hooks'
import { useAppContext } from '@/hooks'
import { DdlModalComponentProps } from '@/pages/User/pages/Home/components/Deployments/types'
import { useGeneratedSql } from '@/utils/sql'

import { generateHeaderTitle } from '../utils'

const DdlModalComponent = ({
	node,
	environment,
	preview,
	drop,
	onClose,
}: DdlModalComponentProps) => {
	const { t } = useAppContext()
	const request = useApiRequest()

	const [successShown, setSuccessShown] = useState(false)
	const [deploying, setDeploying] = useState(false)
	const [pushingToGit, setPushingToGit] = useState(false)
	const [successGitShown, setSuccessGitShown] = useState(false)

	const [sqlPreview, loadingPreview, errorPreview] = useGeneratedSql(
		generateSqlPreviewEnvironment(
			node.id ?? 0,
			environment.id ?? 0,
			environment.objectType?.id ?? 0,
		),
		[node.id, environment.id],
	)

	const [sqlProd, loadingProd, errorProd] = useGeneratedSql(
		generateSql(
			node.id ?? 0,
			environment.id ?? 0,
			environment.objectType?.id as number,
		),
		[node.id, environment.id],
	)

	const { sql, loading, error } = useMemo(
		() =>
			preview
				? { sql: sqlPreview, loading: loadingPreview, error: errorPreview }
				: { sql: sqlProd, loading: loadingProd, error: errorProd },
		[
			errorPreview,
			errorProd,
			loadingPreview,
			loadingProd,
			preview,
			sqlPreview,
			sqlProd,
		],
	)

	const saveFile = useCallback(
		(fileName: string, blobPart: BlobPart[], type?: string) => {
			const blob = new Blob(blobPart, {
				type: type ?? 'text/plain;charset=utf-8',
			})

			FileSaver.saveAs(blob, fileName)
		},
		[],
	)

	const handleDownload = useCallback(() => {
		if (sql) {
			saveFile(`${sql.fileName ?? node.name}`, [sql.sql])
		}
	}, [sql, saveFile, node.name])

	const pushToGit = async () => {
		setPushingToGit(true)

		await request(
			generateAndPushToGit(
				node.id ?? 0,
				environment.id ?? 0,
				environment.objectType?.id as number,
			),
		)

		setPushingToGit(false)
		setSuccessGitShown(true)
	}

	const handleDeploy = async () => {
		if (!sql) {
			return
		}

		setDeploying(true)

		await request(
			createDeploy(
				node.id ?? 0,
				environment.id ?? 0,
				environment.objectType?.id as number,
				{
					data: sql.sql,
					description: '-',
					structureHistoryId: sql.structureHistoryId,
					templateId: sql.template.id || 0,
				},
			),
		)

		setDeploying(false)
		setSuccessShown(true)
	}

	const handleInfoClose = async () => {
		getApiRef(
			getEnvironmentsWithLastDeploymentPerObjectType(node.id ?? 0),
		).invalidate()

		onClose()
	}

	const footer = () => (
		<>
			{!preview && (
				<Button onClick={handleDeploy} icon={faDatabase} isLoading={deploying}>
					{t('MARK_AS_DEPLOYED')}
				</Button>
			)}
			<Button icon={faDownload} onClick={handleDownload}>
				{t('DOWNLOAD')}
			</Button>
			{!preview && (
				<Button onClick={pushToGit} icon={faServer} isLoading={pushingToGit}>
					{t('PUSH_TO_GIT')}
				</Button>
			)}
			<Button onClick={onClose} schema="transparent" icon={faTimes}>
				{t('CLOSE')}
			</Button>
		</>
	)

	const renderMessages = (errorArray?: string[]) => (
		<>
			{errorArray?.map((error) => (
				<Message key={error} type="error" header={t('ERROR')} message={error} />
			))}
		</>
	)

	const errorMessageContent = (
		<>
			<Message type="error" header={t('DDL_CREATION_FAILED')} message={error} />
			{renderMessages(sql?.errors)}
		</>
	)

	const sqlContent = (
		<>
			{renderMessages(sql?.errors)}
			<AutoSizer>
				{({ width, height }: { height: number; width: number }) => (
					<div style={{ height: height, width }}>
						<AceEditorWithoutForm
							name="sqlData"
							value={sql?.sql}
							disabled
							height={height}
						/>
					</div>
				)}
			</AutoSizer>
		</>
	)

	const renderModalContent = () => {
		if (error) {
			return errorMessageContent
		}

		if (loading) {
			return <Loader loaded={false} />
		}

		return sqlContent
	}

	return (
		<>
			<Modal
				open
				onClose={onClose}
				stretchFooterButtons={false}
				resizable
				maximized
				maximizeButtonVisible
				dialogId={DDL_MODAL_ID}
				header={generateHeaderTitle(t, drop, preview)}
				contentStyle={{ width: 800, minHeight: 530, minWidth: 530 }}
				footer={footer}
			>
				{renderModalContent()}
			</Modal>

			<MessageModal
				type="info"
				opened={successShown}
				onClose={handleInfoClose}
				title={t('DEPLOYMENT')}
				message={t('DEPLOYMENT_MARK_CREATED')}
			/>

			<MessageModal
				type="info"
				opened={successGitShown}
				onClose={handleInfoClose}
				title={t('DEPLOYMENT_GIT')}
				message={t('DEPLOYMENT_GIT_PUSHED')}
			/>
		</>
	)
}

export const DdlModal = DdlModalComponent
