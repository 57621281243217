import { emptySplitApi as api } from './emptyApi'
const injectedRtkApi = api.injectEndpoints({
	endpoints: (build) => ({
		getAcronyms: build.query<GetAcronymsApiResponse, GetAcronymsApiArg>({
			query: (queryArg) => ({
				url: `/acronyms`,
				params: {
					page: queryArg.page,
					size: queryArg.size,
					searchQuery: queryArg.searchQuery,
				},
			}),
		}),
		createAcronym: build.mutation<
			CreateAcronymApiResponse,
			CreateAcronymApiArg
		>({
			query: (queryArg) => ({
				url: `/acronyms`,
				method: 'POST',
				body: queryArg.acronymCreateDto,
			}),
		}),
		getAcronym: build.query<GetAcronymApiResponse, GetAcronymApiArg>({
			query: (queryArg) => ({ url: `/acronyms/${queryArg.acronymId}` }),
		}),
		updateAcronym: build.mutation<
			UpdateAcronymApiResponse,
			UpdateAcronymApiArg
		>({
			query: (queryArg) => ({
				url: `/acronyms/${queryArg.acronymId}`,
				method: 'PUT',
				body: queryArg.acronymUpdateDto,
			}),
		}),
		deleteAcronym: build.mutation<
			DeleteAcronymApiResponse,
			DeleteAcronymApiArg
		>({
			query: (queryArg) => ({
				url: `/acronyms/${queryArg.acronymId}`,
				method: 'DELETE',
			}),
		}),
		getAuditLogs: build.query<GetAuditLogsApiResponse, GetAuditLogsApiArg>({
			query: (queryArg) => ({
				url: `/audit/${queryArg.entityType}/${queryArg.entityId}`,
			}),
		}),
		getAllBusinessDomains: build.query<
			GetAllBusinessDomainsApiResponse,
			GetAllBusinessDomainsApiArg
		>({
			query: () => ({ url: `/business-domains` }),
		}),
		createBusinessDomain: build.mutation<
			CreateBusinessDomainApiResponse,
			CreateBusinessDomainApiArg
		>({
			query: (queryArg) => ({
				url: `/business-domains`,
				method: 'POST',
				body: queryArg.businessDomainDto,
			}),
		}),
		getAllBusinessDomainsForCombo: build.query<
			GetAllBusinessDomainsForComboApiResponse,
			GetAllBusinessDomainsForComboApiArg
		>({
			query: () => ({ url: `/business-domains/combo` }),
		}),
		getBusinessDomain: build.query<
			GetBusinessDomainApiResponse,
			GetBusinessDomainApiArg
		>({
			query: (queryArg) => ({
				url: `/business-domains/${queryArg.businessDomainId}`,
			}),
		}),
		updateBusinessDomain: build.mutation<
			UpdateBusinessDomainApiResponse,
			UpdateBusinessDomainApiArg
		>({
			query: (queryArg) => ({
				url: `/business-domains/${queryArg.businessDomainId}`,
				method: 'PUT',
				body: queryArg.businessDomainDto,
			}),
		}),
		deleteBusinessDomain: build.mutation<
			DeleteBusinessDomainApiResponse,
			DeleteBusinessDomainApiArg
		>({
			query: (queryArg) => ({
				url: `/business-domains/${queryArg.businessDomainId}`,
				method: 'DELETE',
			}),
		}),
		getCompany: build.query<GetCompanyApiResponse, GetCompanyApiArg>({
			query: () => ({ url: `/company` }),
		}),
		getAllCustomAttributeStructureTypeDto: build.query<
			GetAllCustomAttributeStructureTypeDtoApiResponse,
			GetAllCustomAttributeStructureTypeDtoApiArg
		>({
			query: () => ({ url: `/custom-attribute-structure-types` }),
		}),
		getAllCustomAttributes: build.query<
			GetAllCustomAttributesApiResponse,
			GetAllCustomAttributesApiArg
		>({
			query: () => ({ url: `/custom-attributes` }),
		}),
		createCustomAttribute: build.mutation<
			CreateCustomAttributeApiResponse,
			CreateCustomAttributeApiArg
		>({
			query: (queryArg) => ({
				url: `/custom-attributes`,
				method: 'POST',
				body: queryArg.customAttributeCreateDto,
			}),
		}),
		getAllCustomAttributesForCombo: build.query<
			GetAllCustomAttributesForComboApiResponse,
			GetAllCustomAttributesForComboApiArg
		>({
			query: (queryArg) => ({
				url: `/custom-attributes/combo/${queryArg.structureType}`,
			}),
		}),
		getCustomAttribute: build.query<
			GetCustomAttributeApiResponse,
			GetCustomAttributeApiArg
		>({
			query: (queryArg) => ({
				url: `/custom-attributes/${queryArg.customAttributeId}`,
			}),
		}),
		updateCustomAttribute: build.mutation<
			UpdateCustomAttributeApiResponse,
			UpdateCustomAttributeApiArg
		>({
			query: (queryArg) => ({
				url: `/custom-attributes/${queryArg.customAttributeId}`,
				method: 'PUT',
				body: queryArg.customAttributeUpdateDto,
			}),
		}),
		deleteCustomAttribute: build.mutation<
			DeleteCustomAttributeApiResponse,
			DeleteCustomAttributeApiArg
		>({
			query: (queryArg) => ({
				url: `/custom-attributes/${queryArg.customAttributeId}`,
				method: 'DELETE',
			}),
		}),
		getAllCustomLovs: build.query<
			GetAllCustomLovsApiResponse,
			GetAllCustomLovsApiArg
		>({
			query: () => ({ url: `/custom-lov` }),
		}),
		createCustomLov: build.mutation<
			CreateCustomLovApiResponse,
			CreateCustomLovApiArg
		>({
			query: (queryArg) => ({
				url: `/custom-lov`,
				method: 'POST',
				body: queryArg.customLovCreateDto,
			}),
		}),
		getAllCustomLovForCombo: build.query<
			GetAllCustomLovForComboApiResponse,
			GetAllCustomLovForComboApiArg
		>({
			query: () => ({ url: `/custom-lov/combo` }),
		}),
		getCustomLov: build.query<GetCustomLovApiResponse, GetCustomLovApiArg>({
			query: (queryArg) => ({ url: `/custom-lov/${queryArg.customLovId}` }),
		}),
		updateCustomLov: build.mutation<
			UpdateCustomLovApiResponse,
			UpdateCustomLovApiArg
		>({
			query: (queryArg) => ({
				url: `/custom-lov/${queryArg.customLovId}`,
				method: 'PUT',
				body: queryArg.customLovUpdateDto,
			}),
		}),
		deleteCustomLov: build.mutation<
			DeleteCustomLovApiResponse,
			DeleteCustomLovApiArg
		>({
			query: (queryArg) => ({
				url: `/custom-lov/${queryArg.customLovId}`,
				method: 'DELETE',
			}),
		}),
		getCustomLovForComboById: build.query<
			GetCustomLovForComboByIdApiResponse,
			GetCustomLovForComboByIdApiArg
		>({
			query: (queryArg) => ({
				url: `/custom-lov/${queryArg.customLovId}/combo`,
			}),
		}),
		getAllNodeDeployments: build.query<
			GetAllNodeDeploymentsApiResponse,
			GetAllNodeDeploymentsApiArg
		>({
			query: (queryArg) => ({
				url: `/deployments/structure/${queryArg.structureId}`,
				params: { sqlType: queryArg.sqlType },
			}),
		}),
		getAllSpDeployments: build.query<
			GetAllSpDeploymentsApiResponse,
			GetAllSpDeploymentsApiArg
		>({
			query: (queryArg) => ({
				url: `/deployments/structure/${queryArg.structureId}/environments/${queryArg.environmentId}/objectTypes/${queryArg.objectTypeId}`,
			}),
		}),
		createDeploy: build.mutation<CreateDeployApiResponse, CreateDeployApiArg>({
			query: (queryArg) => ({
				url: `/deployments/structure/${queryArg.structureId}/environments/${queryArg.environmentId}/objectTypes/${queryArg.objectTypeId}`,
				method: 'POST',
				body: queryArg.deploymentNewDto,
			}),
		}),
		getDeploymentDetail: build.query<
			GetDeploymentDetailApiResponse,
			GetDeploymentDetailApiArg
		>({
			query: (queryArg) => ({ url: `/deployments/${queryArg.deploymentId}` }),
		}),
		getAllDomains: build.query<GetAllDomainsApiResponse, GetAllDomainsApiArg>({
			query: () => ({ url: `/domains` }),
		}),
		createDomain: build.mutation<CreateDomainApiResponse, CreateDomainApiArg>({
			query: (queryArg) => ({
				url: `/domains`,
				method: 'POST',
				body: queryArg.domainCreateDto,
			}),
		}),
		getAllDomainsForCombo: build.query<
			GetAllDomainsForComboApiResponse,
			GetAllDomainsForComboApiArg
		>({
			query: () => ({ url: `/domains/combo` }),
		}),
		getAllDomainsFilteredBySystemTechnology: build.query<
			GetAllDomainsFilteredBySystemTechnologyApiResponse,
			GetAllDomainsFilteredBySystemTechnologyApiArg
		>({
			query: (queryArg) => ({
				url: `/domains/system/${queryArg.systemStructureId}`,
			}),
		}),
		getAllDomainsFilteredBySystemTechnologyDep: build.query<
			GetAllDomainsFilteredBySystemTechnologyDepApiResponse,
			GetAllDomainsFilteredBySystemTechnologyDepApiArg
		>({
			query: (queryArg) => ({ url: `/domains/${queryArg.domainId}` }),
		}),
		updateDomain: build.mutation<UpdateDomainApiResponse, UpdateDomainApiArg>({
			query: (queryArg) => ({
				url: `/domains/${queryArg.domainId}`,
				method: 'PUT',
				body: queryArg.domainUpdateDto,
				params: { updateElements: queryArg.updateElements },
			}),
		}),
		deleteDomain: build.mutation<DeleteDomainApiResponse, DeleteDomainApiArg>({
			query: (queryArg) => ({
				url: `/domains/${queryArg.domainId}`,
				method: 'DELETE',
			}),
		}),
		getColumnsByDomain: build.query<
			GetColumnsByDomainApiResponse,
			GetColumnsByDomainApiArg
		>({
			query: (queryArg) => ({ url: `/domains/${queryArg.domainId}/column` }),
		}),
		getColumnsByDomainCsv: build.query<
			GetColumnsByDomainCsvApiResponse,
			GetColumnsByDomainCsvApiArg
		>({
			query: (queryArg) => ({
				url: `/domains/${queryArg.domainId}/column/csv`,
			}),
		}),
		computeImpactDomainChange: build.mutation<
			ComputeImpactDomainChangeApiResponse,
			ComputeImpactDomainChangeApiArg
		>({
			query: (queryArg) => ({
				url: `/domains/${queryArg.domainId}/impact`,
				method: 'POST',
				body: queryArg.domainUpdateDto,
			}),
		}),
		getEnvironments: build.query<
			GetEnvironmentsApiResponse,
			GetEnvironmentsApiArg
		>({
			query: (queryArg) => ({
				url: `/environments/structure/${queryArg.structureId}`,
			}),
		}),
		getEnvironmentsWithLastDeployment: build.query<
			GetEnvironmentsWithLastDeploymentApiResponse,
			GetEnvironmentsWithLastDeploymentApiArg
		>({
			query: (queryArg) => ({
				url: `/environments/structure/${queryArg.structureId}/deployments`,
			}),
		}),
		getEnvironmentsWithLastDeploymentPerObjectType: build.query<
			GetEnvironmentsWithLastDeploymentPerObjectTypeApiResponse,
			GetEnvironmentsWithLastDeploymentPerObjectTypeApiArg
		>({
			query: (queryArg) => ({
				url: `/environments/structure/${queryArg.structureId}/deploymentsWithObjectType`,
			}),
		}),
		getEnvironmentById: build.query<
			GetEnvironmentByIdApiResponse,
			GetEnvironmentByIdApiArg
		>({
			query: (queryArg) => ({ url: `/environments/${queryArg.environmentId}` }),
		}),
		getInitInfo: build.query<GetInitInfoApiResponse, GetInitInfoApiArg>({
			query: () => ({ url: `/init` }),
		}),
		getNamings: build.query<GetNamingsApiResponse, GetNamingsApiArg>({
			query: () => ({ url: `/naming` }),
		}),
		createNaming: build.mutation<CreateNamingApiResponse, CreateNamingApiArg>({
			query: (queryArg) => ({
				url: `/naming`,
				method: 'POST',
				body: queryArg.namingDto,
			}),
		}),
		updateNamings: build.mutation<
			UpdateNamingsApiResponse,
			UpdateNamingsApiArg
		>({
			query: (queryArg) => ({
				url: `/naming`,
				method: 'PUT',
				body: queryArg.body,
			}),
		}),
		dummyNaming: build.mutation<DummyNamingApiResponse, DummyNamingApiArg>({
			query: (queryArg) => ({
				url: `/naming/dummy`,
				method: 'POST',
				body: queryArg.table,
			}),
		}),
		executeNaming: build.mutation<
			ExecuteNamingApiResponse,
			ExecuteNamingApiArg
		>({
			query: (queryArg) => ({
				url: `/naming/execute/${queryArg.namingType}/${queryArg.structureId}`,
				method: 'POST',
				params: {
					sequence_column: queryArg.sequenceColumn,
					master_node_name: queryArg.masterNodeName,
					master_node_code: queryArg.masterNodeCode,
				},
			}),
		}),
		executeNamingOverWorkingData: build.mutation<
			ExecuteNamingOverWorkingDataApiResponse,
			ExecuteNamingOverWorkingDataApiArg
		>({
			query: (queryArg) => ({
				url: `/naming/execute/${queryArg.namingType}/${queryArg.structureId}/${queryArg.sqlType}`,
				method: 'POST',
				body: queryArg.structureWorkingDataDto,
				params: {
					sequence_column: queryArg.sequenceColumn,
					master_node_name: queryArg.masterNodeName,
					master_node_code: queryArg.masterNodeCode,
				},
			}),
		}),
		getNaming: build.query<GetNamingApiResponse, GetNamingApiArg>({
			query: (queryArg) => ({ url: `/naming/${queryArg.namingType}` }),
		}),
		deleteNaming: build.mutation<DeleteNamingApiResponse, DeleteNamingApiArg>({
			query: (queryArg) => ({
				url: `/naming/${queryArg.namingType}`,
				method: 'DELETE',
			}),
		}),
		getAllObjectSettings: build.query<
			GetAllObjectSettingsApiResponse,
			GetAllObjectSettingsApiArg
		>({
			query: () => ({ url: `/objectsettings` }),
		}),
		createObjectSettings: build.mutation<
			CreateObjectSettingsApiResponse,
			CreateObjectSettingsApiArg
		>({
			query: (queryArg) => ({
				url: `/objectsettings`,
				method: 'POST',
				body: queryArg.objectSettingsCreateDto,
			}),
		}),
		getObjectSettingsByType: build.query<
			GetObjectSettingsByTypeApiResponse,
			GetObjectSettingsByTypeApiArg
		>({
			query: (queryArg) => ({
				url: `/objectsettings/combo`,
				params: { structureType: queryArg.structureType },
			}),
		}),
		getAllObjectSettingsForTargetStructure: build.query<
			GetAllObjectSettingsForTargetStructureApiResponse,
			GetAllObjectSettingsForTargetStructureApiArg
		>({
			query: (queryArg) => ({
				url: `/objectsettings/structure/${queryArg.structureId}`,
			}),
		}),
		getUsedObjectSettings: build.query<
			GetUsedObjectSettingsApiResponse,
			GetUsedObjectSettingsApiArg
		>({
			query: (queryArg) => ({
				url: `/objectsettings/used`,
				params: { structureId: queryArg.structureId },
			}),
		}),
		getAllObjectSettingsForTargetStructureDep: build.query<
			GetAllObjectSettingsForTargetStructureDepApiResponse,
			GetAllObjectSettingsForTargetStructureDepApiArg
		>({
			query: (queryArg) => ({
				url: `/objectsettings/${queryArg.objectSettingsId}`,
			}),
		}),
		updateObjectSettings: build.mutation<
			UpdateObjectSettingsApiResponse,
			UpdateObjectSettingsApiArg
		>({
			query: (queryArg) => ({
				url: `/objectsettings/${queryArg.objectSettingsId}`,
				method: 'PUT',
				body: queryArg.objectSettingsUpdateDto,
			}),
		}),
		deleteObjectSettings: build.mutation<
			DeleteObjectSettingsApiResponse,
			DeleteObjectSettingsApiArg
		>({
			query: (queryArg) => ({
				url: `/objectsettings/${queryArg.objectSettingsId}`,
				method: 'DELETE',
			}),
		}),
		getPermissions: build.query<
			GetPermissionsApiResponse,
			GetPermissionsApiArg
		>({
			query: (queryArg) => ({
				url: `/permissions`,
				params: { type: queryArg['type'] },
			}),
		}),
		getAllRelationsByRelationType: build.query<
			GetAllRelationsByRelationTypeApiResponse,
			GetAllRelationsByRelationTypeApiArg
		>({
			query: (queryArg) => ({
				url: `/relation`,
				params: { relationType: queryArg.relationType },
			}),
		}),
		getStereotypeCategories: build.query<
			GetStereotypeCategoriesApiResponse,
			GetStereotypeCategoriesApiArg
		>({
			query: () => ({ url: `/stereotype-categories` }),
		}),
		getStereotypeTypes: build.query<
			GetStereotypeTypesApiResponse,
			GetStereotypeTypesApiArg
		>({
			query: () => ({ url: `/stereotype-types` }),
		}),
		getStereotypeTypesForObjectSettings: build.query<
			GetStereotypeTypesForObjectSettingsApiResponse,
			GetStereotypeTypesForObjectSettingsApiArg
		>({
			query: () => ({ url: `/stereotype-types/objectsettings` }),
		}),
		getStereotypes: build.query<
			GetStereotypesApiResponse,
			GetStereotypesApiArg
		>({
			query: (queryArg) => ({
				url: `/stereotypes`,
				params: { type: queryArg['type'], internal: queryArg.internal },
			}),
		}),
		createStereotype: build.mutation<
			CreateStereotypeApiResponse,
			CreateStereotypeApiArg
		>({
			query: (queryArg) => ({
				url: `/stereotypes`,
				method: 'POST',
				body: queryArg.stereotypeCreateDto,
			}),
		}),
		getStereotypesForTemplates: build.query<
			GetStereotypesForTemplatesApiResponse,
			GetStereotypesForTemplatesApiArg
		>({
			query: (queryArg) => ({
				url: `/stereotypes/templates`,
				params: { type: queryArg['type'] },
			}),
		}),
		getStereotypesForTableTemplates: build.query<
			GetStereotypesForTableTemplatesApiResponse,
			GetStereotypesForTableTemplatesApiArg
		>({
			query: () => ({ url: `/stereotypes/templates/tables` }),
		}),
		updateStereotype: build.mutation<
			UpdateStereotypeApiResponse,
			UpdateStereotypeApiArg
		>({
			query: (queryArg) => ({
				url: `/stereotypes/${queryArg.stereotypeId}`,
				method: 'PUT',
				body: queryArg.stereotypeUpdateDto,
			}),
		}),
		deleteStereotype: build.mutation<
			DeleteStereotypeApiResponse,
			DeleteStereotypeApiArg
		>({
			query: (queryArg) => ({
				url: `/stereotypes/${queryArg.stereotypeId}`,
				method: 'DELETE',
			}),
		}),
		getSubStereotypes: build.query<
			GetSubStereotypesApiResponse,
			GetSubStereotypesApiArg
		>({
			query: (queryArg) => ({
				url: `/stereotypes/${queryArg.stereotypeId}/sub-stereotypes`,
			}),
		}),
		getRootNodes: build.query<GetRootNodesApiResponse, GetRootNodesApiArg>({
			query: () => ({ url: `/structure` }),
		}),
		createNode: build.mutation<CreateNodeApiResponse, CreateNodeApiArg>({
			query: (queryArg) => ({
				url: `/structure`,
				method: 'POST',
				body: queryArg.newStructureDto,
			}),
		}),
		getAllStructureTypeDto: build.query<
			GetAllStructureTypeDtoApiResponse,
			GetAllStructureTypeDtoApiArg
		>({
			query: () => ({ url: `/structure-types` }),
		}),
		searchFullText: build.query<
			SearchFullTextApiResponse,
			SearchFullTextApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/fulltext`,
				params: { searchText: queryArg.searchText },
			}),
		}),
		searchFullTextJson: build.query<
			SearchFullTextJsonApiResponse,
			SearchFullTextJsonApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/fulltextJson`,
				params: { searchText: queryArg.searchText },
			}),
		}),
		importStructureFile: build.mutation<
			ImportStructureFileApiResponse,
			ImportStructureFileApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/import`,
				method: 'POST',
				body: queryArg.structureExportDto,
			}),
		}),
		importStructureFileIntoNode: build.mutation<
			ImportStructureFileIntoNodeApiResponse,
			ImportStructureFileIntoNodeApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/import/${queryArg.structureId}`,
				method: 'POST',
				body: queryArg.structureExportDto,
			}),
		}),
		importStructureCsvIntoNode: build.mutation<
			ImportStructureCsvIntoNodeApiResponse,
			ImportStructureCsvIntoNodeApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/importCsv/${queryArg.structureId}`,
				method: 'POST',
				body: queryArg.body,
				params: {
					caseSensitive: queryArg.caseSensitive,
					createNewDomains: queryArg.createNewDomains,
					generateCodes: queryArg.generateCodes,
					importAllColumns: queryArg.importAllColumns,
					nameNormalisation: queryArg.nameNormalisation,
				},
			}),
		}),
		importStructureCsvIntoNodeCheck: build.mutation<
			ImportStructureCsvIntoNodeCheckApiResponse,
			ImportStructureCsvIntoNodeCheckApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/importCsv/${queryArg.structureId}/check`,
				method: 'POST',
				body: queryArg.body,
				params: {
					caseSensitive: queryArg.caseSensitive,
					createNewDomains: queryArg.createNewDomains,
					generateCodes: queryArg.generateCodes,
					nameNormalisation: queryArg.nameNormalisation,
				},
			}),
		}),
		generateMassExportFile: build.mutation<
			GenerateMassExportFileApiResponse,
			GenerateMassExportFileApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/mass-export`,
				method: 'POST',
				body: queryArg.massExportConfigDto,
				params: { ignoreErrors: queryArg.ignoreErrors },
			}),
		}),
		searchNodes: build.query<SearchNodesApiResponse, SearchNodesApiArg>({
			query: (queryArg) => ({
				url: `/structure/search`,
				params: { searchText: queryArg.searchText },
			}),
		}),
		searchStructure: build.query<
			SearchStructureApiResponse,
			SearchStructureApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/search-by-name-or-code`,
				params: {
					searchText: queryArg.searchText,
					nodeType: queryArg.nodeType,
				},
			}),
		}),
		getSystemEnvironments: build.query<
			GetSystemEnvironmentsApiResponse,
			GetSystemEnvironmentsApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/system/${queryArg.systemId}/environment`,
			}),
		}),
		resetVersion: build.mutation<ResetVersionApiResponse, ResetVersionApiArg>({
			query: (queryArg) => ({
				url: `/structure/system/${queryArg.systemId}/environment/${queryArg.envId}/reset`,
				method: 'POST',
			}),
		}),
		takeOverVersion: build.mutation<
			TakeOverVersionApiResponse,
			TakeOverVersionApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/system/${queryArg.systemId}/environment/${queryArg.envId}/takeover/${queryArg.fromId}`,
				method: 'POST',
			}),
		}),
		getSystemReleases: build.query<
			GetSystemReleasesApiResponse,
			GetSystemReleasesApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/system/${queryArg.systemId}/release`,
				params: { onlyOpen: queryArg.onlyOpen },
			}),
		}),
		createSystemRelease: build.mutation<
			CreateSystemReleaseApiResponse,
			CreateSystemReleaseApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/system/${queryArg.systemId}/release`,
				method: 'POST',
				body: queryArg.releaseDto,
			}),
		}),
		getSystemRelease: build.query<
			GetSystemReleaseApiResponse,
			GetSystemReleaseApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/system/${queryArg.systemId}/release/${queryArg.releaseId}`,
			}),
		}),
		updateSystemRelease: build.mutation<
			UpdateSystemReleaseApiResponse,
			UpdateSystemReleaseApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/system/${queryArg.systemId}/release/${queryArg.releaseId}`,
				method: 'PUT',
				body: queryArg.releaseDto,
			}),
		}),
		deleteSystemRelease: build.mutation<
			DeleteSystemReleaseApiResponse,
			DeleteSystemReleaseApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/system/${queryArg.systemId}/release/${queryArg.releaseId}`,
				method: 'DELETE',
			}),
		}),
		getReleaseEnvironments: build.query<
			GetReleaseEnvironmentsApiResponse,
			GetReleaseEnvironmentsApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/system/${queryArg.systemId}/release/${queryArg.releaseId}/environment`,
			}),
		}),
		createReleaseEnvironment: build.mutation<
			CreateReleaseEnvironmentApiResponse,
			CreateReleaseEnvironmentApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/system/${queryArg.systemId}/release/${queryArg.releaseId}/environment`,
				method: 'POST',
				body: queryArg.releaseEnvironmentDto,
			}),
		}),
		updateReleaseEnvironment: build.mutation<
			UpdateReleaseEnvironmentApiResponse,
			UpdateReleaseEnvironmentApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/system/${queryArg.systemId}/release/${queryArg.releaseId}/environment/${queryArg.envId}`,
				method: 'PUT',
				body: queryArg.releaseEnvironmentDto,
			}),
		}),
		deleteReleaseEnvironment: build.mutation<
			DeleteReleaseEnvironmentApiResponse,
			DeleteReleaseEnvironmentApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/system/${queryArg.systemId}/release/${queryArg.releaseId}/environment/${queryArg.envId}`,
				method: 'DELETE',
			}),
		}),
		pushReleaseToGit: build.mutation<
			PushReleaseToGitApiResponse,
			PushReleaseToGitApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/system/${queryArg.systemId}/release/${queryArg.releaseId}/environment/${queryArg.envId}/git`,
				method: 'POST',
				body: queryArg.massExportConfigDto,
			}),
		}),
		createReleasePreview: build.mutation<
			CreateReleasePreviewApiResponse,
			CreateReleasePreviewApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/system/${queryArg.systemId}/release/${queryArg.releaseId}/environment/${queryArg.envId}/preview`,
				method: 'POST',
				body: queryArg.massExportConfigDto,
			}),
		}),
		createReleaseScripts: build.mutation<
			CreateReleaseScriptsApiResponse,
			CreateReleaseScriptsApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/system/${queryArg.systemId}/release/${queryArg.releaseId}/environment/${queryArg.envId}/scripts`,
				method: 'POST',
				body: queryArg.massExportConfigDto,
			}),
		}),
		createDeployments: build.mutation<
			CreateDeploymentsApiResponse,
			CreateDeploymentsApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/system/${queryArg.systemId}/release/${queryArg.releaseId}/environment/${queryArg.envId}/scripts/deploy`,
				method: 'POST',
				body: queryArg.massExportConfigDto,
			}),
		}),
		changeReleaseStatus: build.mutation<
			ChangeReleaseStatusApiResponse,
			ChangeReleaseStatusApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/system/${queryArg.systemId}/release/${queryArg.releaseId}/status/${queryArg.newStatus}`,
				method: 'PATCH',
			}),
		}),
		getReleaseStructures: build.query<
			GetReleaseStructuresApiResponse,
			GetReleaseStructuresApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/system/${queryArg.systemId}/release/${queryArg.releaseId}/structure`,
				params: { page: queryArg.page, pageSize: queryArg.pageSize },
			}),
		}),
		addStructureToRelease: build.mutation<
			AddStructureToReleaseApiResponse,
			AddStructureToReleaseApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/system/${queryArg.systemId}/release/${queryArg.releaseId}/structure`,
				method: 'POST',
				body: queryArg.body,
			}),
		}),
		deleteReleaseStructure: build.mutation<
			DeleteReleaseStructureApiResponse,
			DeleteReleaseStructureApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/system/${queryArg.systemId}/release/${queryArg.releaseId}/structure/${queryArg.structureId}`,
				method: 'DELETE',
			}),
		}),
		getAllTablesForSystemNode: build.query<
			GetAllTablesForSystemNodeApiResponse,
			GetAllTablesForSystemNodeApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/system/${queryArg.systemId}/tables`,
			}),
		}),
		getMappingsOfSystemNodes: build.query<
			GetMappingsOfSystemNodesApiResponse,
			GetMappingsOfSystemNodesApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/system/${queryArg.systemStructureId}/mapping`,
			}),
		}),
		getWorkflowsOfSystemNode: build.query<
			GetWorkflowsOfSystemNodeApiResponse,
			GetWorkflowsOfSystemNodeApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/system/${queryArg.systemStructureId}/workflow`,
			}),
		}),
		getNodeDetail: build.query<GetNodeDetailApiResponse, GetNodeDetailApiArg>({
			query: (queryArg) => ({ url: `/structure/${queryArg.structureId}` }),
		}),
		deleteNode: build.mutation<DeleteNodeApiResponse, DeleteNodeApiArg>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}`,
				method: 'DELETE',
			}),
		}),
		getApiNodeColumnFromAllStructures: build.query<
			GetApiNodeColumnFromAllStructuresApiResponse,
			GetApiNodeColumnFromAllStructuresApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/all-columns`,
			}),
		}),
		getNodesOfSpecificParent: build.query<
			GetNodesOfSpecificParentApiResponse,
			GetNodesOfSpecificParentApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/children`,
				params: { recursive: queryArg.recursive },
			}),
		}),
		createChildNode: build.mutation<
			CreateChildNodeApiResponse,
			CreateChildNodeApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/children`,
				method: 'POST',
				body: queryArg.newStructureDto,
			}),
		}),
		importColumnsFromCsv: build.mutation<
			ImportColumnsFromCsvApiResponse,
			ImportColumnsFromCsvApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/columns`,
				method: 'POST',
				body: queryArg.body,
				params: {
					caseSensitive: queryArg.caseSensitive,
					createNewDomains: queryArg.createNewDomains,
					generateCodes: queryArg.generateCodes,
					importAllColumns: queryArg.importAllColumns,
					nameNormalisation: queryArg.nameNormalisation,
				},
			}),
		}),
		importColumnsFromCsvCheck: build.mutation<
			ImportColumnsFromCsvCheckApiResponse,
			ImportColumnsFromCsvCheckApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/columns/check`,
				method: 'POST',
				body: queryArg.body,
				params: {
					caseSensitive: queryArg.caseSensitive,
					createNewDomains: queryArg.createNewDomains,
					generateCodes: queryArg.generateCodes,
					nameNormalisation: queryArg.nameNormalisation,
				},
			}),
		}),
		editDataNode: build.query<EditDataNodeApiResponse, EditDataNodeApiArg>({
			query: (queryArg) => ({ url: `/structure/${queryArg.structureId}/data` }),
		}),
		updateDataNode: build.mutation<
			UpdateDataNodeApiResponse,
			UpdateDataNodeApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/data`,
				method: 'PUT',
				body: queryArg.structureWorkingDataDto,
			}),
		}),
		deleteDataNode: build.mutation<
			DeleteDataNodeApiResponse,
			DeleteDataNodeApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/data`,
				method: 'DELETE',
			}),
		}),
		getDiagram: build.query<GetDiagramApiResponse, GetDiagramApiArg>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/diagram`,
			}),
		}),
		getDeepDiagram: build.query<
			GetDeepDiagramApiResponse,
			GetDeepDiagramApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/diagram/deep`,
			}),
		}),
		createFromSystemStructure: build.query<
			CreateFromSystemStructureApiResponse,
			CreateFromSystemStructureApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/diagram/fromSystem`,
			}),
		}),
		getDiagramFromRelation: build.query<
			GetDiagramFromRelationApiResponse,
			GetDiagramFromRelationApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/diagram/relation`,
			}),
		}),
		duplicateNode: build.mutation<
			DuplicateNodeApiResponse,
			DuplicateNodeApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/duplicate`,
				method: 'POST',
			}),
		}),
		duplicateNodeRecursive: build.mutation<
			DuplicateNodeRecursiveApiResponse,
			DuplicateNodeRecursiveApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/duplicate-recursive`,
				method: 'POST',
				params: {
					suffix: queryArg.suffix,
					useSuffixOnly4System: queryArg.useSuffixOnly4System,
				},
			}),
		}),
		generateAndPushToGit: build.query<
			GenerateAndPushToGitApiResponse,
			GenerateAndPushToGitApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/environments/${queryArg.environmentId}/objectTypes/${queryArg.objectSettingsId}/git`,
			}),
		}),
		generateSqlPreviewEnvironment: build.query<
			GenerateSqlPreviewEnvironmentApiResponse,
			GenerateSqlPreviewEnvironmentApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/environments/${queryArg.environmentId}/objectTypes/${queryArg.objectSettingsId}/preview`,
			}),
		}),
		generateSql: build.query<GenerateSqlApiResponse, GenerateSqlApiArg>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/environments/${queryArg.environmentId}/objectTypes/${queryArg.objectSettingsId}/sql`,
			}),
		}),
		generateSqlDownloadZipFile: build.query<
			GenerateSqlDownloadZipFileApiResponse,
			GenerateSqlDownloadZipFileApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/environments/${queryArg.environmentId}/objectTypes/${queryArg.objectTypeId}/sql/download-zip`,
			}),
		}),
		exportStructure: build.query<
			ExportStructureApiResponse,
			ExportStructureApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/export`,
			}),
		}),
		getAllNodeHistory: build.query<
			GetAllNodeHistoryApiResponse,
			GetAllNodeHistoryApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/history`,
			}),
		}),
		createNodeHistory: build.mutation<
			CreateNodeHistoryApiResponse,
			CreateNodeHistoryApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/history`,
				method: 'POST',
				body: queryArg.newHistoryDto,
				params: { fixImpacts: queryArg.fixImpacts },
			}),
		}),
		getNodeHistoryDetailByVersion: build.query<
			GetNodeHistoryDetailByVersionApiResponse,
			GetNodeHistoryDetailByVersionApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/history/version/${queryArg.version}`,
				params: { envId: queryArg.envId },
			}),
		}),
		getNodeHistoryDetail: build.query<
			GetNodeHistoryDetailApiResponse,
			GetNodeHistoryDetailApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/history/${queryArg.historyId}`,
			}),
		}),
		deleteNodeHistory: build.mutation<
			DeleteNodeHistoryApiResponse,
			DeleteNodeHistoryApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/history/${queryArg.historyId}`,
				method: 'DELETE',
			}),
		}),
		createNodeLock: build.mutation<
			CreateNodeLockApiResponse,
			CreateNodeLockApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/lock`,
				method: 'POST',
			}),
		}),
		deleteNodeLock: build.mutation<
			DeleteNodeLockApiResponse,
			DeleteNodeLockApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/lock`,
				method: 'DELETE',
			}),
		}),
		getMappings: build.query<GetMappingsApiResponse, GetMappingsApiArg>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/mapping`,
			}),
		}),
		generateMassExportPushGit: build.mutation<
			GenerateMassExportPushGitApiResponse,
			GenerateMassExportPushGitApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/mass-export/git`,
				method: 'POST',
				body: queryArg.massExportConfigDto,
			}),
		}),
		massPatchStructuresList: build.mutation<
			MassPatchStructuresListApiResponse,
			MassPatchStructuresListApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/mass-update-list`,
				method: 'PATCH',
				body: queryArg.massUpdateDto,
			}),
		}),
		moveNode: build.mutation<MoveNodeApiResponse, MoveNodeApiArg>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/move/${queryArg.destinationStructureId}`,
				method: 'PUT',
			}),
		}),
		reorderNode: build.mutation<ReorderNodeApiResponse, ReorderNodeApiArg>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/order`,
				method: 'PUT',
				body: queryArg.reorderStructureDto,
			}),
		}),
		patchMergeStructures: build.mutation<
			PatchMergeStructuresApiResponse,
			PatchMergeStructuresApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/patch-merge`,
				method: 'PATCH',
				body: queryArg.massUpdateDto,
			}),
		}),
		getStructurePermissions: build.query<
			GetStructurePermissionsApiResponse,
			GetStructurePermissionsApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/permissions`,
			}),
		}),
		updateBatchEnvironments: build.mutation<
			UpdateBatchEnvironmentsApiResponse,
			UpdateBatchEnvironmentsApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/permissions`,
				method: 'PUT',
				body: queryArg.body,
			}),
		}),
		getUserPermission: build.query<
			GetUserPermissionApiResponse,
			GetUserPermissionApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/permissions/user/${queryArg.userId}`,
			}),
		}),
		changeUserPermission: build.mutation<
			ChangeUserPermissionApiResponse,
			ChangeUserPermissionApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/permissions/user/${queryArg.userId}`,
				method: 'PUT',
				body: queryArg.newStructureUserPermissionDto,
			}),
		}),
		deleteUserPermission: build.mutation<
			DeleteUserPermissionApiResponse,
			DeleteUserPermissionApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/permissions/user/${queryArg.userId}`,
				method: 'DELETE',
			}),
		}),
		prePushValidation: build.query<
			PrePushValidationApiResponse,
			PrePushValidationApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/prepush-validation`,
			}),
		}),
		getNodeRelations: build.query<
			GetNodeRelationsApiResponse,
			GetNodeRelationsApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/relation`,
			}),
		}),
		createRelation: build.mutation<
			CreateRelationApiResponse,
			CreateRelationApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/relation`,
				method: 'POST',
				body: queryArg.relationCreateDto,
			}),
		}),
		updateRelation: build.mutation<
			UpdateRelationApiResponse,
			UpdateRelationApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/relation/${queryArg.relationId}`,
				method: 'PUT',
				body: queryArg.relationDto,
			}),
		}),
		deleteRelation: build.mutation<
			DeleteRelationApiResponse,
			DeleteRelationApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/relation/${queryArg.relationId}`,
				method: 'DELETE',
			}),
		}),
		pathRelation: build.mutation<PathRelationApiResponse, PathRelationApiArg>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/relation/${queryArg.relationId}`,
				method: 'PATCH',
				body: queryArg.jsonPatch,
			}),
		}),
		generateSqlPreview: build.query<
			GenerateSqlPreviewApiResponse,
			GenerateSqlPreviewApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/sql`,
				params: { objectSettingsId: queryArg.objectSettingsId },
			}),
		}),
		generateSqlPreviewDownloadZipFile: build.query<
			GenerateSqlPreviewDownloadZipFileApiResponse,
			GenerateSqlPreviewDownloadZipFileApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/sql/download-zip`,
				params: { objectTypeId: queryArg.objectTypeId },
			}),
		}),
		getExistingStructuresForSourceMapping: build.query<
			GetExistingStructuresForSourceMappingApiResponse,
			GetExistingStructuresForSourceMappingApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/structure-object-read-only/${queryArg.sourceObjectType}`,
			}),
		}),
		getAvailableStructureForSourceObject: build.query<
			GetAvailableStructureForSourceObjectApiResponse,
			GetAvailableStructureForSourceObjectApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/structure-object/${queryArg.sourceObjectType}`,
				params: { name: queryArg.name },
			}),
		}),
		getSystemUsers: build.query<
			GetSystemUsersApiResponse,
			GetSystemUsersApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/system-users`,
			}),
		}),
		getSystemUser: build.query<GetSystemUserApiResponse, GetSystemUserApiArg>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/system-users/${queryArg.id}`,
			}),
		}),
		getStructureTree: build.query<
			GetStructureTreeApiResponse,
			GetStructureTreeApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/tree`,
				body: queryArg.structureQuickFilter,
			}),
		}),
		getUniversalColumnFromAllStructures: build.query<
			GetUniversalColumnFromAllStructuresApiResponse,
			GetUniversalColumnFromAllStructuresApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/universal/all-columns`,
			}),
		}),
		getWriteColumnFromAllStructures: build.query<
			GetWriteColumnFromAllStructuresApiResponse,
			GetWriteColumnFromAllStructuresApiArg
		>({
			query: (queryArg) => ({
				url: `/structure/${queryArg.structureId}/write-mapping/all-columns`,
			}),
		}),
		getAllSystemTechnologies: build.query<
			GetAllSystemTechnologiesApiResponse,
			GetAllSystemTechnologiesApiArg
		>({
			query: () => ({ url: `/system-technologies` }),
		}),
		searchTableColumns: build.query<
			SearchTableColumnsApiResponse,
			SearchTableColumnsApiArg
		>({
			query: (queryArg) => ({
				url: `/system/${queryArg.systemId}/structure/table/${queryArg.tableCode}/columns`,
				params: {
					searchText: queryArg.searchText,
					actualStructureId: queryArg.actualStructureId,
				},
			}),
		}),
		searchNodesWithType: build.query<
			SearchNodesWithTypeApiResponse,
			SearchNodesWithTypeApiArg
		>({
			query: (queryArg) => ({
				url: `/system/${queryArg.systemId}/structure/${queryArg.structureType}/search`,
				params: {
					searchText: queryArg.searchText,
					actualStructureId: queryArg.actualStructureId,
				},
			}),
		}),
		getAsyncTasks: build.query<GetAsyncTasksApiResponse, GetAsyncTasksApiArg>({
			query: (queryArg) => ({
				url: `/task`,
				params: {
					state: queryArg.state,
					page: queryArg.page,
					pageSize: queryArg.pageSize,
				},
			}),
		}),
		create: build.mutation<CreateApiResponse, CreateApiArg>({
			query: (queryArg) => ({
				url: `/task/type/${queryArg['type']}`,
				method: 'POST',
				body: queryArg.body,
			}),
		}),
		getTaskExecution: build.query<
			GetTaskExecutionApiResponse,
			GetTaskExecutionApiArg
		>({
			query: (queryArg) => ({ url: `/task/${queryArg.id}` }),
		}),
		cancelTask: build.mutation<CancelTaskApiResponse, CancelTaskApiArg>({
			query: (queryArg) => ({ url: `/task/${queryArg.id}`, method: 'DELETE' }),
		}),
		getTaskResultFile: build.query<
			GetTaskResultFileApiResponse,
			GetTaskResultFileApiArg
		>({
			query: (queryArg) => ({ url: `/task/${queryArg.id}/bin` }),
		}),
		getTaskResult: build.query<GetTaskResultApiResponse, GetTaskResultApiArg>({
			query: (queryArg) => ({ url: `/task/${queryArg.id}/result` }),
		}),
		getAllTechnicalColumns: build.query<
			GetAllTechnicalColumnsApiResponse,
			GetAllTechnicalColumnsApiArg
		>({
			query: () => ({ url: `/technical-columns` }),
		}),
		createTechnicalColumn: build.mutation<
			CreateTechnicalColumnApiResponse,
			CreateTechnicalColumnApiArg
		>({
			query: (queryArg) => ({
				url: `/technical-columns`,
				method: 'POST',
				body: queryArg.technicalColumnDto,
				params: { updateElements: queryArg.updateElements },
			}),
		}),
		getTechnicalColumnStereotype: build.query<
			GetTechnicalColumnStereotypeApiResponse,
			GetTechnicalColumnStereotypeApiArg
		>({
			query: (queryArg) => ({
				url: `/technical-columns/combo/${queryArg.stereotypeId}`,
				params: { technologyId: queryArg.technologyId },
			}),
		}),
		updateTechnicalColumn: build.mutation<
			UpdateTechnicalColumnApiResponse,
			UpdateTechnicalColumnApiArg
		>({
			query: (queryArg) => ({
				url: `/technical-columns/${queryArg.technicalColumnId}`,
				method: 'PUT',
				body: queryArg.technicalColumnDto,
				params: { updateElements: queryArg.updateElements },
			}),
		}),
		deleteTechnicalColumn: build.mutation<
			DeleteTechnicalColumnApiResponse,
			DeleteTechnicalColumnApiArg
		>({
			query: (queryArg) => ({
				url: `/technical-columns/${queryArg.technicalColumnId}`,
				method: 'DELETE',
				params: { updateElements: queryArg.updateElements },
			}),
		}),
		computeImpactTcChange: build.mutation<
			ComputeImpactTcChangeApiResponse,
			ComputeImpactTcChangeApiArg
		>({
			query: (queryArg) => ({
				url: `/technical-columns/${queryArg.technicalColumnId}/impact`,
				method: 'POST',
				body: queryArg.technicalColumnDto,
			}),
		}),
		getTablesByTechnicalColumn: build.query<
			GetTablesByTechnicalColumnApiResponse,
			GetTablesByTechnicalColumnApiArg
		>({
			query: (queryArg) => ({
				url: `/technical-columns/${queryArg.technicalColumnId}/table`,
			}),
		}),
		getTablesByTechnicalColumnCsv: build.query<
			GetTablesByTechnicalColumnCsvApiResponse,
			GetTablesByTechnicalColumnCsvApiArg
		>({
			query: (queryArg) => ({
				url: `/technical-columns/${queryArg.technicalColumnId}/table/csv`,
			}),
		}),
		getAllTechnologies: build.query<
			GetAllTechnologiesApiResponse,
			GetAllTechnologiesApiArg
		>({
			query: () => ({ url: `/technologies` }),
		}),
		getAllTemplates: build.query<
			GetAllTemplatesApiResponse,
			GetAllTemplatesApiArg
		>({
			query: (queryArg) => ({
				url: `/templates`,
				params: {
					technologyId: queryArg.technologyId,
					stereotypeId: queryArg.stereotypeId,
				},
			}),
		}),
		createTemplate: build.mutation<
			CreateTemplateApiResponse,
			CreateTemplateApiArg
		>({
			query: (queryArg) => ({
				url: `/templates`,
				method: 'POST',
				body: queryArg.templateDto,
			}),
		}),
		getTemplate: build.query<GetTemplateApiResponse, GetTemplateApiArg>({
			query: (queryArg) => ({ url: `/templates/${queryArg.id}` }),
		}),
		updateTemplate: build.mutation<
			UpdateTemplateApiResponse,
			UpdateTemplateApiArg
		>({
			query: (queryArg) => ({
				url: `/templates/${queryArg.id}`,
				method: 'PUT',
				body: queryArg.templateDto,
			}),
		}),
		deleteTemplate: build.mutation<
			DeleteTemplateApiResponse,
			DeleteTemplateApiArg
		>({
			query: (queryArg) => ({
				url: `/templates/${queryArg.id}`,
				method: 'DELETE',
			}),
		}),
		getTemplateHistory: build.query<
			GetTemplateHistoryApiResponse,
			GetTemplateHistoryApiArg
		>({
			query: (queryArg) => ({ url: `/templates/${queryArg.id}/history` }),
		}),
		getTemplateHistoryDetail: build.query<
			GetTemplateHistoryDetailApiResponse,
			GetTemplateHistoryDetailApiArg
		>({
			query: (queryArg) => ({
				url: `/templates/${queryArg.id}/history/${queryArg.version}`,
			}),
		}),
		lock: build.mutation<LockApiResponse, LockApiArg>({
			query: (queryArg) => ({
				url: `/templates/${queryArg.templateId}/lock`,
				method: 'POST',
			}),
		}),
		push: build.mutation<PushApiResponse, PushApiArg>({
			query: (queryArg) => ({
				url: `/templates/${queryArg.templateId}/push`,
				method: 'POST',
				params: { unlock: queryArg.unlock },
			}),
		}),
		unlock: build.mutation<UnlockApiResponse, UnlockApiArg>({
			query: (queryArg) => ({
				url: `/templates/${queryArg.templateId}/unlock`,
				method: 'POST',
			}),
		}),
		getUsers: build.query<GetUsersApiResponse, GetUsersApiArg>({
			query: () => ({ url: `/users` }),
		}),
		addUser: build.mutation<AddUserApiResponse, AddUserApiArg>({
			query: (queryArg) => ({
				url: `/users`,
				method: 'POST',
				body: queryArg.newUserDto,
			}),
		}),
		getApiUsers: build.query<GetApiUsersApiResponse, GetApiUsersApiArg>({
			query: () => ({ url: `/users/api` }),
		}),
		addApiUser: build.mutation<AddApiUserApiResponse, AddApiUserApiArg>({
			query: (queryArg) => ({
				url: `/users/api`,
				method: 'POST',
				body: queryArg.apiUserDto,
			}),
		}),
		deleteApiUser: build.mutation<
			DeleteApiUserApiResponse,
			DeleteApiUserApiArg
		>({
			query: (queryArg) => ({
				url: `/users/api/${queryArg.clientId}`,
				method: 'DELETE',
			}),
		}),
		apiUserResetSecret: build.mutation<
			ApiUserResetSecretApiResponse,
			ApiUserResetSecretApiArg
		>({
			query: (queryArg) => ({
				url: `/users/api/${queryArg.clientId}/reset-secret`,
				method: 'POST',
			}),
		}),
		getUsersForCombo: build.query<
			GetUsersForComboApiResponse,
			GetUsersForComboApiArg
		>({
			query: () => ({ url: `/users/combo` }),
		}),
		getSessionContext: build.query<
			GetSessionContextApiResponse,
			GetSessionContextApiArg
		>({
			query: () => ({ url: `/users/context` }),
		}),
		updateSessionContext: build.mutation<
			UpdateSessionContextApiResponse,
			UpdateSessionContextApiArg
		>({
			query: (queryArg) => ({
				url: `/users/context`,
				method: 'PUT',
				body: queryArg.sessionContextDto,
			}),
		}),
		lockUser: build.mutation<LockUserApiResponse, LockUserApiArg>({
			query: (queryArg) => ({
				url: `/users/lock/${queryArg.userId}`,
				method: 'POST',
			}),
		}),
		resetUserPassword: build.mutation<
			ResetUserPasswordApiResponse,
			ResetUserPasswordApiArg
		>({
			query: (queryArg) => ({
				url: `/users/password`,
				method: 'POST',
				body: queryArg.resetPasswordDto,
			}),
		}),
		unlockUser: build.mutation<UnlockUserApiResponse, UnlockUserApiArg>({
			query: (queryArg) => ({
				url: `/users/unlock/${queryArg.userId}`,
				method: 'POST',
			}),
		}),
		getUser: build.query<GetUserApiResponse, GetUserApiArg>({
			query: (queryArg) => ({ url: `/users/${queryArg.userId}` }),
		}),
		editUser: build.mutation<EditUserApiResponse, EditUserApiArg>({
			query: (queryArg) => ({
				url: `/users/${queryArg.userId}`,
				method: 'PUT',
				body: queryArg.editUserInfoDto,
			}),
		}),
		deleteUser: build.mutation<DeleteUserApiResponse, DeleteUserApiArg>({
			query: (queryArg) => ({
				url: `/users/${queryArg.userId}`,
				method: 'DELETE',
			}),
		}),
		resetLoginAttempts: build.mutation<
			ResetLoginAttemptsApiResponse,
			ResetLoginAttemptsApiArg
		>({
			query: (queryArg) => ({
				url: `/users/${queryArg.userId}/login-attempts`,
				method: 'DELETE',
			}),
		}),
		getUserRoles: build.query<GetUserRolesApiResponse, GetUserRolesApiArg>({
			query: (queryArg) => ({ url: `/users/${queryArg.userId}/roles` }),
		}),
		editUserRoles: build.mutation<
			EditUserRolesApiResponse,
			EditUserRolesApiArg
		>({
			query: (queryArg) => ({
				url: `/users/${queryArg.userId}/roles`,
				method: 'PUT',
				body: queryArg.userRolesDto,
			}),
		}),
		adjustTableName: build.mutation<
			AdjustTableNameApiResponse,
			AdjustTableNameApiArg
		>({
			query: (queryArg) => ({
				url: `/util/adjust/name`,
				method: 'POST',
				body: queryArg.utilsNameRequestDto,
			}),
		}),
		checkUniqueName: build.mutation<
			CheckUniqueNameApiResponse,
			CheckUniqueNameApiArg
		>({
			query: (queryArg) => ({
				url: `/util/unique/name`,
				method: 'POST',
				body: queryArg.utilsNameRequestDto,
			}),
		}),
		checkStructure: build.query<
			CheckStructureApiResponse,
			CheckStructureApiArg
		>({
			query: (queryArg) => ({
				url: `/validation/structure/${queryArg.structureId}`,
			}),
		}),
		getAllWorkflowTechnologies: build.query<
			GetAllWorkflowTechnologiesApiResponse,
			GetAllWorkflowTechnologiesApiArg
		>({
			query: () => ({ url: `/workflow-technologies` }),
		}),
	}),
	overrideExisting: false,
})
export { injectedRtkApi as mddApiSliceGenerated }
export type GetAcronymsApiResponse =
	/** status 200 successful operation */ PageableDtoAcronymDto
export type GetAcronymsApiArg = {
	page?: number
	searchQuery?: string
	size?: number
}
export type CreateAcronymApiResponse =
	/** status 200 successful operation */
	AcronymDto | /** status 201  */ void
export type CreateAcronymApiArg = {
	acronymCreateDto: AcronymCreateDto
}
export type GetAcronymApiResponse = unknown
export type GetAcronymApiArg = {
	/** acronym id */
	acronymId: number
}
export type UpdateAcronymApiResponse =
	/** status 200 successful operation */ AcronymDto
export type UpdateAcronymApiArg = {
	/** acronym id */
	acronymId: number
	acronymUpdateDto: AcronymUpdateDto
}
export type DeleteAcronymApiResponse = unknown
export type DeleteAcronymApiArg = {
	/** acronym id */
	acronymId: number
}
export type GetAuditLogsApiResponse =
	/** status 200 successful operation */ StructureAudit[]
export type GetAuditLogsApiArg = {
	entityId: number
	entityType: 'STRUCTURE' | 'DOMAIN'
}
export type GetAllBusinessDomainsApiResponse =
	/** status 200 successful operation */ BusinessDomainDto[]
export type GetAllBusinessDomainsApiArg = void
export type CreateBusinessDomainApiResponse =
	/** status 200 successful operation */
	BusinessDomainDto | /** status 201  */ void
export type CreateBusinessDomainApiArg = {
	/** Business Domain dto */
	businessDomainDto: BusinessDomainDto
}
export type GetAllBusinessDomainsForComboApiResponse =
	/** status 200 successful operation */ BusinessDomainComboDto[]
export type GetAllBusinessDomainsForComboApiArg = void
export type GetBusinessDomainApiResponse =
	/** status 200 successful operation */ BusinessDomainDto
export type GetBusinessDomainApiArg = {
	/** Business Domain id */
	businessDomainId: number
}
export type UpdateBusinessDomainApiResponse =
	/** status 200 successful operation */ BusinessDomainDto
export type UpdateBusinessDomainApiArg = {
	/** BusinessDomain dto for update */
	businessDomainDto: BusinessDomainDto
	/** BusinessDomain id */
	businessDomainId: number
}
export type DeleteBusinessDomainApiResponse = unknown
export type DeleteBusinessDomainApiArg = {
	/** Business Domain id */
	businessDomainId: number
}
export type GetCompanyApiResponse =
	/** status 200 successful operation */ Company
export type GetCompanyApiArg = void
export type GetAllCustomAttributeStructureTypeDtoApiResponse =
	/** status 200 successful operation */ StructureTypeDto[]
export type GetAllCustomAttributeStructureTypeDtoApiArg = void
export type GetAllCustomAttributesApiResponse =
	/** status 200 successful operation */ CustomAttributeDto[]
export type GetAllCustomAttributesApiArg = void
export type CreateCustomAttributeApiResponse =
	/** status 200 successful operation */
	CustomAttributeDto | /** status 201  */ void
export type CreateCustomAttributeApiArg = {
	/** Custom Attribute dto */
	customAttributeCreateDto: CustomAttributeCreateDto
}
export type GetAllCustomAttributesForComboApiResponse =
	/** status 200 successful operation */ CustomAttributeComboDto[]
export type GetAllCustomAttributesForComboApiArg = {
	structureType:
		| 'SYSTEM'
		| 'FOLDER'
		| 'FOLDER_WORKFLOW'
		| 'TABLE'
		| 'TABLE_COLUMN'
		| 'MAPPING_DEFAULT'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
}
export type GetCustomAttributeApiResponse =
	/** status 200 successful operation */ CustomAttributeDto
export type GetCustomAttributeApiArg = {
	/** Custom Attributes id */
	customAttributeId: number
}
export type UpdateCustomAttributeApiResponse =
	/** status 200 successful operation */ CustomAttributeDto
export type UpdateCustomAttributeApiArg = {
	/** Custom Attribute id */
	customAttributeId: number
	/** CustomAttribute dto for update */
	customAttributeUpdateDto: CustomAttributeUpdateDto
}
export type DeleteCustomAttributeApiResponse = unknown
export type DeleteCustomAttributeApiArg = {
	/** Custom Attribute id */
	customAttributeId: number
}
export type GetAllCustomLovsApiResponse =
	/** status 200 successful operation */ CustomLovDto[]
export type GetAllCustomLovsApiArg = void
export type CreateCustomLovApiResponse =
	/** status 200 successful operation */
	CustomLovDto | /** status 201  */ void
export type CreateCustomLovApiArg = {
	/** Custom Lov dto */
	customLovCreateDto: CustomLovCreateDto
}
export type GetAllCustomLovForComboApiResponse =
	/** status 200 successful operation */ CustomLovComboDto[]
export type GetAllCustomLovForComboApiArg = void
export type GetCustomLovApiResponse =
	/** status 200 successful operation */ CustomLovDto
export type GetCustomLovApiArg = {
	/** Custom LOV id */
	customLovId: number
}
export type UpdateCustomLovApiResponse =
	/** status 200 successful operation */ CustomLovDto
export type UpdateCustomLovApiArg = {
	/** Custom LOV id */
	customLovId: number
	/** CustomLov dto for update */
	customLovUpdateDto: CustomLovUpdateDto
}
export type DeleteCustomLovApiResponse = unknown
export type DeleteCustomLovApiArg = {
	/** Custom Lov id */
	customLovId: number
}
export type GetCustomLovForComboByIdApiResponse =
	/** status 200 successful operation */ CustomLovComboDto
export type GetCustomLovForComboByIdApiArg = {
	/** Custom LOV id */
	customLovId: number
}
export type GetAllNodeDeploymentsApiResponse =
	/** status 200 successful operation */ DeploymentDto[]
export type GetAllNodeDeploymentsApiArg = {
	sqlType?:
		| 'TABLE_HISTORY'
		| 'TABLE_REFERENCE'
		| 'TABLE'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
	structureId: number
}
export type GetAllSpDeploymentsApiResponse =
	/** status 200 successful operation */ DeploymentDto
export type GetAllSpDeploymentsApiArg = {
	environmentId: number
	objectTypeId: number
	structureId: number
}
export type CreateDeployApiResponse =
	/** status 200 successful operation */ DeploymentDto
export type CreateDeployApiArg = {
	deploymentNewDto: DeploymentNewDto
	environmentId: number
	objectTypeId: number
	structureId: number
}
export type GetDeploymentDetailApiResponse =
	/** status 200 successful operation */ DeploymentDetailDto
export type GetDeploymentDetailApiArg = {
	deploymentId: number
}
export type GetAllDomainsApiResponse =
	/** status 200 successful operation */ DomainDto[]
export type GetAllDomainsApiArg = void
export type CreateDomainApiResponse =
	/** status 200 successful operation */
	DomainDto | /** status 201  */ void
export type CreateDomainApiArg = {
	/** Domain dto */
	domainCreateDto: DomainCreateDto
}
export type GetAllDomainsForComboApiResponse =
	/** status 200 successful operation */ DomainComboDto[]
export type GetAllDomainsForComboApiArg = void
export type GetAllDomainsFilteredBySystemTechnologyApiResponse =
	/** status 200 successful operation */ DomainDto[]
export type GetAllDomainsFilteredBySystemTechnologyApiArg = {
	/** System structure ID */
	systemStructureId: number
}
export type GetAllDomainsFilteredBySystemTechnologyDepApiResponse =
	/** status 200 successful operation */ DomainDto[]
export type GetAllDomainsFilteredBySystemTechnologyDepApiArg = {
	/** System structure ID */
	domainId: number
}
export type UpdateDomainApiResponse =
	/** status 200 successful operation */ DomainDto
export type UpdateDomainApiArg = {
	/** Domain id */
	domainId: number
	/** Domain dto for update */
	domainUpdateDto: DomainUpdateDto
	/** Update affected elements */
	updateElements?: boolean
}
export type DeleteDomainApiResponse = unknown
export type DeleteDomainApiArg = {
	/** Domain id */
	domainId: number
}
export type GetColumnsByDomainApiResponse =
	/** status 200 successful operation */ ImpactedColumnDto[]
export type GetColumnsByDomainApiArg = {
	/** Domain id */
	domainId: number
}
export type GetColumnsByDomainCsvApiResponse =
	/** status 200 successful operation */ string[]
export type GetColumnsByDomainCsvApiArg = {
	/** Domain id */
	domainId: number
}
export type ComputeImpactDomainChangeApiResponse =
	/** status 200 successful operation */ ImpactChangeCodeListDto
export type ComputeImpactDomainChangeApiArg = {
	/** Domain id */
	domainId: number
	/** Domain dto for update */
	domainUpdateDto: DomainUpdateDto
}
export type GetEnvironmentsApiResponse =
	/** status 200 successful operation */ EnvironmentDto[]
export type GetEnvironmentsApiArg = {
	structureId: number
}
export type GetEnvironmentsWithLastDeploymentApiResponse =
	/** status 200 successful operation */ EnvironmentDeploymentDto[]
export type GetEnvironmentsWithLastDeploymentApiArg = {
	structureId: number
}
export type GetEnvironmentsWithLastDeploymentPerObjectTypeApiResponse =
	/** status 200 successful operation */ EnvironmentDeploymentDto[]
export type GetEnvironmentsWithLastDeploymentPerObjectTypeApiArg = {
	structureId: number
}
export type GetEnvironmentByIdApiResponse =
	/** status 200 successful operation */ EnvironmentDto
export type GetEnvironmentByIdApiArg = {
	environmentId: number
}
export type GetInitInfoApiResponse =
	/** status 200 successful operation */ InitInfoDto
export type GetInitInfoApiArg = void
export type GetNamingsApiResponse =
	/** status 200 successful operation */ NamingDto[]
export type GetNamingsApiArg = void
export type CreateNamingApiResponse =
	/** status 200 successful operation */
	NamingDto | /** status 201  */ void
export type CreateNamingApiArg = {
	/** Naming dto */
	namingDto: NamingDto
}
export type UpdateNamingsApiResponse =
	/** status 200 successful operation */ NamingDto[]
export type UpdateNamingsApiArg = {
	/** Array of Naming dto for update */
	body: NamingDto[]
}
export type DummyNamingApiResponse =
	/** status 200 successful operation */ AdjustResultDto
export type DummyNamingApiArg = {
	table: Table
}
export type ExecuteNamingApiResponse =
	/** status 200 successful operation */ AdjustResultDto
export type ExecuteNamingApiArg = {
	masterNodeCode?: string
	masterNodeName?: string
	/** namingType */
	namingType:
		| 'TABLE_CODE'
		| 'MAPPING_CODE'
		| 'INTERFACE_CODE'
		| 'VIEW_CODE'
		| 'API_NODE_CODE'
		| 'API_COLLECTION_CODE'
		| 'FOLDER_CODE'
		| 'QUEUE_CODE'
		| 'SYSTEM_CODE'
		| 'WORKFLOW_CODE'
		| 'HISTORICAL_TABLE_NAME'
		| 'REFERENCE_TABLE_NAME'
		| 'HISTORICAL_TABLE_CODE'
		| 'REFERENCE_TABLE_CODE'
		| 'HISTORICAL_DEFAULT_MAPPING_NAME'
		| 'REFERENCE_DEFAULT_MAPPING_NAME'
		| 'INTERFACE_DEFAULT_MAPPING_NAME'
		| 'HISTORICAL_DEFAULT_MAPPING_CODE'
		| 'REFERENCE_DEFAULT_MAPPING_CODE'
		| 'INTERFACE_DEFAULT_MAPPING_CODE'
		| 'DEFAULT_MAPPING_NAME'
		| 'DEFAULT_MAPPING_CODE'
		| 'DDL_SCRIPT_NAME'
		| 'EXPORT_NAME'
		| 'PRIMARY_KEY_NAME'
		| 'SEQUENCE_NAME'
	sequenceColumn?: string
	/** StructureId */
	structureId: number
}
export type ExecuteNamingOverWorkingDataApiResponse =
	/** status 200 successful operation */ AdjustResultDto
export type ExecuteNamingOverWorkingDataApiArg = {
	masterNodeCode?: string
	masterNodeName?: string
	/** namingType */
	namingType:
		| 'TABLE_CODE'
		| 'MAPPING_CODE'
		| 'INTERFACE_CODE'
		| 'VIEW_CODE'
		| 'API_NODE_CODE'
		| 'API_COLLECTION_CODE'
		| 'FOLDER_CODE'
		| 'QUEUE_CODE'
		| 'SYSTEM_CODE'
		| 'WORKFLOW_CODE'
		| 'HISTORICAL_TABLE_NAME'
		| 'REFERENCE_TABLE_NAME'
		| 'HISTORICAL_TABLE_CODE'
		| 'REFERENCE_TABLE_CODE'
		| 'HISTORICAL_DEFAULT_MAPPING_NAME'
		| 'REFERENCE_DEFAULT_MAPPING_NAME'
		| 'INTERFACE_DEFAULT_MAPPING_NAME'
		| 'HISTORICAL_DEFAULT_MAPPING_CODE'
		| 'REFERENCE_DEFAULT_MAPPING_CODE'
		| 'INTERFACE_DEFAULT_MAPPING_CODE'
		| 'DEFAULT_MAPPING_NAME'
		| 'DEFAULT_MAPPING_CODE'
		| 'DDL_SCRIPT_NAME'
		| 'EXPORT_NAME'
		| 'PRIMARY_KEY_NAME'
		| 'SEQUENCE_NAME'
	sequenceColumn?: string
	/** sqlType */
	sqlType:
		| 'TABLE_HISTORY'
		| 'TABLE_REFERENCE'
		| 'TABLE'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
	/** StructureId */
	structureId: number
	structureWorkingDataDto: StructureWorkingDataDto
}
export type GetNamingApiResponse =
	/** status 200 successful operation */ NamingDto
export type GetNamingApiArg = {
	/** Naming type */
	namingType:
		| 'TABLE_CODE'
		| 'MAPPING_CODE'
		| 'INTERFACE_CODE'
		| 'VIEW_CODE'
		| 'API_NODE_CODE'
		| 'API_COLLECTION_CODE'
		| 'FOLDER_CODE'
		| 'QUEUE_CODE'
		| 'SYSTEM_CODE'
		| 'WORKFLOW_CODE'
		| 'HISTORICAL_TABLE_NAME'
		| 'REFERENCE_TABLE_NAME'
		| 'HISTORICAL_TABLE_CODE'
		| 'REFERENCE_TABLE_CODE'
		| 'HISTORICAL_DEFAULT_MAPPING_NAME'
		| 'REFERENCE_DEFAULT_MAPPING_NAME'
		| 'INTERFACE_DEFAULT_MAPPING_NAME'
		| 'HISTORICAL_DEFAULT_MAPPING_CODE'
		| 'REFERENCE_DEFAULT_MAPPING_CODE'
		| 'INTERFACE_DEFAULT_MAPPING_CODE'
		| 'DEFAULT_MAPPING_NAME'
		| 'DEFAULT_MAPPING_CODE'
		| 'DDL_SCRIPT_NAME'
		| 'EXPORT_NAME'
		| 'PRIMARY_KEY_NAME'
		| 'SEQUENCE_NAME'
}
export type DeleteNamingApiResponse = unknown
export type DeleteNamingApiArg = {
	/** Naming type */
	namingType:
		| 'TABLE_CODE'
		| 'MAPPING_CODE'
		| 'INTERFACE_CODE'
		| 'VIEW_CODE'
		| 'API_NODE_CODE'
		| 'API_COLLECTION_CODE'
		| 'FOLDER_CODE'
		| 'QUEUE_CODE'
		| 'SYSTEM_CODE'
		| 'WORKFLOW_CODE'
		| 'HISTORICAL_TABLE_NAME'
		| 'REFERENCE_TABLE_NAME'
		| 'HISTORICAL_TABLE_CODE'
		| 'REFERENCE_TABLE_CODE'
		| 'HISTORICAL_DEFAULT_MAPPING_NAME'
		| 'REFERENCE_DEFAULT_MAPPING_NAME'
		| 'INTERFACE_DEFAULT_MAPPING_NAME'
		| 'HISTORICAL_DEFAULT_MAPPING_CODE'
		| 'REFERENCE_DEFAULT_MAPPING_CODE'
		| 'INTERFACE_DEFAULT_MAPPING_CODE'
		| 'DEFAULT_MAPPING_NAME'
		| 'DEFAULT_MAPPING_CODE'
		| 'DDL_SCRIPT_NAME'
		| 'EXPORT_NAME'
		| 'PRIMARY_KEY_NAME'
		| 'SEQUENCE_NAME'
}
export type GetAllObjectSettingsApiResponse =
	/** status 200 successful operation */ ObjectSettingsDto[]
export type GetAllObjectSettingsApiArg = void
export type CreateObjectSettingsApiResponse =
	/** status 200 successful operation */
	ObjectSettingsDto | /** status 201  */ void
export type CreateObjectSettingsApiArg = {
	/** ObjectSettings dto */
	objectSettingsCreateDto: ObjectSettingsCreateDto
}
export type GetObjectSettingsByTypeApiResponse =
	/** status 200 successful operation */ ObjectSettingsComboDto[]
export type GetObjectSettingsByTypeApiArg = {
	structureType:
		| 'ROOT'
		| 'SYSTEM'
		| 'FOLDER'
		| 'FOLDER_WORKFLOW'
		| 'TABLE'
		| 'MAPPING_DEFAULT'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
		| 'CONSTANT_DOMAIN'
		| 'TABLE_COLUMN'
		| 'DIAGRAM'
}
export type GetAllObjectSettingsForTargetStructureApiResponse =
	/** status 200 successful operation */ ObjectSettingsDto[]
export type GetAllObjectSettingsForTargetStructureApiArg = {
	structureId: number
}
export type GetUsedObjectSettingsApiResponse =
	/** status 200 successful operation */ ObjectSettingsDto[]
export type GetUsedObjectSettingsApiArg = {
	structureId: number[]
}
export type GetAllObjectSettingsForTargetStructureDepApiResponse =
	/** status 200 successful operation */ ObjectSettingsDto[]
export type GetAllObjectSettingsForTargetStructureDepApiArg = {
	/** Structure ID */
	objectSettingsId: number
}
export type UpdateObjectSettingsApiResponse =
	/** status 200 successful operation */ ObjectSettingsDto
export type UpdateObjectSettingsApiArg = {
	/** ObjectSettings id */
	objectSettingsId: number
	/** ObjectSettings dto for update */
	objectSettingsUpdateDto: ObjectSettingsUpdateDto
}
export type DeleteObjectSettingsApiResponse = unknown
export type DeleteObjectSettingsApiArg = {
	/** ObjectSettings id */
	objectSettingsId: number
}
export type GetPermissionsApiResponse =
	/** status 200 successful operation */ Permission[]
export type GetPermissionsApiArg = {
	type: 'NODE' | 'GLOBAL'
}
export type GetAllRelationsByRelationTypeApiResponse =
	/** status 200 successful operation */ RelationDto[]
export type GetAllRelationsByRelationTypeApiArg = {
	relationType?:
		| 'PK_FK_TABLE'
		| 'PK_FK_COLUMN'
		| 'MAPPING_WRITE'
		| 'LINK'
		| 'LINK_COLUMN'
		| 'CUSTOM_LINK'
}
export type GetStereotypeCategoriesApiResponse =
	/** status 200 successful operation */ StereotypeCategoryDto[]
export type GetStereotypeCategoriesApiArg = void
export type GetStereotypeTypesApiResponse =
	/** status 200 successful operation */ StereotypeTypeDto[]
export type GetStereotypeTypesApiArg = void
export type GetStereotypeTypesForObjectSettingsApiResponse =
	/** status 200 successful operation */ StereotypeTypeDto[]
export type GetStereotypeTypesForObjectSettingsApiArg = void
export type GetStereotypesApiResponse =
	/** status 200 successful operation */ StereotypeDto[]
export type GetStereotypesApiArg = {
	internal?: boolean
	type?:
		| 'FOLDER'
		| 'TABLE'
		| 'TABLE_INTERFACE'
		| 'TABLE_HISTORY'
		| 'COLUMN'
		| 'MAPPING'
		| 'MAPPING_TYPE'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'CONSTANT_DOMAIN'
		| 'VIEW'
		| 'WORKFLOW'
		| 'API_NODE'
		| 'API_NODE_ATTRIBUTE'
		| 'API_ROWSET'
		| 'QUEUE'
		| 'TABLE_COLUMN'
		| 'DIAGRAM'
}
export type CreateStereotypeApiResponse =
	/** status 200 successful operation */ StereotypeDto | /** status 201  */ void
export type CreateStereotypeApiArg = {
	/** Stereotype dto */
	stereotypeCreateDto: StereotypeCreateDto
}
export type GetStereotypesForTemplatesApiResponse =
	/** status 200 successful operation */ StereotypeDto[]
export type GetStereotypesForTemplatesApiArg = {
	type?:
		| 'FOLDER'
		| 'TABLE'
		| 'TABLE_INTERFACE'
		| 'TABLE_HISTORY'
		| 'COLUMN'
		| 'MAPPING'
		| 'MAPPING_TYPE'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'CONSTANT_DOMAIN'
		| 'VIEW'
		| 'WORKFLOW'
		| 'API_NODE'
		| 'API_NODE_ATTRIBUTE'
		| 'API_ROWSET'
		| 'QUEUE'
		| 'TABLE_COLUMN'
		| 'DIAGRAM'
}
export type GetStereotypesForTableTemplatesApiResponse =
	/** status 200 successful operation */ StereotypeDto[]
export type GetStereotypesForTableTemplatesApiArg = void
export type UpdateStereotypeApiResponse =
	/** status 200 successful operation */ StereotypeDto
export type UpdateStereotypeApiArg = {
	/** Stereotype id */
	stereotypeId: number
	/** Stereotype dto for update */
	stereotypeUpdateDto: StereotypeUpdateDto
}
export type DeleteStereotypeApiResponse = unknown
export type DeleteStereotypeApiArg = {
	/** Stereotype id */
	stereotypeId: number
}
export type GetSubStereotypesApiResponse =
	/** status 200 successful operation */ StereotypeDto[]
export type GetSubStereotypesApiArg = {
	stereotypeId: number
}
export type GetRootNodesApiResponse =
	/** status 200 successful operation */ StructureDto[]
export type GetRootNodesApiArg = void
export type CreateNodeApiResponse =
	/** status 200 successful operation */ StructureDto
export type CreateNodeApiArg = {
	newStructureDto: NewStructureDto
}
export type GetAllStructureTypeDtoApiResponse =
	/** status 200 successful operation */ StructureTypeDto[]
export type GetAllStructureTypeDtoApiArg = void
export type SearchFullTextApiResponse =
	/** status 200 successful operation */ FullTextSearchDto[]
export type SearchFullTextApiArg = {
	searchText: string
}
export type SearchFullTextJsonApiResponse =
	/** status 200 successful operation */ FullTextSearchDto[]
export type SearchFullTextJsonApiArg = {
	searchText: string
}
export type ImportStructureFileApiResponse = unknown
export type ImportStructureFileApiArg = {
	structureExportDto: StructureExportDto
}
export type ImportStructureFileIntoNodeApiResponse = unknown
export type ImportStructureFileIntoNodeApiArg = {
	structureExportDto: StructureExportDto
	structureId: number
}
export type ImportStructureCsvIntoNodeApiResponse =
	/** status 200 successful operation */ CsvImportReturnDto
export type ImportStructureCsvIntoNodeApiArg = {
	body: CsvImportRowDto[]
	caseSensitive?: boolean
	createNewDomains?: boolean
	generateCodes?: boolean
	importAllColumns?: boolean
	nameNormalisation?: 'NONE' | 'UPPERCASE_FIRST_CHARS' | 'UPPERCASE_ALL'
	structureId: number
}
export type ImportStructureCsvIntoNodeCheckApiResponse =
	/** status 200 successful operation */ CsvImportReturnDto
export type ImportStructureCsvIntoNodeCheckApiArg = {
	body: CsvImportRowDto[]
	caseSensitive?: boolean
	createNewDomains?: boolean
	generateCodes?: boolean
	nameNormalisation?: 'NONE' | 'UPPERCASE_FIRST_CHARS' | 'UPPERCASE_ALL'
	structureId: number
}
export type GenerateMassExportFileApiResponse = /** status 200 OK */ string[]
export type GenerateMassExportFileApiArg = {
	ignoreErrors?: boolean
	massExportConfigDto: MassExportConfigDto
}
export type SearchNodesApiResponse =
	/** status 200 successful operation */ StructureSearchedDto[]
export type SearchNodesApiArg = {
	searchText: string
}
export type SearchStructureApiResponse =
	/** status 200 successful operation */ StructureObjectDto[]
export type SearchStructureApiArg = {
	nodeType?:
		| 'SYSTEM_NODE'
		| 'FOLDER'
		| 'WORKFLOW'
		| 'TABLE'
		| 'MAPPING'
		| 'VIEW'
		| 'API'
		| 'QUEUE'
	searchText: string
}
export type GetSystemEnvironmentsApiResponse =
	/** status 200 successful operation */ EnvironmentDto[]
export type GetSystemEnvironmentsApiArg = {
	/** Id of system */
	systemId: number
}
export type ResetVersionApiResponse = unknown
export type ResetVersionApiArg = {
	/** Id of environment */
	envId: number
	/** Id of system */
	systemId: number
}
export type TakeOverVersionApiResponse = unknown
export type TakeOverVersionApiArg = {
	/** Id destination environment */
	envId: number
	/** Id source environment */
	fromId: number
	/** Id of system */
	systemId: number
}
export type GetSystemReleasesApiResponse =
	/** status 200 successful operation */ ReleaseDto[]
export type GetSystemReleasesApiArg = {
	/** Only opened releases */
	onlyOpen?: boolean
	systemId: number
}
export type CreateSystemReleaseApiResponse =
	/** status 200 successful operation */ ReleaseDto
export type CreateSystemReleaseApiArg = {
	releaseDto: ReleaseDto
	systemId: number
}
export type GetSystemReleaseApiResponse =
	/** status 200 successful operation */ ReleaseDto
export type GetSystemReleaseApiArg = {
	releaseId: number
	systemId: number
}
export type UpdateSystemReleaseApiResponse =
	/** status 200 successful operation */ ReleaseDto
export type UpdateSystemReleaseApiArg = {
	releaseDto: ReleaseDto
	releaseId: number
	systemId: number
}
export type DeleteSystemReleaseApiResponse = unknown
export type DeleteSystemReleaseApiArg = {
	releaseId: number
	systemId: number
}
export type GetReleaseEnvironmentsApiResponse =
	/** status 200 successful operation */ ReleaseEnvironmentDto[]
export type GetReleaseEnvironmentsApiArg = {
	releaseId: number
	systemId: number
}
export type CreateReleaseEnvironmentApiResponse =
	/** status 200 successful operation */ ReleaseEnvironmentDto
export type CreateReleaseEnvironmentApiArg = {
	releaseEnvironmentDto: ReleaseEnvironmentDto
	releaseId: number
	systemId: number
}
export type UpdateReleaseEnvironmentApiResponse =
	/** status 200 successful operation */ ReleaseEnvironmentDto
export type UpdateReleaseEnvironmentApiArg = {
	envId: number
	releaseEnvironmentDto: ReleaseEnvironmentDto
	releaseId: number
	systemId: number
}
export type DeleteReleaseEnvironmentApiResponse = unknown
export type DeleteReleaseEnvironmentApiArg = {
	envId: number
	releaseId: number
	systemId: number
}
export type PushReleaseToGitApiResponse = unknown
export type PushReleaseToGitApiArg = {
	envId: number
	massExportConfigDto: MassExportConfigDto
	releaseId: number
	systemId: number
}
export type CreateReleasePreviewApiResponse =
	/** status 200 successful operation */ string
export type CreateReleasePreviewApiArg = {
	envId: number
	massExportConfigDto: MassExportConfigDto
	releaseId: number
	systemId: number
}
export type CreateReleaseScriptsApiResponse = /** status 200 OK */ string[]
export type CreateReleaseScriptsApiArg = {
	envId: number
	massExportConfigDto: MassExportConfigDto
	releaseId: number
	systemId: number
}
export type CreateDeploymentsApiResponse = unknown
export type CreateDeploymentsApiArg = {
	envId: number
	massExportConfigDto: MassExportConfigDto
	releaseId: number
	systemId: number
}
export type ChangeReleaseStatusApiResponse = unknown
export type ChangeReleaseStatusApiArg = {
	newStatus: 'OPEN' | 'CANCEL' | 'COMPLETED'
	releaseId: number
	systemId: number
}
export type GetReleaseStructuresApiResponse =
	/** status 200 successful operation */ PageStructureReleaseInfoDto
export type GetReleaseStructuresApiArg = {
	page?: number
	pageSize?: number
	releaseId: number
	systemId: number
}
export type AddStructureToReleaseApiResponse = unknown
export type AddStructureToReleaseApiArg = {
	body: number[]
	releaseId: number
	systemId: number
}
export type DeleteReleaseStructureApiResponse = unknown
export type DeleteReleaseStructureApiArg = {
	releaseId: number
	structureId: number
	systemId: number
}
export type GetAllTablesForSystemNodeApiResponse =
	/** status 200 successful operation */ StructureDto[]
export type GetAllTablesForSystemNodeApiArg = {
	systemId: number
}
export type GetMappingsOfSystemNodesApiResponse =
	/** status 200 successful operation */ StructureDto[]
export type GetMappingsOfSystemNodesApiArg = {
	systemStructureId: number
}
export type GetWorkflowsOfSystemNodeApiResponse =
	/** status 200 successful operation */ StructureDto[]
export type GetWorkflowsOfSystemNodeApiArg = {
	systemStructureId: number
}
export type GetNodeDetailApiResponse =
	/** status 200 successful operation */ StructureDetailDto
export type GetNodeDetailApiArg = {
	structureId: number
}
export type DeleteNodeApiResponse = unknown
export type DeleteNodeApiArg = {
	structureId: number
}
export type GetApiNodeColumnFromAllStructuresApiResponse =
	/** status 200 successful operation */ ApiNodeColumnDto[]
export type GetApiNodeColumnFromAllStructuresApiArg = {
	structureId: number
}
export type GetNodesOfSpecificParentApiResponse =
	/** status 200 successful operation */ StructureDto[]
export type GetNodesOfSpecificParentApiArg = {
	recursive?: boolean
	structureId: number
}
export type CreateChildNodeApiResponse =
	/** status 200 successful operation */ StructureDetailDto
export type CreateChildNodeApiArg = {
	newStructureDto: NewStructureDto
	structureId: number
}
export type ImportColumnsFromCsvApiResponse =
	/** status 200 successful operation */ CsvImportReturnDto
export type ImportColumnsFromCsvApiArg = {
	body: ImportColumn[]
	caseSensitive?: boolean
	createNewDomains?: boolean
	generateCodes?: boolean
	importAllColumns?: boolean
	nameNormalisation?: 'NONE' | 'UPPERCASE_FIRST_CHARS' | 'UPPERCASE_ALL'
	structureId: number
}
export type ImportColumnsFromCsvCheckApiResponse =
	/** status 200 successful operation */ CsvImportReturnDto
export type ImportColumnsFromCsvCheckApiArg = {
	body: ImportColumn[]
	caseSensitive?: boolean
	createNewDomains?: boolean
	generateCodes?: boolean
	nameNormalisation?: 'NONE' | 'UPPERCASE_FIRST_CHARS' | 'UPPERCASE_ALL'
	structureId: number
}
export type EditDataNodeApiResponse =
	/** status 200 successful operation */ StructureDataDto
export type EditDataNodeApiArg = {
	structureId: number
}
export type UpdateDataNodeApiResponse = unknown
export type UpdateDataNodeApiArg = {
	structureId: number
	structureWorkingDataDto: StructureWorkingDataDto
}
export type DeleteDataNodeApiResponse = unknown
export type DeleteDataNodeApiArg = {
	structureId: number
}
export type GetDiagramApiResponse =
	/** status 200 successful operation */ TableDiagramDto
export type GetDiagramApiArg = {
	structureId: number
}
export type GetDeepDiagramApiResponse =
	/** status 200 successful operation */ TableDiagramDto
export type GetDeepDiagramApiArg = {
	structureId: number
}
export type CreateFromSystemStructureApiResponse =
	/** status 200 successful operation */ TableDiagramDto
export type CreateFromSystemStructureApiArg = {
	structureId: number
}
export type GetDiagramFromRelationApiResponse =
	/** status 200 successful operation */ TableDiagramDto
export type GetDiagramFromRelationApiArg = {
	structureId: number
}
export type DuplicateNodeApiResponse =
	/** status 200 successful operation */ StructureDetailDto
export type DuplicateNodeApiArg = {
	structureId: number
}
export type DuplicateNodeRecursiveApiResponse =
	/** status 200 successful operation */ string
export type DuplicateNodeRecursiveApiArg = {
	structureId: number
	suffix?: string
	useSuffixOnly4System?: boolean
}
export type GenerateAndPushToGitApiResponse = unknown
export type GenerateAndPushToGitApiArg = {
	environmentId: number
	objectSettingsId: number
	structureId: number
}
export type GenerateSqlPreviewEnvironmentApiResponse =
	/** status 200 successful operation */ GeneratedSqlDtoRead
export type GenerateSqlPreviewEnvironmentApiArg = {
	environmentId: number
	objectSettingsId: number
	structureId: number
}
export type GenerateSqlApiResponse =
	/** status 200 successful operation */ GeneratedSqlDtoRead
export type GenerateSqlApiArg = {
	environmentId: number
	objectSettingsId: number
	structureId: number
}
export type GenerateSqlDownloadZipFileApiResponse = unknown
export type GenerateSqlDownloadZipFileApiArg = {
	environmentId: number
	objectTypeId: number
	structureId: number
}
export type ExportStructureApiResponse =
	/** status 200 successful operation */ StructureExport
export type ExportStructureApiArg = {
	structureId: number
}
export type GetAllNodeHistoryApiResponse =
	/** status 200 successful operation */ HistoryWithDeploymentDto[]
export type GetAllNodeHistoryApiArg = {
	structureId: number
}
export type CreateNodeHistoryApiResponse =
	/** status 200 successful operation */ HistoryDto
export type CreateNodeHistoryApiArg = {
	fixImpacts?: boolean
	newHistoryDto: NewHistoryDto
	structureId: number
}
export type GetNodeHistoryDetailByVersionApiResponse =
	/** status 200 successful operation */ HistoryDetailDto
export type GetNodeHistoryDetailByVersionApiArg = {
	envId?: number
	structureId: number
	version: number
}
export type GetNodeHistoryDetailApiResponse =
	/** status 200 successful operation */ HistoryDetailDto
export type GetNodeHistoryDetailApiArg = {
	historyId: number
	structureId: number
}
export type DeleteNodeHistoryApiResponse = unknown
export type DeleteNodeHistoryApiArg = {
	historyId: number
	structureId: number
}
export type CreateNodeLockApiResponse = unknown
export type CreateNodeLockApiArg = {
	structureId: number
}
export type DeleteNodeLockApiResponse = unknown
export type DeleteNodeLockApiArg = {
	structureId: number
}
export type GetMappingsApiResponse =
	/** status 200 successful operation */ StructureDto[]
export type GetMappingsApiArg = {
	structureId: number
}
export type GenerateMassExportPushGitApiResponse = unknown
export type GenerateMassExportPushGitApiArg = {
	massExportConfigDto: MassExportConfigDto
	structureId: number
}
export type MassPatchStructuresListApiResponse =
	/** status 200 successful operation */ string
export type MassPatchStructuresListApiArg = {
	massUpdateDto: MassUpdateDto
	structureId: number
}
export type MoveNodeApiResponse = unknown
export type MoveNodeApiArg = {
	destinationStructureId: number
	structureId: number
}
export type ReorderNodeApiResponse = unknown
export type ReorderNodeApiArg = {
	reorderStructureDto: ReorderStructureDto
	structureId: number
}
export type PatchMergeStructuresApiResponse =
	/** status 200 successful operation */ {
		[key: string]: object
	}
export type PatchMergeStructuresApiArg = {
	massUpdateDto: MassUpdateDto
	structureId: number
}
export type GetStructurePermissionsApiResponse =
	/** status 200 successful operation */ StructureUserPermissionDto[]
export type GetStructurePermissionsApiArg = {
	structureId: number
}
export type UpdateBatchEnvironmentsApiResponse =
	/** status 200 successful operation */ StructureUserPermissionDto[]
export type UpdateBatchEnvironmentsApiArg = {
	body: NewStructureUserPermissionBatchDto[]
	structureId: number
}
export type GetUserPermissionApiResponse =
	/** status 200 successful operation */ StructureUserPermissionDto
export type GetUserPermissionApiArg = {
	structureId: number
	userId: number
}
export type ChangeUserPermissionApiResponse = unknown
export type ChangeUserPermissionApiArg = {
	newStructureUserPermissionDto: NewStructureUserPermissionDto
	structureId: number
	userId: number
}
export type DeleteUserPermissionApiResponse = unknown
export type DeleteUserPermissionApiArg = {
	structureId: number
	userId: number
}
export type PrePushValidationApiResponse =
	/** status 200 successful operation */ PrePushValidationDto[]
export type PrePushValidationApiArg = {
	structureId: number
}
export type GetNodeRelationsApiResponse =
	/** status 200 successful operation */ RelationDto[]
export type GetNodeRelationsApiArg = {
	structureId: number
}
export type CreateRelationApiResponse =
	/** status 200 successful operation */ RelationDto
export type CreateRelationApiArg = {
	/** Relation */
	relationCreateDto: RelationCreateDto
	structureId: number
}
export type UpdateRelationApiResponse =
	/** status 200 successful operation */ RelationDto
export type UpdateRelationApiArg = {
	relationDto: RelationDto
	relationId: number
	structureId: number
}
export type DeleteRelationApiResponse = unknown
export type DeleteRelationApiArg = {
	relationId: number
	structureId: number
}
export type PathRelationApiResponse =
	/** status 200 successful operation */ RelationDto
export type PathRelationApiArg = {
	jsonPatch: JsonPatch
	relationId: number
	structureId: number
}
export type GenerateSqlPreviewApiResponse =
	/** status 200 successful operation */ GeneratedSqlDtoRead
export type GenerateSqlPreviewApiArg = {
	objectSettingsId?: number
	structureId: number
}
export type GenerateSqlPreviewDownloadZipFileApiResponse = unknown
export type GenerateSqlPreviewDownloadZipFileApiArg = {
	objectTypeId?: number
	structureId: number
}
export type GetExistingStructuresForSourceMappingApiResponse =
	/** status 200 successful operation */ StructureObjectDto[]
export type GetExistingStructuresForSourceMappingApiArg = {
	sourceObjectType:
		| 'ROOT'
		| 'SYSTEM'
		| 'FOLDER'
		| 'FOLDER_WORKFLOW'
		| 'TABLE'
		| 'MAPPING_DEFAULT'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
		| 'CONSTANT_DOMAIN'
		| 'TABLE_COLUMN'
		| 'DIAGRAM'
	structureId: number
}
export type GetAvailableStructureForSourceObjectApiResponse =
	/** status 200 successful operation */ StructureObjectDto[]
export type GetAvailableStructureForSourceObjectApiArg = {
	name?: string
	sourceObjectType:
		| 'ROOT'
		| 'SYSTEM'
		| 'FOLDER'
		| 'FOLDER_WORKFLOW'
		| 'TABLE'
		| 'MAPPING_DEFAULT'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
		| 'CONSTANT_DOMAIN'
		| 'TABLE_COLUMN'
		| 'DIAGRAM'
	structureId: number
}
export type GetSystemUsersApiResponse =
	/** status 200 successful operation */ SystemUser[]
export type GetSystemUsersApiArg = {
	structureId: number
}
export type GetSystemUserApiResponse =
	/** status 200 successful operation */ SystemUser
export type GetSystemUserApiArg = {
	/** SystemUser id */
	id: number
	structureId: number
}
export type GetStructureTreeApiResponse =
	/** status 200 successful operation */ StructureDto[]
export type GetStructureTreeApiArg = {
	structureId: number
	structureQuickFilter: StructureQuickFilter
}
export type GetUniversalColumnFromAllStructuresApiResponse =
	/** status 200 successful operation */ ApiNodeColumnDto[]
export type GetUniversalColumnFromAllStructuresApiArg = {
	structureId: number
}
export type GetWriteColumnFromAllStructuresApiResponse =
	/** status 200 successful operation */ ApiNodeColumnDto[]
export type GetWriteColumnFromAllStructuresApiArg = {
	structureId: number
}
export type GetAllSystemTechnologiesApiResponse =
	/** status 200 successful operation */ TechnologyDto[]
export type GetAllSystemTechnologiesApiArg = void
export type SearchTableColumnsApiResponse =
	/** status 200 successful operation */ AutocompleteResultDto[]
export type SearchTableColumnsApiArg = {
	actualStructureId?: number
	searchText?: string
	systemId: number
	tableCode: string
}
export type SearchNodesWithTypeApiResponse =
	/** status 200 successful operation */ AutocompleteResultDto[]
export type SearchNodesWithTypeApiArg = {
	actualStructureId?: number
	searchText: string
	structureType:
		| 'ROOT'
		| 'SYSTEM'
		| 'FOLDER'
		| 'FOLDER_WORKFLOW'
		| 'TABLE'
		| 'MAPPING_DEFAULT'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
		| 'CONSTANT_DOMAIN'
		| 'TABLE_COLUMN'
		| 'DIAGRAM'
	systemId: number
}
export type GetAsyncTasksApiResponse =
	/** status 200 successful operation */ PageWrapperAsyncTaskExecutionDto
export type GetAsyncTasksApiArg = {
	page?: number
	pageSize?: number
	state?: ('NEW' | 'RUNNING' | 'ERROR' | 'FINISHED' | 'CANCELLED')[]
}
export type CreateApiResponse =
	/** status 200 successful operation */ AsyncTaskExecutionDto
export type CreateApiArg = {
	body: {
		[key: string]: object
	}
	type: 'MASS_EXPORT' | 'UPDATE_TECHNICAL_COLUMNS'
}
export type GetTaskExecutionApiResponse =
	/** status 200 successful operation */ AsyncTaskExecutionDto
export type GetTaskExecutionApiArg = {
	id: number
}
export type CancelTaskApiResponse = unknown
export type CancelTaskApiArg = {
	id: number
}
export type GetTaskResultFileApiResponse = unknown
export type GetTaskResultFileApiArg = {
	id: number
}
export type GetTaskResultApiResponse =
	/** status 200 successful operation */ object
export type GetTaskResultApiArg = {
	id: number
}
export type GetAllTechnicalColumnsApiResponse =
	/** status 200 successful operation */ TechnicalColumnDtoRead[]
export type GetAllTechnicalColumnsApiArg = void
export type CreateTechnicalColumnApiResponse =
	/** status 200 successful operation */
	TechnicalColumnDtoRead | /** status 201  */ void
export type CreateTechnicalColumnApiArg = {
	/** Technical column dto */
	technicalColumnDto: TechnicalColumnDto
	/** Update affected elements */
	updateElements?: boolean
}
export type GetTechnicalColumnStereotypeApiResponse =
	/** status 200 successful operation */ TechnicalColumnStereotypeDto[]
export type GetTechnicalColumnStereotypeApiArg = {
	/** Stereotype id */
	stereotypeId: number
	technologyId: number
}
export type UpdateTechnicalColumnApiResponse =
	/** status 200 successful operation */ TechnicalColumnDtoRead
export type UpdateTechnicalColumnApiArg = {
	/** Technical column dto for update */
	technicalColumnDto: TechnicalColumnDto
	/** Technical column id */
	technicalColumnId: number
	/** Update affected elements */
	updateElements?: boolean
}
export type DeleteTechnicalColumnApiResponse = unknown
export type DeleteTechnicalColumnApiArg = {
	/** Technical column id */
	technicalColumnId: number
	/** Update affected elements */
	updateElements?: boolean
}
export type ComputeImpactTcChangeApiResponse =
	/** status 200 successful operation */ ImpactChangeCodeListDto
export type ComputeImpactTcChangeApiArg = {
	/** Technical column dto for update */
	technicalColumnDto: TechnicalColumnDto
	/** Technical column id */
	technicalColumnId: number
}
export type GetTablesByTechnicalColumnApiResponse =
	/** status 200 successful operation */ StructureObjectDto[]
export type GetTablesByTechnicalColumnApiArg = {
	/** Technical column id */
	technicalColumnId: number
}
export type GetTablesByTechnicalColumnCsvApiResponse =
	/** status 200 successful operation */ string[]
export type GetTablesByTechnicalColumnCsvApiArg = {
	/** Technical column id */
	technicalColumnId: number
}
export type GetAllTechnologiesApiResponse =
	/** status 200 successful operation */ TechnologyDto[]
export type GetAllTechnologiesApiArg = void
export type GetAllTemplatesApiResponse =
	/** status 200 successful operation */ TemplateListDtoRead[]
export type GetAllTemplatesApiArg = {
	stereotypeId?: number
	technologyId?: number
}
export type CreateTemplateApiResponse =
	/** status 200 successful operation */
	TemplateDtoRead | /** status 201  */ void
export type CreateTemplateApiArg = {
	templateDto: TemplateDto
}
export type GetTemplateApiResponse =
	/** status 200 successful operation */ TemplateDtoRead
export type GetTemplateApiArg = {
	id: number
}
export type UpdateTemplateApiResponse =
	/** status 200 successful operation */ TemplateDtoRead
export type UpdateTemplateApiArg = {
	id: number
	templateDto: TemplateDto
}
export type DeleteTemplateApiResponse = unknown
export type DeleteTemplateApiArg = {
	id: number
}
export type GetTemplateHistoryApiResponse =
	/** status 200 successful operation */ TemplateListDtoRead[]
export type GetTemplateHistoryApiArg = {
	id: number
}
export type GetTemplateHistoryDetailApiResponse =
	/** status 200 successful operation */ TemplateDtoRead
export type GetTemplateHistoryDetailApiArg = {
	id: number
	version: number
}
export type LockApiResponse = unknown
export type LockApiArg = {
	templateId: number
}
export type PushApiResponse = unknown
export type PushApiArg = {
	templateId: number
	unlock?: boolean
}
export type UnlockApiResponse = unknown
export type UnlockApiArg = {
	templateId: number
}
export type GetUsersApiResponse =
	/** status 200 successful operation */ UserDto[]
export type GetUsersApiArg = void
export type AddUserApiResponse = /** status 200 successful operation */ UserDto
export type AddUserApiArg = {
	newUserDto: NewUserDto
}
export type GetApiUsersApiResponse =
	/** status 200 successful operation */ ApiUserDtoRead[]
export type GetApiUsersApiArg = void
export type AddApiUserApiResponse =
	/** status 200 successful operation */ ApiUserDtoRead
export type AddApiUserApiArg = {
	apiUserDto: ApiUserDto
}
export type DeleteApiUserApiResponse = unknown
export type DeleteApiUserApiArg = {
	clientId: string
}
export type ApiUserResetSecretApiResponse =
	/** status 200 successful operation */ ApiUserDtoRead
export type ApiUserResetSecretApiArg = {
	clientId: string
}
export type GetUsersForComboApiResponse =
	/** status 200 successful operation */ UserComboDto[]
export type GetUsersForComboApiArg = void
export type GetSessionContextApiResponse =
	/** status 200 successful operation */ SessionContextDto
export type GetSessionContextApiArg = void
export type UpdateSessionContextApiResponse =
	/** status 200 successful operation */ SessionContextDto
export type UpdateSessionContextApiArg = {
	sessionContextDto: SessionContextDto
}
export type LockUserApiResponse =
	/** status 200 successful operation */ UserMessageDto
export type LockUserApiArg = {
	/** User id */
	userId: number
}
export type ResetUserPasswordApiResponse = unknown
export type ResetUserPasswordApiArg = {
	resetPasswordDto: ResetPasswordDto
}
export type UnlockUserApiResponse =
	/** status 200 successful operation */ UserMessageDto
export type UnlockUserApiArg = {
	/** User id */
	userId: number
}
export type GetUserApiResponse = /** status 200 successful operation */ UserDto
export type GetUserApiArg = {
	userId: number
}
export type EditUserApiResponse = /** status 200 successful operation */ UserDto
export type EditUserApiArg = {
	editUserInfoDto: EditUserInfoDto
	userId: number
}
export type DeleteUserApiResponse = unknown
export type DeleteUserApiArg = {
	userId: number
}
export type ResetLoginAttemptsApiResponse = unknown
export type ResetLoginAttemptsApiArg = {
	userId: number
}
export type GetUserRolesApiResponse =
	/** status 200 successful operation */ UserRolesDto
export type GetUserRolesApiArg = {
	userId: number
}
export type EditUserRolesApiResponse =
	/** status 200 successful operation */ UserRolesDto
export type EditUserRolesApiArg = {
	userId: number
	userRolesDto: UserRolesDto
}
export type AdjustTableNameApiResponse =
	/** status 200 successful operation */ AdjustResultDto
export type AdjustTableNameApiArg = {
	utilsNameRequestDto: UtilsNameRequestDto
}
export type CheckUniqueNameApiResponse =
	/** status 200 successful operation */ CheckResultDto
export type CheckUniqueNameApiArg = {
	utilsNameRequestDto: UtilsNameRequestDto
}
export type CheckStructureApiResponse =
	/** status 200 successful operation */ ValidationMessageDto[]
export type CheckStructureApiArg = {
	structureId: number
}
export type GetAllWorkflowTechnologiesApiResponse =
	/** status 200 successful operation */ TechnologyDto[]
export type GetAllWorkflowTechnologiesApiArg = void
export type AcronymDto = {
	acronym?: string
	createdAt?: string
	createdBy?: string
	deleted?: boolean
	id?: number
	text?: string
	updatedAt?: string
	updatedBy?: string
	version?: number
}
export type PageableDtoAcronymDto = {
	acronyms?: AcronymDto[]
	page?: number
	size?: number
	totalElements?: number
	totalPages?: number
}
export type AcronymCreateDto = {
	acronym?: string
	text?: string
}
export type AcronymUpdateDto = {
	acronym?: string
	text?: string
}
export type User = {
	api?: boolean
	company?: number
	department?: string
	description?: string
	email?: string
	extId?: string
	id?: number
	loginName?: string
	name?: string
	sessionContext?: string
	surname?: string
}
export type StructureAudit = {
	author?: User
	created?: string
	description?: string
	entityId?: number
	entityType?: 'STRUCTURE' | 'DOMAIN'
	id?: number
	operationType?:
		| 'LOCK'
		| 'UNLOCK'
		| 'FORCE_UNLOCK'
		| 'UPDATE_DATA'
		| 'UPDATE'
		| 'CREATE'
		| 'DELETE'
	plainDataAfter?: string
	plainDataBefore?: string
	relatedPerson?: User
}
export type BusinessDomainDto = {
	companyName?: string
	createdAt?: string
	createdBy?: string
	deleted?: boolean
	description?: string
	id?: number
	name?: string
	updatedAt?: string
	updatedBy?: string
	version?: number
}
export type BusinessDomainComboDto = {
	companyName?: string
	description?: string
	id?: number
	name?: string
}
export type Company = {
	id?: number
	name?: string
	users?: User[]
}
export type StructureTypeDto = {
	code?: string
}
export type CustomAttributeDto = {
	createdAt?: string
	createdBy?: string
	customLovId?: number
	deleted?: boolean
	description?: string
	id?: number
	name?: string
	ordering?: number
	regex?: string
	stereotypeIds?: number[]
	structureTypes?: (
		| 'SYSTEM'
		| 'FOLDER'
		| 'FOLDER_WORKFLOW'
		| 'TABLE'
		| 'TABLE_COLUMN'
		| 'MAPPING_DEFAULT'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
	)[]
	type?: 'STRING' | 'SCRIPT' | 'NUMBER' | 'BOOLEAN' | 'LOV' | 'ARRAY'
	updatedAt?: string
	updatedBy?: string
	version?: number
}
export type CustomAttributeCreateDto = {
	customLovId?: number
	description?: string
	id?: number
	name?: string
	ordering?: number
	regex?: string
	stereotypeIds?: number[]
	structureTypes?: (
		| 'SYSTEM'
		| 'FOLDER'
		| 'FOLDER_WORKFLOW'
		| 'TABLE'
		| 'TABLE_COLUMN'
		| 'MAPPING_DEFAULT'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
	)[]
	type?: 'STRING' | 'SCRIPT' | 'NUMBER' | 'BOOLEAN' | 'LOV' | 'ARRAY'
	version?: number
}
export type CustomLovValueComboDto = {
	label?: string
	ordering?: number
	value?: string
}
export type CustomAttributeComboDto = {
	description?: string
	id?: number
	lovValues?: CustomLovValueComboDto[]
	name?: string
	ordering?: number
	regex?: string
	stereotypeIds?: number[]
	structureTypes?: (
		| 'SYSTEM'
		| 'FOLDER'
		| 'FOLDER_WORKFLOW'
		| 'TABLE'
		| 'TABLE_COLUMN'
		| 'MAPPING_DEFAULT'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
	)[]
	type?: 'STRING' | 'SCRIPT' | 'NUMBER' | 'BOOLEAN' | 'LOV' | 'ARRAY'
	version?: number
}
export type CustomAttributeUpdateDto = {
	customLovId?: number
	description?: string
	id?: number
	name?: string
	ordering?: number
	regex?: string
	stereotypeIds?: number[]
	structureTypes?: (
		| 'SYSTEM'
		| 'FOLDER'
		| 'FOLDER_WORKFLOW'
		| 'TABLE'
		| 'TABLE_COLUMN'
		| 'MAPPING_DEFAULT'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
	)[]
	type?: 'STRING' | 'SCRIPT' | 'NUMBER' | 'BOOLEAN' | 'LOV' | 'ARRAY'
	version?: number
}
export type CustomLovDto = {
	createdAt?: string
	createdBy?: string
	deleted?: boolean
	description?: string
	id?: number
	lovValues?: CustomLovValueComboDto[]
	name?: string
	updatedAt?: string
	updatedBy?: string
	version?: number
}
export type CustomLovCreateDto = {
	description?: string
	id?: number
	lovValues?: CustomLovValueComboDto[]
	name?: string
	version?: number
}
export type CustomLovComboDto = {
	description?: string
	id?: number
	lovValues?: CustomLovValueComboDto[]
	name?: string
	version?: number
}
export type CustomLovUpdateDto = {
	description?: string
	id?: number
	lovValues?: CustomLovValueComboDto[]
	name?: string
	version?: number
}
export type DeploymentDto = {
	authorId?: number
	authorName?: string
	created?: string
	description?: string
	environmentCode?: string
	environmentFeId?: number
	environmentId?: number
	id?: number
	releaseId?: number
	sqlType?:
		| 'TABLE_HISTORY'
		| 'TABLE_REFERENCE'
		| 'TABLE'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
	structureHistoryId?: number
	structureId?: number
	templateId?: number
	version?: number
}
export type DeploymentNewDto = {
	data?: string
	description?: string
	sqlType?:
		| 'TABLE_HISTORY'
		| 'TABLE_REFERENCE'
		| 'TABLE'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
	structureHistoryId?: number
	templateId?: number
}
export type DeploymentDetailDto = {
	authorId?: number
	authorName?: string
	created?: string
	data?: string
	description?: string
	environmentCode?: string
	environmentFeId?: number
	environmentId?: number
	id?: number
	releaseId?: number
	sqlType?:
		| 'TABLE_HISTORY'
		| 'TABLE_REFERENCE'
		| 'TABLE'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
	structureHistoryId?: number
	structureId?: number
	templateId?: number
	version?: number
}
export type DomainTechnologyDto = {
	dataType?: string
	defaultValue?: string
	id?: number
	technologyCode?: string
	technologyId?: number
}
export type DomainDto = {
	code?: string
	companyName?: string
	createdAt?: string
	createdBy?: string
	custom?: boolean
	deleted?: boolean
	description?: string
	domainTechnologies?: DomainTechnologyDto[]
	hasTemplate?: boolean
	id?: number
	length?: string
	name?: string
	notNullFlag?: boolean
	template?: string
	templateValueFormat?: string
	templateValueRegex?: string
	textValueFlag?: boolean
	updatedAt?: string
	updatedBy?: string
	version?: number
}
export type DomainCreateDto = {
	code?: string
	companyName?: string
	custom?: boolean
	description?: string
	domainTechnologies?: DomainTechnologyDto[]
	hasTemplate?: boolean
	length?: string
	name?: string
	notNullFlag?: boolean
	template?: string
	templateValueFormat?: string
	templateValueRegex?: string
	textValueFlag?: boolean
}
export type DomainComboDto = {
	code?: string
	companyName?: string
	createdAt?: string
	createdBy?: string
	custom?: boolean
	deleted?: boolean
	description?: string
	domainTechnologies?: DomainTechnologyDto[]
	hasTemplate?: boolean
	id?: number
	length?: string
	name?: string
	notNullFlag?: boolean
	template?: string
	templateValueFormat?: string
	templateValueRegex?: string
	textValueFlag?: boolean
	updatedAt?: string
	updatedBy?: string
	version?: number
}
export type DomainUpdateDto = {
	code?: string
	companyName?: string
	custom?: boolean
	description?: string
	domainTechnologies?: DomainTechnologyDto[]
	hasTemplate?: boolean
	id?: number
	length?: string
	name?: string
	notNullFlag?: boolean
	template?: string
	templateValueFormat?: string
	templateValueRegex?: string
	textValueFlag?: boolean
}
export type ImpactedColumnDto = {
	columnCode?: string
	columnName?: string
	columnUuid?: string
	tableCode?: string
	tableId?: number
	tableName?: string
}
export type ImpactChangeCodeListDto = {
	affectedElements?: {
		[key: string]: number
	}
}
export type UserDto = {
	canBeEdited?: boolean
	companyName?: string
	compositeName?: string
	deleted?: boolean
	department?: string
	description?: string
	email?: string
	hardLock?: boolean
	id?: number
	loginName?: string
	name?: string
	sessionContext?: string
	surname?: string
}
export type ReleaseDto = {
	description?: string
	id?: number
	name?: string
	responsibleUser?: UserDto
	responsibleUserId?: number
	status?: 'OPEN' | 'CANCEL' | 'COMPLETED'
}
export type EnvironmentDto = {
	code?: string
	connection?: string
	description?: string
	directDeployment?: boolean
	frontendId?: number
	gitLoginName?: string
	gitPasswordToken?: string
	gitUrl?: string
	id?: number
	lastRelease?: ReleaseDto
	name?: string
	production?: boolean
	rememberPassword?: boolean
}
export type ObjectSettingsDto = {
	code?: string
	comment?: string
	createdAt?: string
	createdBy?: string
	defaultFlag?: boolean
	deleted?: boolean
	description?: string
	id?: number
	name?: string
	structureType?:
		| 'ROOT'
		| 'SYSTEM'
		| 'FOLDER'
		| 'FOLDER_WORKFLOW'
		| 'TABLE'
		| 'MAPPING_DEFAULT'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
		| 'CONSTANT_DOMAIN'
		| 'TABLE_COLUMN'
		| 'DIAGRAM'
	updatedAt?: string
	updatedBy?: string
	version?: number
}
export type EnvironmentDeploymentDto = {
	code?: string
	connection?: string
	description?: string
	directDeployment?: boolean
	frontendId?: number
	gitLoginName?: string
	gitPasswordToken?: string
	gitUrl?: string
	id?: number
	key?: number
	lastDeploymentAuthorCompositeName?: string
	lastDeploymentAuthorId?: number
	lastDeploymentStructureHistoryId?: number
	lastDeploymentStructureHistoryVersion?: number
	lastRelease?: ReleaseDto
	name?: string
	objectType?: ObjectSettingsDto
	production?: boolean
	rememberPassword?: boolean
}
export type InitInfoDto = {
	allowed?: boolean
	goJSLicenseKey?: string
}
export type NamingDto = {
	companyName?: string
	createdAt?: string
	createdBy?: string
	defaultTemplate?: string
	deleted?: boolean
	template?: string
	type?:
		| 'TABLE_CODE'
		| 'MAPPING_CODE'
		| 'INTERFACE_CODE'
		| 'VIEW_CODE'
		| 'API_NODE_CODE'
		| 'API_COLLECTION_CODE'
		| 'FOLDER_CODE'
		| 'QUEUE_CODE'
		| 'SYSTEM_CODE'
		| 'WORKFLOW_CODE'
		| 'HISTORICAL_TABLE_NAME'
		| 'REFERENCE_TABLE_NAME'
		| 'HISTORICAL_TABLE_CODE'
		| 'REFERENCE_TABLE_CODE'
		| 'HISTORICAL_DEFAULT_MAPPING_NAME'
		| 'REFERENCE_DEFAULT_MAPPING_NAME'
		| 'INTERFACE_DEFAULT_MAPPING_NAME'
		| 'HISTORICAL_DEFAULT_MAPPING_CODE'
		| 'REFERENCE_DEFAULT_MAPPING_CODE'
		| 'INTERFACE_DEFAULT_MAPPING_CODE'
		| 'DEFAULT_MAPPING_NAME'
		| 'DEFAULT_MAPPING_CODE'
		| 'DDL_SCRIPT_NAME'
		| 'EXPORT_NAME'
		| 'PRIMARY_KEY_NAME'
		| 'SEQUENCE_NAME'
	updatedAt?: string
	updatedBy?: string
	version?: number
}
export type AdjustResultDto = {
	result?: string
}
export type TableColumnDomain = {
	code?: string
	id?: number
	name?: string
}
export type TableColumnStereotype = {
	code?: string
	id?: number
	name?: string
}
export type CustomAttribute = {
	id?: string
	name?: string
	source?: string
	value?: object
	version?: string
}
export type TableColumn = {
	code?: string
	comment?: string
	customAttributes?: CustomAttribute[]
	dataType?: string
	defaultValue?: string
	description?: string
	domain?: TableColumnDomain
	foreignKey?: boolean
	g_hasTemplate?: boolean
	g_isTextValue?: boolean
	g_template?: string
	/** true if the column is created automatically for history table. */
	historyColumn?: boolean
	/** Is unique across table columns. */
	id?: number
	mandatory?: boolean
	name?: string
	notNullFlag?: boolean
	primaryKey?: boolean
	stereotype?: TableColumnStereotype
	/** true if the column is created automatically based on table stereotype. */
	stereotypeColumn?: boolean
	/** id of the technical column from which it was created */
	technicalColumnId?: number
	uuid?: string
	virtualColumnScript?: string
}
export type TableIndexColumn = {
	code?: string
	sortType?: 'ASC' | 'DESC'
	uuid?: string
}
export type TableIndex = {
	code?: string
	columns?: TableIndexColumn[]
	comment?: string
	description?: string
	/** Is unique across table indexes. */
	id?: number
	isUnique?: boolean
	name?: string
	physicalOptions?: string
}
export type TableConstraintColumn = {
	/** The reference to the table column. */
	code?: string
	/** The reference to the referenced table column. */
	foreignColumnCode?: string
	foreignColumnUuid?: string
	name?: string
	uuid?: string
}
export type TableConstraint = {
	code?: string
	columns?: TableConstraintColumn[]
	comment?: string
	constraintType?: 'PRIMARY_KEY' | 'FOREIGN_KEY' | 'CUSTOM' | 'UNIQUE' | 'CHECK'
	description?: string
	enabled?: boolean
	expression?: string
	foreignKeyTableId?: number
	g_foreignKeyTableCode?: string
	g_foreignKeyTableOwner?: string
	/** Is unique across table indexes. */
	id?: number
	name?: string
	physicalOptions?: string
	script?: string
}
export type ObjectSettings = {
	code?: string
	id?: number
	name?: string
}
export type Parent = {
	code?: string
	name?: string
}
export type SystemUser = {
	code?: string
	comment?: string
	description?: string
	id?: number
	name?: string
}
export type TablePermission = {
	/** Is unique across table users. */
	id?: number
	order?: number
	permissions?: (
		| 'ALTER'
		| 'SELECT'
		| 'DELETE'
		| 'UPDATE'
		| 'INSERT'
		| 'REFERENCE'
		| 'SEQUENCE'
	)[]
	user?: SystemUser
}
export type ReferenceTable = {
	businessDomainId?: number
	businessDomainName?: string
	code?: string
	columns?: TableColumn[]
	columnsLastId?: number
	comment?: string
	constraints?: TableConstraint[]
	constraintsLastId?: number
	customAttributes?: CustomAttribute[]
	description?: string
	entityType?:
		| 'AGGREGATE'
		| 'AUXILIARY'
		| 'COMPOSITION'
		| 'FUNDAMENTAL'
		| 'METADATA'
		| 'RELATION'
		| 'SNAPSHOT'
		| 'SPECIALISATION'
		| 'TRANSACTION'
	g_isReferenceTable?: boolean
	g_partitioningColumnCode?: string
	g_templateStereotype?: string
	g_templateVersion?: number
	hasHistoryTable?: boolean
	hasReferenceTable?: boolean
	indexes?: TableIndex[]
	indexesLastId?: number
	name?: string
	nonSyncFields?: (
		| 'UUID'
		| 'NAME'
		| 'CODE'
		| 'COMMENT'
		| 'DESCRIPTION'
		| 'STEREOTYPE_ID'
		| 'STEREOTYPE_CODE'
		| 'SUB_STEREOTYPE_ID'
		| 'HAS_HISTORY_TABLE'
		| 'HAS_REFERENCE_TABLE'
		| 'BUSINESS_DOMAIN_ID'
		| 'BUSINESS_DOMAIN_NAME'
		| 'ENTITY_TYPE'
		| 'STATUS'
		| 'RESPONSIBLE_ANALYST_ID'
		| 'RESPONSIBLE_ANALYST_NAME'
		| 'RESPONSIBLE_PERSON_ID'
		| 'RESPONSIBLE_PERSON_NAME'
		| 'RETENTION_STRATEGY'
		| 'RETENTION_STRATEGY_FULL_HISTORY_VALUE'
		| 'RETENTION_STRATEGY_FULL_HISTORY_UNIT'
		| 'RETENTION_STRATEGY_EOM_VALUE'
		| 'RETENTION_STRATEGY_EOM_UNIT'
		| 'COLUMNS'
		| 'COLUMNS_LAST_ID'
		| 'INDEXES'
		| 'INDEXES_LAST_ID'
		| 'CONSTRAINTS'
		| 'CONSTRAINTS_LAST_ID'
		| 'HISTORY_TABLE'
		| 'REFERENCE_TABLE'
		| 'SCRIPT'
		| 'PRE_TABLE_SCRIPT'
		| 'POST_TABLE_SCRIPT'
		| 'PARTITIONING_STRATEGY'
		| 'PARTITIONING_COLUMN_ID'
		| 'PARTITIONING_COLUMN_CODE'
		| 'SEQUENCE_CODE'
		| 'SEQUENCE_COLUMN_CODE'
		| 'OWNER_ID'
		| 'OWNER_NAME'
		| 'OBJECT_SETTINGS'
		| 'CUSTOM_ATTRIBUTES'
	)[]
	objectPermissionLastId?: number
	objectPermissions?: TablePermission[]
	responsiblePersonId?: number
	stereotypeId?: number
	ownerId?: number
	responsiblePersonName?: string
	partitioningColumnCode?: string
	/** If any of the key partitioning strategies is chosen then a id of key column must be provided. */
	partitioningColumnId?: number
	partitioningStrategy?:
		| 'NO_PARTITION'
		| 'KEY'
		| 'SOURCE_SYSTEM'
		| 'SNAPSHOT_DATA'
		| 'SOURCE_AND_SNAPSHOT'
		| 'KEY_AND_SNAPSHOT'
		| 'SOURCE_AND_SNAPSHOT_AND_DATE_EFFECTIVE'
		| 'KEY_AND_SNAPSHOT_AND_DATE_EFFECTIVE'
	postTableScript?: string
	preTableScript?: string
	stereotypeCode?: string
	subStereotypeId?: number
	responsibleAnalystId?: number
	/** Column used with the Sequence */
	sequenceColumnCode?: string
	retentionStrategyFullHistoryUnit?: 'DAY' | 'MONTH' | 'YEAR'
	retentionStrategyFullHistoryValue?: number
	uuid?: string
	retentionStrategy?:
		| 'INFINITE'
		| 'DELETE_AFTER'
		| 'MOVE_AFTER'
		| 'KEEP_FULL_HISTORY'
		| 'KEEP_FULL_HISTORY_AND_EOM'
	ownerName?: string
	script?: string
	/** Sequence related to the table (only 1 is allowed) */
	sequenceCode?: string
	status?:
		| 'LOGICAL_IN_PROGRESS'
		| 'LOGICAL_COMPLETED'
		| 'PHYSICAL_IN_PROGRESS'
		| 'PHYSICAL_COMPLETED'
		| 'FROZEN'
	objectSettings?: ObjectSettings[]
	originalTable?: Parent
	retentionStrategyEomUnit?: 'DAY' | 'MONTH' | 'YEAR'
	retentionStrategyEomValue?: number
	responsibleAnalystName?: string
}
export type HistoryTable = {
	businessDomainId?: number
	businessDomainName?: string
	code?: string
	columns?: TableColumn[]
	columnsLastId?: number
	comment?: string
	constraints?: TableConstraint[]
	constraintsLastId?: number
	customAttributes?: CustomAttribute[]
	description?: string
	entityType?:
		| 'AGGREGATE'
		| 'AUXILIARY'
		| 'COMPOSITION'
		| 'FUNDAMENTAL'
		| 'METADATA'
		| 'RELATION'
		| 'SNAPSHOT'
		| 'SPECIALISATION'
		| 'TRANSACTION'
	g_isHistoryTable?: boolean
	g_partitioningColumnCode?: string
	g_templateStereotype?: string
	g_templateVersion?: number
	hasHistoryTable?: boolean
	hasReferenceTable?: boolean
	indexes?: TableIndex[]
	indexesLastId?: number
	name?: string
	nonSyncFields?: (
		| 'UUID'
		| 'NAME'
		| 'CODE'
		| 'COMMENT'
		| 'DESCRIPTION'
		| 'STEREOTYPE_ID'
		| 'STEREOTYPE_CODE'
		| 'SUB_STEREOTYPE_ID'
		| 'HAS_HISTORY_TABLE'
		| 'HAS_REFERENCE_TABLE'
		| 'BUSINESS_DOMAIN_ID'
		| 'BUSINESS_DOMAIN_NAME'
		| 'ENTITY_TYPE'
		| 'STATUS'
		| 'RESPONSIBLE_ANALYST_ID'
		| 'RESPONSIBLE_ANALYST_NAME'
		| 'RESPONSIBLE_PERSON_ID'
		| 'RESPONSIBLE_PERSON_NAME'
		| 'RETENTION_STRATEGY'
		| 'RETENTION_STRATEGY_FULL_HISTORY_VALUE'
		| 'RETENTION_STRATEGY_FULL_HISTORY_UNIT'
		| 'RETENTION_STRATEGY_EOM_VALUE'
		| 'RETENTION_STRATEGY_EOM_UNIT'
		| 'COLUMNS'
		| 'COLUMNS_LAST_ID'
		| 'INDEXES'
		| 'INDEXES_LAST_ID'
		| 'CONSTRAINTS'
		| 'CONSTRAINTS_LAST_ID'
		| 'HISTORY_TABLE'
		| 'REFERENCE_TABLE'
		| 'SCRIPT'
		| 'PRE_TABLE_SCRIPT'
		| 'POST_TABLE_SCRIPT'
		| 'PARTITIONING_STRATEGY'
		| 'PARTITIONING_COLUMN_ID'
		| 'PARTITIONING_COLUMN_CODE'
		| 'SEQUENCE_CODE'
		| 'SEQUENCE_COLUMN_CODE'
		| 'OWNER_ID'
		| 'OWNER_NAME'
		| 'OBJECT_SETTINGS'
		| 'CUSTOM_ATTRIBUTES'
	)[]
	objectPermissionLastId?: number
	objectPermissions?: TablePermission[]
	responsiblePersonId?: number
	stereotypeId?: number
	ownerId?: number
	ownerName?: string
	partitioningColumnCode?: string
	/** If any of the key partitioning strategies is chosen then a id of key column must be provided. */
	partitioningColumnId?: number
	responsibleAnalystId?: number
	postTableScript?: string
	preTableScript?: string
	stereotypeCode?: string
	subStereotypeId?: number
	/** Sequence related to the table (only 1 is allowed) */
	sequenceCode?: string
	/** Column used with the Sequence */
	sequenceColumnCode?: string
	retentionStrategyFullHistoryUnit?: 'DAY' | 'MONTH' | 'YEAR'
	retentionStrategyFullHistoryValue?: number
	uuid?: string
	retentionStrategy?:
		| 'INFINITE'
		| 'DELETE_AFTER'
		| 'MOVE_AFTER'
		| 'KEEP_FULL_HISTORY'
		| 'KEEP_FULL_HISTORY_AND_EOM'
	script?: string
	partitioningStrategy?:
		| 'NO_PARTITION'
		| 'KEY'
		| 'SOURCE_SYSTEM'
		| 'SNAPSHOT_DATA'
		| 'SOURCE_AND_SNAPSHOT'
		| 'KEY_AND_SNAPSHOT'
		| 'SOURCE_AND_SNAPSHOT_AND_DATE_EFFECTIVE'
		| 'KEY_AND_SNAPSHOT_AND_DATE_EFFECTIVE'
		| 'SOURCE_SYSTEM_AND_VALID_TO'
	status?:
		| 'LOGICAL_IN_PROGRESS'
		| 'LOGICAL_COMPLETED'
		| 'PHYSICAL_IN_PROGRESS'
		| 'PHYSICAL_COMPLETED'
		| 'FROZEN'
	responsiblePersonName?: string
	objectSettings?: ObjectSettings[]
	originalTable?: Parent
	retentionStrategyEomUnit?: 'DAY' | 'MONTH' | 'YEAR'
	retentionStrategyEomValue?: number
	responsibleAnalystName?: string
}
export type Table = {
	businessDomainId?: number
	businessDomainName?: string
	code?: string
	columns?: TableColumn[]
	columnsLastId?: number
	comment?: string
	constraints?: TableConstraint[]
	constraintsLastId?: number
	customAttributes?: CustomAttribute[]
	description?: string
	entityType?:
		| 'AGGREGATE'
		| 'AUXILIARY'
		| 'COMPOSITION'
		| 'FUNDAMENTAL'
		| 'METADATA'
		| 'RELATION'
		| 'SNAPSHOT'
		| 'SPECIALISATION'
		| 'TRANSACTION'
	g_partitioningColumnCode?: string
	g_templateStereotype?: string
	g_templateVersion?: number
	hasHistoryTable?: boolean
	hasReferenceTable?: boolean
	historyTable?: HistoryTable
	indexes?: TableIndex[]
	indexesLastId?: number
	name?: string
	objectPermissionLastId?: number
	objectPermissions?: TablePermission[]
	objectSettings?: ObjectSettings[]
	responsibleAnalystName?: string
	stereotypeId?: number
	partitioningColumnCode?: string
	/** If any of the key partitioning strategies is chosen then a id of key column must be provided. */
	partitioningColumnId?: number
	partitioningStrategy?:
		| 'NO_PARTITION'
		| 'KEY'
		| 'SOURCE_SYSTEM'
		| 'SNAPSHOT_DATA'
		| 'SOURCE_AND_SNAPSHOT'
		| 'KEY_AND_SNAPSHOT'
		| 'SOURCE_AND_SNAPSHOT_AND_DATE_EFFECTIVE'
		| 'KEY_AND_SNAPSHOT_AND_DATE_EFFECTIVE'
	postTableScript?: string
	preTableScript?: string
	referenceTable?: ReferenceTable
	script?: string
	responsiblePersonId?: number
	stereotypeCode?: string
	subStereotypeId?: number
	responsiblePersonName?: string
	retentionStrategy?:
		| 'INFINITE'
		| 'DELETE_AFTER'
		| 'MOVE_AFTER'
		| 'KEEP_FULL_HISTORY'
		| 'KEEP_FULL_HISTORY_AND_EOM'
	/** Sequence related to the table (only 1 is allowed) */
	sequenceCode?: string
	/** Column used with the Sequence */
	sequenceColumnCode?: string
	status?:
		| 'LOGICAL_IN_PROGRESS'
		| 'LOGICAL_COMPLETED'
		| 'PHYSICAL_IN_PROGRESS'
		| 'PHYSICAL_COMPLETED'
		| 'FROZEN'
	uuid?: string
	retentionStrategyFullHistoryValue?: number
	ownerId?: number
	ownerName?: string
	retentionStrategyEomUnit?: 'DAY' | 'MONTH' | 'YEAR'
	retentionStrategyEomValue?: number
	retentionStrategyFullHistoryUnit?: 'DAY' | 'MONTH' | 'YEAR'
	responsibleAnalystId?: number
}
export type StructureWorkingDataDto = {
	data?: string
}
export type ObjectSettingsCreateDto = {
	code?: string
	comment?: string
	description?: string
	id?: number
	name?: string
	structureType?:
		| 'ROOT'
		| 'SYSTEM'
		| 'FOLDER'
		| 'FOLDER_WORKFLOW'
		| 'TABLE'
		| 'MAPPING_DEFAULT'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
		| 'CONSTANT_DOMAIN'
		| 'TABLE_COLUMN'
		| 'DIAGRAM'
}
export type ObjectSettingsComboDto = {
	code?: string
	comment?: string
	createdAt?: string
	createdBy?: string
	defaultFlag?: boolean
	deleted?: boolean
	description?: string
	id?: number
	name?: string
	structureType?:
		| 'ROOT'
		| 'SYSTEM'
		| 'FOLDER'
		| 'FOLDER_WORKFLOW'
		| 'TABLE'
		| 'MAPPING_DEFAULT'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
		| 'CONSTANT_DOMAIN'
		| 'TABLE_COLUMN'
		| 'DIAGRAM'
	updatedAt?: string
	updatedBy?: string
	version?: number
}
export type ObjectSettingsUpdateDto = {
	code?: string
	comment?: string
	description?: string
	id?: number
	name?: string
	structureType?:
		| 'ROOT'
		| 'SYSTEM'
		| 'FOLDER'
		| 'FOLDER_WORKFLOW'
		| 'TABLE'
		| 'MAPPING_DEFAULT'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
		| 'CONSTANT_DOMAIN'
		| 'TABLE_COLUMN'
		| 'DIAGRAM'
}
export type Permission = {
	code?:
		| 'NO_ACCESS'
		| 'READ_ACCESS'
		| 'FULL_ACCESS'
		| 'MANAGE_USERS'
		| 'MANAGE_LOV'
		| 'CREATE_DELETE_SYSTEM'
		| 'FORCE_UNLOCK'
	description?: string
	name?: string
	permissionGroup?: 'NONE' | 'NODE_ACCESS' | 'SINGLE'
	type?: 'NODE' | 'GLOBAL'
}
export type RelationStructureDto = {
	code?: string
	fullPath?: string
	id?: number
	name?: string
	type?:
		| 'ROOT'
		| 'SYSTEM'
		| 'FOLDER'
		| 'FOLDER_WORKFLOW'
		| 'TABLE'
		| 'MAPPING_DEFAULT'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
		| 'CONSTANT_DOMAIN'
		| 'TABLE_COLUMN'
		| 'DIAGRAM'
}
export type RelationColumnDto = {
	code?: string
	uuid?: string
}
export type RelationDto = {
	children?: RelationDto[]
	code?: string
	description?: string
	hasChildren?: boolean
	id?: number
	leftSideColumn?: RelationColumnDto
	leftSideStructure?: RelationStructureDto
	name?: string
	parentRelationId?: number
	relationType?:
		| 'PK_FK_TABLE'
		| 'PK_FK_COLUMN'
		| 'MAPPING_WRITE'
		| 'LINK'
		| 'LINK_COLUMN'
		| 'CUSTOM_LINK'
	rightSideColumn?: RelationColumnDto
	rightSideStructure?: RelationStructureDto
	status?:
		| 'OK'
		| 'PK_SIDE_REMOVED'
		| 'FK_COL_NOT_SET'
		| 'PK_COL_REMOVED'
		| 'BROKEN'
}
export type StereotypeCategoryDto = {
	code?: string
}
export type StereotypeTypeDto = {
	code?: string
}
export type StereotypeDto = {
	category?: 'BEHAVIORAL' | 'DEFAULT' | 'USER_DEFINED'
	children?: StereotypeDto[]
	code?: string
	description?: string
	hasSubtypes?: boolean
	id?: number
	internal?: boolean
	name?: string
	type?:
		| 'FOLDER'
		| 'TABLE'
		| 'TABLE_INTERFACE'
		| 'TABLE_HISTORY'
		| 'COLUMN'
		| 'MAPPING'
		| 'MAPPING_TYPE'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'CONSTANT_DOMAIN'
		| 'VIEW'
		| 'WORKFLOW'
		| 'API_NODE'
		| 'API_NODE_ATTRIBUTE'
		| 'API_ROWSET'
		| 'QUEUE'
		| 'TABLE_COLUMN'
		| 'DIAGRAM'
}
export type StereotypeCreateDto = {
	category?: 'BEHAVIORAL' | 'DEFAULT' | 'USER_DEFINED'
	children?: StereotypeCreateDto[]
	code?: string
	description?: string
	hasSubtypes?: boolean
	name?: string
	type?:
		| 'FOLDER'
		| 'TABLE'
		| 'TABLE_INTERFACE'
		| 'TABLE_HISTORY'
		| 'COLUMN'
		| 'MAPPING'
		| 'MAPPING_TYPE'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'CONSTANT_DOMAIN'
		| 'VIEW'
		| 'WORKFLOW'
		| 'API_NODE'
		| 'API_NODE_ATTRIBUTE'
		| 'API_ROWSET'
		| 'QUEUE'
		| 'TABLE_COLUMN'
		| 'DIAGRAM'
}
export type StereotypeUpdateDto = {
	category?: 'BEHAVIORAL' | 'DEFAULT' | 'USER_DEFINED'
	children?: StereotypeUpdateDto[]
	code?: string
	description?: string
	hasSubtypes?: boolean
	id?: number
	internal?: boolean
	name?: string
	type?:
		| 'FOLDER'
		| 'TABLE'
		| 'TABLE_INTERFACE'
		| 'TABLE_HISTORY'
		| 'COLUMN'
		| 'MAPPING'
		| 'MAPPING_TYPE'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'CONSTANT_DOMAIN'
		| 'VIEW'
		| 'WORKFLOW'
		| 'API_NODE'
		| 'API_NODE_ATTRIBUTE'
		| 'API_ROWSET'
		| 'QUEUE'
		| 'TABLE_COLUMN'
		| 'DIAGRAM'
}
export type StructureDto = {
	authorId?: number
	authorName?: string
	canEdit?: boolean
	code?: string
	companyId?: number
	description?: string
	fullPath?: string
	hasChildren?: boolean
	id?: number
	lockTime?: string
	lockUserId?: number
	lockUserName?: string
	name?: string
	orderPriority?: number
	parentStructureId?: number
	status?: string
	type?:
		| 'ROOT'
		| 'SYSTEM'
		| 'FOLDER'
		| 'FOLDER_WORKFLOW'
		| 'TABLE'
		| 'MAPPING_DEFAULT'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
		| 'CONSTANT_DOMAIN'
		| 'TABLE_COLUMN'
		| 'DIAGRAM'
	uuid?: string
}
export type NewStructureDto = {
	data?: string
	type?:
		| 'ROOT'
		| 'SYSTEM'
		| 'FOLDER'
		| 'FOLDER_WORKFLOW'
		| 'TABLE'
		| 'MAPPING_DEFAULT'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
		| 'CONSTANT_DOMAIN'
		| 'TABLE_COLUMN'
		| 'DIAGRAM'
}
export type Number = object
export type FullTextSearchDto = {
	code?: string
	created?: string
	id?: number
	name?: string
	path?: string
	rank?: number
	type?:
		| 'ROOT'
		| 'SYSTEM'
		| 'FOLDER'
		| 'FOLDER_WORKFLOW'
		| 'TABLE'
		| 'MAPPING_DEFAULT'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
		| 'CONSTANT_DOMAIN'
		| 'TABLE_COLUMN'
		| 'DIAGRAM'
}
export type StructureExportDto = {
	actualData?: string
	children?: StructureExportDto[]
	code?: string
	name?: string
	type?:
		| 'ROOT'
		| 'SYSTEM'
		| 'FOLDER'
		| 'FOLDER_WORKFLOW'
		| 'TABLE'
		| 'MAPPING_DEFAULT'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
		| 'CONSTANT_DOMAIN'
		| 'TABLE_COLUMN'
		| 'DIAGRAM'
}
export type CsvImportExistingDomainDto = {
	code?: string
	customFlag?: boolean
	dataType?: string
	defaultValue?: string
	domainName?: string
	id?: number
	notNullFlag?: boolean
}
export type CsvImportDomainDto = {
	dataType?: string
	defaultValue?: string
	domainName?: string
	mandatoryFlag?: boolean
	matchedDomain?: CsvImportExistingDomainDto
	similarDomain?: CsvImportExistingDomainDto[]
}
export type CsvImportColumnDto = {
	code?: string
	comment?: string
	dataType?: string
	defaultValue?: string
	description?: string
	domainCode?: string
	domainId?: number
	indexName?: string
	name?: string
	notNullFlag?: boolean
	primaryKeyName?: string
	stereoTypeId?: number
	stereotypeCode?: string
}
export type CsvImportTableDto = {
	code?: string
	columnDtoList?: CsvImportColumnDto[]
	comment?: string
	description?: string
	name?: string
	postScript?: string
	preScript?: string
	script?: string
	stereotypeCode?: string
	stereotypeId?: number
}
export type TechnicalColumnStereotypeDto = {
	code?: string
	columnStereotypeCode?: string
	columnStereotypeId?: number
	columnStereotypeName?: string
	comment?: string
	dataType?: string
	defaultValue?: string
	description?: string
	domainCode?: string
	domainId?: number
	domainName?: string
	id?: number
	name?: string
	notNull?: boolean
	ordering?: number
	virtualColumnScript?: string
}
export type ImportReturnMessage = {
	messageType?:
		| 'INCOMPATIBLE_TARGET'
		| 'DOMAIN_NOT_FOUND'
		| 'STEREOTYPE_NOT_FOUND'
		| 'TABLE_STEREOTYPE_NOT_FOUND'
		| 'TABLE_ALREADY_EXISTS_IN_FOLDER'
		| 'TABLE_ALREADY_EXISTS_IN_SYSTEM'
		| 'INCONSISTENT_TABLES'
		| 'INCONSISTENT_DOMAINS'
		| 'COLUMN_WITH_SAME_NAME_ALREADY_EXISTS'
		| 'COLUMN_WITH_SAME_CODE_ALREADY_EXISTS'
		| 'DUPLICATE_COLUMN_NAME'
		| 'DUPLICATE_COLUMN_CODE'
	object?: string
}
export type CsvImportReturnDto = {
	domainDtoList?: CsvImportDomainDto[]
	errors?: ImportReturnMessage[]
	tableDtos?: CsvImportTableDto[]
	technicalColumnMap?: {
		[key: string]: TechnicalColumnStereotypeDto[]
	}
	warnings?: ImportReturnMessage[]
}
export type ImportColumn = {
	code?: string
	comment?: string
	dataType?: string
	defaultValue?: string
	description?: string
	domainName?: string
	indexName?: string
	mandatoryFlag?: boolean
	name?: string
	primaryKeyName?: string
	stereotypeCode?: string
}
export type ImportTable = {
	code?: string
	comment?: string
	description?: string
	name?: string
	postScript?: string
	preScript?: string
	script?: string
	stereotypeCode?: string
}
export type CsvImportRowDto = {
	column?: ImportColumn
	table?: ImportTable
}
export type MddError = {
	errorCode?: string
	errorDescription?: string
	errorMessage?: string
}
export type StructureObjectPair = {
	objectType?: ObjectSettingsDto
	structureType?:
		| 'ROOT'
		| 'SYSTEM'
		| 'FOLDER'
		| 'FOLDER_WORKFLOW'
		| 'TABLE'
		| 'MAPPING_DEFAULT'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
		| 'CONSTANT_DOMAIN'
		| 'TABLE_COLUMN'
		| 'DIAGRAM'
}
export type MassExportConfigDto = {
	environmentId?: number
	massExportSorting?:
		| 'DEFAULT'
		| 'SORT_BY_NAME_ASC'
		| 'SORT_BY_TYPE_AND_NAME_ASC'
	massExportStrategy?:
		| 'ZIP_PER_STRUCTURE'
		| 'ZIP_OWNER_FOLDER_PER_OBJECT_TYPE'
		| 'ZIP_PER_OWNER_AND_OBJECT_TYPE'
		| 'SINGLE_FILE'
	structureIds?: number[]
	structureObjectPairs?: StructureObjectPair[]
	structureStatus?: (
		| 'LOGICAL_IN_PROGRESS'
		| 'LOGICAL_COMPLETED'
		| 'PHYSICAL_IN_PROGRESS'
		| 'PHYSICAL_COMPLETED'
		| 'FROZEN'
	)[]
}
export type StructureSearchedDto = {
	children?: StructureSearchedDto[]
	code?: string
	hasChildren?: boolean
	id?: number
	lockTime?: string
	lockUserId?: number
	lockUserName?: string
	name?: string
	orderPriority?: number
	parentStructureId?: number
	searchedItem?: boolean
	type?:
		| 'ROOT'
		| 'SYSTEM'
		| 'FOLDER'
		| 'FOLDER_WORKFLOW'
		| 'TABLE'
		| 'MAPPING_DEFAULT'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
		| 'CONSTANT_DOMAIN'
		| 'TABLE_COLUMN'
		| 'DIAGRAM'
	uuid?: string
}
export type StructureObjectDto = {
	code?: string
	fullPath?: string
	name?: string
	parentStructureId?: number
	structureId?: number
	type?:
		| 'ROOT'
		| 'SYSTEM'
		| 'FOLDER'
		| 'FOLDER_WORKFLOW'
		| 'TABLE'
		| 'MAPPING_DEFAULT'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
		| 'CONSTANT_DOMAIN'
		| 'TABLE_COLUMN'
		| 'DIAGRAM'
}
export type ReleaseEnvironmentDto = {
	code?: string
	id?: number
	name?: string
	releaseDate?: string
	released?: boolean
	releasedTime?: string
}
export type DeploymentInfo = {
	deployedHistoryId?: number
	deployedVersion?: number
	id?: string
	objectTypeCode?: string
	pushedHistoryId?: number
	pushedVersion?: number
}
export type EnvInfo = {
	deploymentInfo?: DeploymentInfo[]
	envCode?: string
	frontendId?: number
}
export type StructureReleaseInfoDto = {
	code?: string
	environmentInfo?: EnvInfo[]
	fullPath?: string
	name?: string
	parentStructureId?: number
	structureId?: number
	type?:
		| 'ROOT'
		| 'SYSTEM'
		| 'FOLDER'
		| 'FOLDER_WORKFLOW'
		| 'TABLE'
		| 'MAPPING_DEFAULT'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
		| 'CONSTANT_DOMAIN'
		| 'TABLE_COLUMN'
		| 'DIAGRAM'
}
export type Sort = {
	empty?: boolean
	sorted?: boolean
	unsorted?: boolean
}
export type Pageable = {
	offset?: number
	pageNumber?: number
	pageSize?: number
	paged?: boolean
	sort?: Sort
	unpaged?: boolean
}
export type PageStructureReleaseInfoDto = {
	content?: StructureReleaseInfoDto[]
	empty?: boolean
	first?: boolean
	last?: boolean
	number?: number
	numberOfElements?: number
	pageable?: Pageable
	size?: number
	sort?: Sort
	totalElements?: number
	totalPages?: number
}
export type StructureUserPermissionDto = {
	inheritedPermissionCode?: string
	inheritedPermissionName?: string
	structurePermissionCode?: string
	structurePermissionName?: string
	userId?: number
	userLoginName?: string
}
export type StructureDetailDto = {
	authorId?: number
	authorName?: string
	canEdit?: boolean
	code?: string
	companyId?: number
	/** Required for SYSTEM/TABLE/MAPPING/WORKFLOW types. */
	data?: string
	hasChildren?: boolean
	id?: number
	/** Required for SYSTEM/TABLE/MAPPING/WORKFLOW types. */
	lockTime?: string
	lockUserId?: number
	/** Required for SYSTEM/TABLE/MAPPING/WORKFLOW types. */
	lockUserName?: string
	name?: string
	orderPriority?: number
	parentStructureId?: number
	status?: string
	/** Required for TABLE/MAPPING types. */
	stereotypeId?: number
	/** Required for TABLE/MAPPING types. */
	stereotypeName?: string
	/** Required for MAPPING type and optional for Table. */
	subStereotypeId?: number
	/** Required for MAPPING type and optional for Table. */
	subStereotypeName?: string
	/** Required for SYSTEM type. */
	systemTechnologyId?: number
	/** Required for SYSTEM type. */
	systemTechnologyName?: string
	type?:
		| 'ROOT'
		| 'SYSTEM'
		| 'FOLDER'
		| 'FOLDER_WORKFLOW'
		| 'TABLE'
		| 'MAPPING_DEFAULT'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
		| 'CONSTANT_DOMAIN'
		| 'TABLE_COLUMN'
		| 'DIAGRAM'
	usersPermissions?: StructureUserPermissionDto[]
	/** Required for SYSTEM type. */
	workflowTechnologyId?: number
	uuid?: string
	/** Required for SYSTEM type. */
	workflowTechnologyName?: string
	/** Required for SYSTEM/TABLE/MAPPING/WORKFLOW types. */
	workingData?: string
}
export type ApiNodeColumnDto = {
	columnCode?: string
	columnName?: string
	columnUuid?: string
	sourceStructureCode?: string
	sourceStructureId?: number
	sourceType?: 'API_NODE' | 'ROW_SET' | 'TABLE'
}
export type StructureDataDto = {
	/** Required for SYSTEM/TABLE/MAPPING/WORKFLOW types. */
	data?: string
	/** Required for SYSTEM/TABLE/MAPPING/WORKFLOW types. */
	workingData?: string
}
export type TableDiagramNodeDto = {
	code?: string
	id?: number
	name?: string
	relationship?: 'DIRECT' | 'REFERENCED'
}
export type TableDiagramLinkDto = {
	constraintCode?: string
	constraintName?: string
	foreignKeyColumnCode?: string
	foreignKeyColumnCodes?: TableConstraintColumn[]
	foreignKeyTableId?: number
	primaryKeyColumnCodes?: TableConstraintColumn[]
	primaryKeyTableId?: number
}
export type TableDiagramDto = {
	links?: TableDiagramLinkDto[]
	nodes?: TableDiagramNodeDto[]
	tableDirectIds?: number[]
	tableReferencedIds?: number[]
}
export type ReferenceObjectInfo = {
	id?: number
}
export type ReferenceObjectInfoRead = {
	code?: string
	id?: number
	name?: string
}
export type UserInfoDto = {
	email?: string
	fullName?: string
	id?: number
	loginName?: string
}
export type TemplateListDto = {
	author?: UserInfoDto
	comment?: string
	description?: string
	fileExtension?: string
	formatType?: 'PYTHON' | 'SQL' | 'PLAIN_TEXT'
	id?: number
	lockTime?: string
	lockedBy?: UserInfoDto
	objectSettings?: ReferenceObjectInfo
	stereotype?: ReferenceObjectInfo
	structureType?:
		| 'ROOT'
		| 'SYSTEM'
		| 'FOLDER'
		| 'FOLDER_WORKFLOW'
		| 'TABLE'
		| 'MAPPING_DEFAULT'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
		| 'CONSTANT_DOMAIN'
		| 'TABLE_COLUMN'
		| 'DIAGRAM'
	technology?: ReferenceObjectInfo
}
export type TemplateListDtoRead = {
	author?: UserInfoDto
	comment?: string
	created?: string
	description?: string
	fileExtension?: string
	formatType?: 'PYTHON' | 'SQL' | 'PLAIN_TEXT'
	id?: number
	lockTime?: string
	lockedBy?: UserInfoDto
	objectSettings?: ReferenceObjectInfoRead
	stereotype?: ReferenceObjectInfoRead
	structureType?:
		| 'ROOT'
		| 'SYSTEM'
		| 'FOLDER'
		| 'FOLDER_WORKFLOW'
		| 'TABLE'
		| 'MAPPING_DEFAULT'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
		| 'CONSTANT_DOMAIN'
		| 'TABLE_COLUMN'
		| 'DIAGRAM'
	technology?: ReferenceObjectInfoRead
	version?: number
}
export type GeneratedSqlDto = {
	errors?: string[]
	fileName?: string
	formatType?: 'PYTHON' | 'SQL' | 'PLAIN_TEXT'
	objectSettingsCode?: string
	sql?: string
	sqlType?:
		| 'TABLE_HISTORY'
		| 'TABLE_REFERENCE'
		| 'TABLE'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
	structureHistoryId?: number
	structurePath?: string
	template?: TemplateListDto
	templateDataMap?: {
		[key: string]: object
	}
}
export type GeneratedSqlDtoRead = {
	errors?: string[]
	fileName?: string
	formatType?: 'PYTHON' | 'SQL' | 'PLAIN_TEXT'
	objectSettingsCode?: string
	sql?: string
	sqlType?:
		| 'TABLE_HISTORY'
		| 'TABLE_REFERENCE'
		| 'TABLE'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
	structureHistoryId?: number
	structurePath?: string
	template?: TemplateListDtoRead
	templateDataMap?: {
		[key: string]: object
	}
}
export type StructureExport = {
	content?: StructureExportDto
	exportFileName?: string
}
export type HistoryWithDeploymentDto = {
	authorId?: number
	authorName?: string
	created?: string
	deployments?: DeploymentDto[]
	description?: string
	id?: number
	structureId?: number
	version?: number
}
export type HistoryDto = {
	authorId?: number
	authorName?: string
	created?: string
	description?: string
	id?: number
	structureId?: number
	version?: number
}
export type NewHistoryDto = {
	description?: string
}
export type HistoryDetailDto = {
	authorId?: number
	authorName?: string
	code?: string
	created?: string
	data?: string
	deploymentDto?: DeploymentDto
	description?: string
	id?: number
	name?: string
	structureId?: number
	version?: number
}
export type JsonPatch = object
export type MassUpdateDto = {
	commitMessage?: string
	patch?: JsonPatch
	stereotypeType?:
		| 'FOLDER'
		| 'TABLE'
		| 'TABLE_INTERFACE'
		| 'TABLE_HISTORY'
		| 'COLUMN'
		| 'MAPPING'
		| 'MAPPING_TYPE'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'CONSTANT_DOMAIN'
		| 'VIEW'
		| 'WORKFLOW'
		| 'API_NODE'
		| 'API_NODE_ATTRIBUTE'
		| 'API_ROWSET'
		| 'QUEUE'
		| 'TABLE_COLUMN'
		| 'DIAGRAM'
	structureIds?: number[]
}
export type ReorderStructureDto = {
	enterBeforeStructureId?: number
	reorderStrategy?: 'ALPHABETICAL' | 'CUSTOM' | 'TYPE'
	targetParentStructureId?: number
}
export type NewStructureUserPermissionBatchDto = {
	permissionCodes?: (
		| 'NO_ACCESS'
		| 'READ_ACCESS'
		| 'FULL_ACCESS'
		| 'MANAGE_USERS'
		| 'MANAGE_LOV'
		| 'CREATE_DELETE_SYSTEM'
		| 'FORCE_UNLOCK'
	)[]
	userId?: number
}
export type NewStructureUserPermissionDto = {
	permissionCode?:
		| 'NO_ACCESS'
		| 'READ_ACCESS'
		| 'FULL_ACCESS'
		| 'MANAGE_USERS'
		| 'MANAGE_LOV'
		| 'CREATE_DELETE_SYSTEM'
		| 'FORCE_UNLOCK'
}
export type PrePushValidationDto = {
	allowedIgnore?: boolean
	editableByActualUser?: boolean
	message?: string
	path?: string
	structureId?: number
	structureType?:
		| 'ROOT'
		| 'SYSTEM'
		| 'FOLDER'
		| 'FOLDER_WORKFLOW'
		| 'TABLE'
		| 'MAPPING_DEFAULT'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
		| 'CONSTANT_DOMAIN'
		| 'TABLE_COLUMN'
		| 'DIAGRAM'
	type?:
		| 'BROKEN_LINK'
		| 'BROKEN_FK'
		| 'MISSING_REQ_FIELD'
		| 'MAPPING_COLUMN_CHANGED'
		| 'DELETED_COLUMN'
		| 'DELETED_IDX'
		| 'DELETED_CONSTRAINT'
		| 'SOME_EXPECTED_VALUE_MISSING'
		| 'SOME_VALUE_REMOVED'
}
export type RelationCreateDto = {
	code?: string
	description?: string
	name?: string
	rightSideStructure?: RelationStructureDto
}
export type StructureQuickFilter = {
	locked?: boolean
	lockedByMe?: boolean
	maxLevel?: number
	type?: (
		| 'ROOT'
		| 'SYSTEM'
		| 'FOLDER'
		| 'FOLDER_WORKFLOW'
		| 'TABLE'
		| 'MAPPING_DEFAULT'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
		| 'CONSTANT_DOMAIN'
		| 'TABLE_COLUMN'
		| 'DIAGRAM'
	)[]
}
export type TechnologyDto = {
	code?: string
	description?: string
	id?: number
	name?: string
}
export type AutocompleteResultDto = {
	code?: string
	name?: string
}
export type AsyncTaskExecutionDto = {
	createdAt?: string
	createdBy?: string
	errorMessage?: string
	id?: number
	itemDone?: number
	itemTotal?: number
	progress?: number
	state?: 'NEW' | 'RUNNING' | 'ERROR' | 'FINISHED' | 'CANCELLED'
	type?: 'MASS_EXPORT' | 'UPDATE_TECHNICAL_COLUMNS'
	updatedAt?: string
}
export type PageWrapperAsyncTaskExecutionDto = {
	content?: AsyncTaskExecutionDto[]
	page?: number
	size?: number
	totalElements?: number
	totalPages?: number
}
export type TechnicalColumnDto = {
	code?: string
	comment?: string
	description?: string
	domainId?: number
	name?: string
	notNull?: boolean
	ordering?: number
	stereotypeColumnId?: number
	stereotypeIds?: number[]
	virtualColumnScript?: string
}
export type TechnicalColumnDtoRead = {
	code?: string
	comment?: string
	createdAt?: string
	createdBy?: string
	deleted?: boolean
	description?: string
	domainId?: number
	id?: number
	name?: string
	notNull?: boolean
	ordering?: number
	stereotypeColumnId?: number
	stereotypeIds?: number[]
	updatedAt?: string
	updatedBy?: string
	version?: number
	virtualColumnScript?: string
}
export type TemplateDto = {
	author?: UserInfoDto
	comment?: string
	data?: string
	description?: string
	fileExtension?: string
	formatType?: 'PYTHON' | 'SQL' | 'PLAIN_TEXT'
	lockedBy?: UserInfoDto
	objectSettings?: ReferenceObjectInfo
	stereotype?: ReferenceObjectInfo
	structureType?:
		| 'ROOT'
		| 'SYSTEM'
		| 'FOLDER'
		| 'FOLDER_WORKFLOW'
		| 'TABLE'
		| 'MAPPING_DEFAULT'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
		| 'CONSTANT_DOMAIN'
		| 'TABLE_COLUMN'
		| 'DIAGRAM'
	technology?: ReferenceObjectInfo
}
export type TemplateDtoRead = {
	author?: UserInfoDto
	comment?: string
	created?: string
	data?: string
	description?: string
	fileExtension?: string
	formatType?: 'PYTHON' | 'SQL' | 'PLAIN_TEXT'
	id?: number
	lockTime?: string
	lockedBy?: UserInfoDto
	objectSettings?: ReferenceObjectInfoRead
	stereotype?: ReferenceObjectInfoRead
	structureType?:
		| 'ROOT'
		| 'SYSTEM'
		| 'FOLDER'
		| 'FOLDER_WORKFLOW'
		| 'TABLE'
		| 'MAPPING_DEFAULT'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
		| 'CONSTANT_DOMAIN'
		| 'TABLE_COLUMN'
		| 'DIAGRAM'
	technology?: ReferenceObjectInfoRead
	version?: number
}
export type NewUserDto = {
	department?: string
	description?: string
	email?: string
	loginName?: string
	name?: string
	password?: string
	surname?: string
}
export type ApiUserDto = {
	clientId?: string
	description?: string
}
export type ApiUserDtoRead = {
	clientId?: string
	clientSecret?: string
	description?: string
}
export type UserComboDto = {
	id?: number
	value?: string
}
export type SessionContextDto = {
	context?: string
	user?: UserDto
}
export type UserMessageDto = {
	message?: string
}
export type ResetPasswordDto = {
	username?: string
}
export type EditUserInfoDto = {
	department?: string
	description?: string
	email?: string
	loginName?: string
	name?: string
	role?: 'ADMIN' | 'LEADER'
	surname?: string
}
export type UserRolesDto = {
	roles?: (
		| 'SUPER_ADMIN'
		| 'MANAGE_USERS'
		| 'MANAGE_LOV'
		| 'CREATE_DELETE_SYSTEM'
		| 'FORCE_UNLOCK'
		| 'EXT_SYSTEM'
		| 'EXT_SYSTEM_WRITE'
	)[]
}
export type UtilsNameRequestDto = {
	name?: string
	systemFolderId?: number
}
export type CheckResultDto = {
	errors?: string[]
	result?: boolean
}
export type ValidationMessageDto = {
	description?: string
	elementName?: string
	elementType?:
		| 'TABLE'
		| 'MAPPING'
		| 'COLUMN'
		| 'VIEW'
		| 'FOLDER'
		| 'SYSTEM_FOLDER'
		| 'RELATION'
		| 'OVERVIEW'
		| 'CONSTRAINT'
		| 'STEREOTYPE'
	id?: number
	message?: string
	nodeName?: string
	nodeType?:
		| 'SYSTEM_NODE'
		| 'FOLDER'
		| 'WORKFLOW'
		| 'TABLE'
		| 'MAPPING'
		| 'VIEW'
		| 'API'
		| 'QUEUE'
	path?: string
	ruleName?: string
	severity?: 'ERROR' | 'WARN' | 'INFO'
	uuid?: string
}
export const {
	useGetAcronymsQuery,
	useCreateAcronymMutation,
	useGetAcronymQuery,
	useUpdateAcronymMutation,
	useDeleteAcronymMutation,
	useGetAuditLogsQuery,
	useGetAllBusinessDomainsQuery,
	useCreateBusinessDomainMutation,
	useGetAllBusinessDomainsForComboQuery,
	useGetBusinessDomainQuery,
	useUpdateBusinessDomainMutation,
	useDeleteBusinessDomainMutation,
	useGetCompanyQuery,
	useGetAllCustomAttributeStructureTypeDtoQuery,
	useGetAllCustomAttributesQuery,
	useCreateCustomAttributeMutation,
	useGetAllCustomAttributesForComboQuery,
	useGetCustomAttributeQuery,
	useUpdateCustomAttributeMutation,
	useDeleteCustomAttributeMutation,
	useGetAllCustomLovsQuery,
	useCreateCustomLovMutation,
	useGetAllCustomLovForComboQuery,
	useGetCustomLovQuery,
	useUpdateCustomLovMutation,
	useDeleteCustomLovMutation,
	useGetCustomLovForComboByIdQuery,
	useGetAllNodeDeploymentsQuery,
	useGetAllSpDeploymentsQuery,
	useCreateDeployMutation,
	useGetDeploymentDetailQuery,
	useGetAllDomainsQuery,
	useCreateDomainMutation,
	useGetAllDomainsForComboQuery,
	useGetAllDomainsFilteredBySystemTechnologyQuery,
	useGetAllDomainsFilteredBySystemTechnologyDepQuery,
	useUpdateDomainMutation,
	useDeleteDomainMutation,
	useGetColumnsByDomainQuery,
	useGetColumnsByDomainCsvQuery,
	useComputeImpactDomainChangeMutation,
	useGetEnvironmentsQuery,
	useGetEnvironmentsWithLastDeploymentQuery,
	useGetEnvironmentsWithLastDeploymentPerObjectTypeQuery,
	useGetEnvironmentByIdQuery,
	useGetInitInfoQuery,
	useGetNamingsQuery,
	useCreateNamingMutation,
	useUpdateNamingsMutation,
	useDummyNamingMutation,
	useExecuteNamingMutation,
	useExecuteNamingOverWorkingDataMutation,
	useGetNamingQuery,
	useDeleteNamingMutation,
	useGetAllObjectSettingsQuery,
	useCreateObjectSettingsMutation,
	useGetObjectSettingsByTypeQuery,
	useGetAllObjectSettingsForTargetStructureQuery,
	useGetUsedObjectSettingsQuery,
	useGetAllObjectSettingsForTargetStructureDepQuery,
	useUpdateObjectSettingsMutation,
	useDeleteObjectSettingsMutation,
	useGetPermissionsQuery,
	useGetAllRelationsByRelationTypeQuery,
	useGetStereotypeCategoriesQuery,
	useGetStereotypeTypesQuery,
	useGetStereotypeTypesForObjectSettingsQuery,
	useGetStereotypesQuery,
	useCreateStereotypeMutation,
	useGetStereotypesForTemplatesQuery,
	useGetStereotypesForTableTemplatesQuery,
	useUpdateStereotypeMutation,
	useDeleteStereotypeMutation,
	useGetSubStereotypesQuery,
	useGetRootNodesQuery,
	useCreateNodeMutation,
	useGetAllStructureTypeDtoQuery,
	useSearchFullTextQuery,
	useSearchFullTextJsonQuery,
	useImportStructureFileMutation,
	useImportStructureFileIntoNodeMutation,
	useImportStructureCsvIntoNodeMutation,
	useImportStructureCsvIntoNodeCheckMutation,
	useGenerateMassExportFileMutation,
	useSearchNodesQuery,
	useSearchStructureQuery,
	useGetSystemEnvironmentsQuery,
	useResetVersionMutation,
	useTakeOverVersionMutation,
	useGetSystemReleasesQuery,
	useCreateSystemReleaseMutation,
	useGetSystemReleaseQuery,
	useUpdateSystemReleaseMutation,
	useDeleteSystemReleaseMutation,
	useGetReleaseEnvironmentsQuery,
	useCreateReleaseEnvironmentMutation,
	useUpdateReleaseEnvironmentMutation,
	useDeleteReleaseEnvironmentMutation,
	usePushReleaseToGitMutation,
	useCreateReleasePreviewMutation,
	useCreateReleaseScriptsMutation,
	useCreateDeploymentsMutation,
	useChangeReleaseStatusMutation,
	useGetReleaseStructuresQuery,
	useAddStructureToReleaseMutation,
	useDeleteReleaseStructureMutation,
	useGetAllTablesForSystemNodeQuery,
	useGetMappingsOfSystemNodesQuery,
	useGetWorkflowsOfSystemNodeQuery,
	useGetNodeDetailQuery,
	useDeleteNodeMutation,
	useGetApiNodeColumnFromAllStructuresQuery,
	useGetNodesOfSpecificParentQuery,
	useCreateChildNodeMutation,
	useImportColumnsFromCsvMutation,
	useImportColumnsFromCsvCheckMutation,
	useEditDataNodeQuery,
	useUpdateDataNodeMutation,
	useDeleteDataNodeMutation,
	useGetDiagramQuery,
	useGetDeepDiagramQuery,
	useCreateFromSystemStructureQuery,
	useGetDiagramFromRelationQuery,
	useDuplicateNodeMutation,
	useDuplicateNodeRecursiveMutation,
	useGenerateAndPushToGitQuery,
	useGenerateSqlPreviewEnvironmentQuery,
	useGenerateSqlQuery,
	useGenerateSqlDownloadZipFileQuery,
	useExportStructureQuery,
	useGetAllNodeHistoryQuery,
	useCreateNodeHistoryMutation,
	useGetNodeHistoryDetailByVersionQuery,
	useGetNodeHistoryDetailQuery,
	useDeleteNodeHistoryMutation,
	useCreateNodeLockMutation,
	useDeleteNodeLockMutation,
	useGetMappingsQuery,
	useGenerateMassExportPushGitMutation,
	useMassPatchStructuresListMutation,
	useMoveNodeMutation,
	useReorderNodeMutation,
	usePatchMergeStructuresMutation,
	useGetStructurePermissionsQuery,
	useUpdateBatchEnvironmentsMutation,
	useGetUserPermissionQuery,
	useChangeUserPermissionMutation,
	useDeleteUserPermissionMutation,
	usePrePushValidationQuery,
	useGetNodeRelationsQuery,
	useCreateRelationMutation,
	useUpdateRelationMutation,
	useDeleteRelationMutation,
	usePathRelationMutation,
	useGenerateSqlPreviewQuery,
	useGenerateSqlPreviewDownloadZipFileQuery,
	useGetExistingStructuresForSourceMappingQuery,
	useGetAvailableStructureForSourceObjectQuery,
	useGetSystemUsersQuery,
	useGetSystemUserQuery,
	useGetStructureTreeQuery,
	useGetUniversalColumnFromAllStructuresQuery,
	useGetWriteColumnFromAllStructuresQuery,
	useGetAllSystemTechnologiesQuery,
	useSearchTableColumnsQuery,
	useSearchNodesWithTypeQuery,
	useGetAsyncTasksQuery,
	useCreateMutation,
	useGetTaskExecutionQuery,
	useCancelTaskMutation,
	useGetTaskResultFileQuery,
	useGetTaskResultQuery,
	useGetAllTechnicalColumnsQuery,
	useCreateTechnicalColumnMutation,
	useGetTechnicalColumnStereotypeQuery,
	useUpdateTechnicalColumnMutation,
	useDeleteTechnicalColumnMutation,
	useComputeImpactTcChangeMutation,
	useGetTablesByTechnicalColumnQuery,
	useGetTablesByTechnicalColumnCsvQuery,
	useGetAllTechnologiesQuery,
	useGetAllTemplatesQuery,
	useCreateTemplateMutation,
	useGetTemplateQuery,
	useUpdateTemplateMutation,
	useDeleteTemplateMutation,
	useGetTemplateHistoryQuery,
	useGetTemplateHistoryDetailQuery,
	useLockMutation,
	usePushMutation,
	useUnlockMutation,
	useGetUsersQuery,
	useAddUserMutation,
	useGetApiUsersQuery,
	useAddApiUserMutation,
	useDeleteApiUserMutation,
	useApiUserResetSecretMutation,
	useGetUsersForComboQuery,
	useGetSessionContextQuery,
	useUpdateSessionContextMutation,
	useLockUserMutation,
	useResetUserPasswordMutation,
	useUnlockUserMutation,
	useGetUserQuery,
	useEditUserMutation,
	useDeleteUserMutation,
	useResetLoginAttemptsMutation,
	useGetUserRolesQuery,
	useEditUserRolesMutation,
	useAdjustTableNameMutation,
	useCheckUniqueNameMutation,
	useCheckStructureQuery,
	useGetAllWorkflowTechnologiesQuery,
} = injectedRtkApi
