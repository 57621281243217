function normalizeToArray(input: any) {
	if (Array.isArray(input)) {
		return input
	}

	if (input != null) {
		return [input]
	}

	return []
}

interface GetSelectValue<T> {
	options: T[] | undefined
	value: any
	isMulti: boolean | undefined
	getOptionValue: (option: any) => string | null
}

export const getSelectValue = <T extends object>({
	options = [],
	value,
	isMulti,
	getOptionValue,
}: GetSelectValue<T>) => {
	if (!options.length) return null

	if (isMulti) {
		const values = normalizeToArray(value)
		const filteredOptions = options.filter((opt) =>
			values.includes(getOptionValue(opt)),
		)
		return filteredOptions.length > 0 ? filteredOptions : null
	}

	return value ? options.find((opt) => getOptionValue(opt) === value) : null
}
