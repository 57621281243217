import { MRT_Row, MRT_RowData } from 'material-react-table'

import { getDisabled } from '@/components/FieldRenderer/helper'
import { SelectWithoutForm } from '@/components/UberForm/Input/Select/Select'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'

import { MrtCellProps } from '../types'

export const SelectCell = <TData extends MRT_RowData>({
	cell,
	column,
	row,
	updateCellValue,
	isLoading,
	isMulti = false,
}: MrtCellProps<TData>) => {
	const {
		state: { editMode },
	} = useDetailTabContext()

	const customStyles = { borderWidth: '0px', background: 'none' }
	const { selectOptions, optionValue, optionLabel, isNumeric } =
		cell?.column.columnDef?.meta || {}

	const disabled = getDisabled<TData>({
		editMode,
		row,
		column,
	})

	const isFunction = typeof selectOptions === 'function'
	const options = isFunction
		? selectOptions(cell?.row as MRT_Row<TData>)
		: selectOptions

	const onChange = (value: unknown) => {
		return updateCellValue?.(row?.index as number, column?.id as string, value)
	}

	return (
		<SelectWithoutForm
			name={column?.header}
			options={options}
			onChange={onChange}
			valueKey={optionValue}
			labelKey={optionLabel}
			value={cell?.getValue()}
			customStyles={customStyles}
			disabled={disabled}
			isNumeric={isNumeric}
			isLoading={isLoading}
			isMulti={isMulti}
		/>
	)
}
