import { formFieldSwitch } from '@/components'
import { TableColumnFlat } from '@/store/modules/table/types'
import { TableColumnDefinition } from '@/types'
import { Control, FieldValues } from 'react-hook-form'
import { ItemPropertyType } from '@/enums'

interface FormFieldRendererProps<T extends object> {
	fieldProp: TableColumnDefinition<T, any>
	fieldName: string
	control: Control<FieldValues, any>
	selectOptions: TableColumnFlat[] | undefined
}

export const FormFieldRenderer = <T extends object>({
	fieldProp,
	fieldName,
	control,
	selectOptions,
}: FormFieldRendererProps<T>) => {
	// Get the specific renderer based on the field type
	const fieldType = fieldProp.type

	const FormFieldSwitch =
		formFieldSwitch[fieldType] ||
		(() => <div>Unsupported field type: {fieldType}</div>)

	const noLabels = [ItemPropertyType.RIGHT_ACTION]

	return (
		<div className="mb-4">
			{!noLabels.includes(fieldType) && (
				<div className="border-b-2 border-solid border-emerald-600 mb-4 pb-2">
					{fieldProp.header as string}
				</div>
			)}

			<FormFieldSwitch
				fieldProp={fieldProp}
				fieldName={fieldName}
				control={control}
				selectOptions={selectOptions}
			/>
		</div>
	)
}
