import { MRT_Column, MRT_Row } from 'material-react-table'

import { cn } from '@/utils/cn'

// Helper to generate a class name for unavailable options
export const getUnavailableOptionClass = (
	columnCode: string | undefined,
	invalidColumnCodes: string[] | undefined,
): string => {
	const isInvalid = invalidColumnCodes?.includes(columnCode || '')
	return cn({
		'underline decoration-wavy text-red-600': isInvalid,
	})
}

interface IsDisabledParams<TData extends object> {
	column: MRT_Column<TData> | undefined
	editMode: boolean
	row: MRT_Row<TData>
}

export const getDisabled = <TData extends object>({
	editMode,
	row,
	column,
}: IsDisabledParams<TData>): boolean => {
	const isColumnDisabled = column?.columnDef?.meta?.isDisabled
	// 1. Highest priority: Global edit mode. If not in edit mode, ALWAYS disabled.
	if (!editMode) {
		return true
	}

	// 2. Second Priority: Column-level disabled function. This overrides.
	if (isColumnDisabled && typeof isColumnDisabled === 'function') {
		return isColumnDisabled(row)
	}

	// If none of the above, it's NOT disabled
	return false
}
