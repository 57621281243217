import { Box } from '@mui/material'
import isEmpty from 'lodash/isEmpty'
import { MaterialReactTable } from 'material-react-table'
import { useCallback, useEffect, useMemo, useState } from 'react'

import {
	muiRowDragHandleProps,
	NewPanelProperties,
	PageWrap,
	TableProvider,
	TableToolbar,
} from '@/components'
import { newMappingRowPayload } from '@/constants'
import { MappingColumn } from '@/endpoints/schemas'
import {
	useAppStore,
	useResponsiveBreakpoint,
	useTable,
	useTableHandlers,
} from '@/hooks'
import { saveMapping, updateMapping } from '@/store/modules/mapping/actions'

import { useDetailTabContext } from '../../../../components/DetailTab'
import {
	mappingColInitVisibility,
	type MappingColVisibility,
} from './constants'
import { useMappingColumns, useParentTableColumns } from './hooks'
import { useMappingFormFields } from './useMappingFormFields'

export const MappingColumnTab = () => {
	const {
		state: { node },
	} = useDetailTabContext()
	const mappingsData = useAppStore(
		(state) => state.mapping.mappings[Number(node?.id)],
	)

	const [isPanelOpen, setIsPanelOpen] = useState(false)
	const [invalidColumnCodes, setInvalidColumnCodes] = useState<string[]>([])

	const parentTableColumns = useParentTableColumns()

	const { isExtraLargeScreen } = useResponsiveBreakpoint()

	const {
		columnVisibility,
		dataKey,
		deleteRow,
		handleRowClick,
		isUserInteracting,
		setColumnVisibility,
		selectedRow,
		setSelectedRow,
		setTableData,
		tableData,
		updateCellValue,
		updateTableData,
	} = useTableHandlers<MappingColumn, MappingColVisibility>({
		initialData: mappingsData?.form.columns || [],
		columnInitVisibility: mappingColInitVisibility,
		reduxActions: {
			saveAction: saveMapping,
			updateAction: updateMapping,
			updateKey: 'columns',
		},
	})

	const availableColumns = useMemo(() => {
		const selectedColumnCodes = tableData?.map(({ columnCode }) => columnCode)
		// Exclude invalid columns from availableColumns
		return parentTableColumns.filter(
			({ code }) => code && !selectedColumnCodes.includes(code),
		)
	}, [parentTableColumns, tableData])

	const updateSelectedRow = useCallback(
		(updatedRow: MappingColumn) => {
			updateTableData((draft) => {
				const rowIndex = draft.findIndex((row) => row.id === updatedRow.id)
				if (rowIndex !== -1) {
					draft[rowIndex] = { ...draft[rowIndex], ...updatedRow }
				}
			})
		},
		[updateTableData],
	)

	const formFields = useMappingFormFields({
		parentTableColumns,
		availableColumns,
		isPanelOpen,
	})

	const columns = useMappingColumns({
		availableColumns,
		deleteRow,
		handleRowClick,
		invalidColumnCodes,
		isPanelOpen,
		parentTableColumns,
		tableData,
		updateCellValue,
	})

	const table = useTable<MappingColumn>({
		columns,
		data: tableData,
		options: {
			initialState: {
				density: 'compact',
			},
			layoutMode: isPanelOpen ? 'grid' : 'semantic',
			renderBottomToolbar: () => {
				return (
					<TableToolbar<MappingColumn>
						availableColumns={availableColumns}
						updateTableData={updateTableData}
						newRowPayload={newMappingRowPayload}
						tableData={tableData}
					/>
				)
			},
			state: {
				columnVisibility,
			},
			muiRowDragHandleProps: ({ table }) =>
				muiRowDragHandleProps({ updateTableData, table }),
		},
	})

	useEffect(() => {
		if (tableData && !isEmpty(parentTableColumns)) {
			const existingCodes = tableData.map((row) => row.columnCode)
			const parentCodes = parentTableColumns.map((col) => col.code)
			const newInvalidCodes = existingCodes.filter(
				(code) => code && !parentCodes.includes(code),
			)
			setInvalidColumnCodes(newInvalidCodes)
		}
	}, [tableData, parentTableColumns])

	const isPanelOpenXl = isExtraLargeScreen && !isPanelOpen

	useEffect(() => {
		setColumnVisibility((prevState) => {
			if (!prevState) return

			return {
				...prevState,
				mappingDescription: isPanelOpenXl,
				mappingComment: isPanelOpenXl,
			}
		})
	}, [isPanelOpenXl])

	return (
		<PageWrap
			onMouseEnter={() => {
				isUserInteracting.current = true
			}}
			hasPropPanel
		>
			<TableProvider<MappingColumn>
				availableColumns={availableColumns}
				invalidColumnCodes={invalidColumnCodes}
				isPanelOpen={isPanelOpen}
				selectedRow={selectedRow}
				setSelectedRow={setSelectedRow}
				setTableData={setTableData}
				tableData={tableData}
				updateTableData={updateTableData}
			>
				<Box
					component="main"
					sx={{
						flexGrow: 1,
						transition: 'margin-right 0.3s ease',
						marginRight: isPanelOpen ? '627px' : '0px',
						zIndex: 20,
						position: 'relative',
					}}
				>
					<MaterialReactTable key={dataKey} table={table} />
				</Box>

				<NewPanelProperties<MappingColumn, MappingColVisibility>
					parentColumns={parentTableColumns}
					formFields={formFields}
					setColumnVisibility={setColumnVisibility}
					setIsPanelOpen={setIsPanelOpen}
					updateSelectedRow={updateSelectedRow}
				/>
			</TableProvider>
		</PageWrap>
	)
}
