import { CoreRow } from '@tanstack/react-table'
import { useCallback, useMemo } from 'react'

import { MappingColumn } from '@/endpoints/schemas'
import { ItemPropertyType } from '@/enums'
import { useAppContext } from '@/hooks'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'
import { TableColumnFlat } from '@/store/modules/table/types'
import { TableColumnDefinition } from '@/types'
import { generateColumnOptions } from '@/utils'

type useMappingColDefsProps = {
	availableColumns: TableColumnFlat[]
	isPanelOpen: boolean
	parentTableColumns: TableColumnFlat[]
}

export const useMappingFormFields = ({
	parentTableColumns,
	availableColumns,
	isPanelOpen,
}: useMappingColDefsProps): TableColumnDefinition<
	MappingColumn,
	TableColumnFlat
>[] => {
	const { t } = useAppContext()

	const {
		state: { editMode },
	} = useDetailTabContext()

	const getSelectOptions = useCallback(
		(row: CoreRow<MappingColumn>) =>
			generateColumnOptions(
				row?.original.columnCode || '',
				parentTableColumns,
				availableColumns,
			),
		[parentTableColumns, availableColumns],
	)

	const properties = useMemo(
		() => [
			{
				accessorKey: 'columnCode',
				header: t('MAPPING_COLUMNS_COLUMN'),
				type: ItemPropertyType.OPTION,
				options: getSelectOptions,
				optionValue: 'code',
				optionLabel: 'name',
				isNumeric: true,
				minSize: 100,
				maxSize: 150,
			},
			{
				accessorKey: 'mapping',
				header: t('MAPPING_COLUMNS_MAPPING'),
				type: ItemPropertyType.ACE_EDITOR, // Custom editor type
				size: 200,
				maxSize: isPanelOpen ? 150 : 200,
			},
			{
				accessorKey: 'outerMapping',
				header: t('MAPPING_COLUMNS_OUTER_MAPPING'),
				type: ItemPropertyType.ACE_EDITOR,
				size: 200,
			},
			{
				accessorKey: 'mappingDescription',
				header: t('MAPPING_COLUMNS_DESCRIPTION'),
				type: ItemPropertyType.TEXTAREA, // Custom: Text area input
				size: 200,
			},
			{
				accessorKey: 'mappingComment',
				header: t('MAPPING_COLUMNS_COMMENT'),
				type: ItemPropertyType.TEXTAREA,
				size: 200,
			},
			{
				accessorKey: 'keyFlag',
				header: t('MAPPING_COLUMNS_KEY_FLAG'),
				type: ItemPropertyType.CHECKBOX, // Custom: Checkbox input
				size: 70,
				disabled: !editMode, // Custom editable flag
			},
			{
				accessorKey: 'updateFlag',
				header: t('MAPPING_COLUMNS_UPDATE_FLAG'),
				type: ItemPropertyType.CHECKBOX,
				size: 70,
				disabled: !editMode,
			},
			{
				accessorKey: 'groupByFlag',
				header: t('MAPPING_COLUMNS_GROUP_BY_FLAG'),
				type: ItemPropertyType.CHECKBOX,
				size: 70,
				disabled: !editMode,
			},
			{
				accessorKey: 'rightAction',
				header: 'Actions',
				type: ItemPropertyType.RIGHT_ACTION,
				size: 70,
				maxSize: 90,
				hideFilter: true,
			},
		],
		[t, getSelectOptions, editMode],
	)

	return properties
}
