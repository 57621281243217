import { Paper, Tab, Tabs } from '@mui/material'
import { MaterialReactTable } from 'material-react-table'
import { useCallback, useEffect, useState } from 'react'
import {
	getAvailableStructureForSourceObject,
	getExistingStructuresForSourceMapping,
} from 'src/endpoints'

import {
	muiRowDragHandleProps,
	MuiTabPanel,
	TableProvider,
	TableToolbar,
} from '@/components'
import { AceEditorField } from '@/components/AceEditor/AceEditorField'
import { a11yProps } from '@/components/MuiTab/helpers'
import { newSourceItemPayload } from '@/constants'
import { useApi } from '@/endpoints/hooks'
import { SourceItem } from '@/endpoints/schemas'
import { useAppContext, useAppStore, useTable, useTableHandlers } from '@/hooks'
import { useGetNodeDetailQuery, useGetSystemUsersQuery } from '@/rtkApi'
import { saveMapping, updateMapping } from '@/store/modules/mapping/actions'
import { cn } from '@/utils/cn'

import { useDetailTabContext } from '../DetailTab/context/DetailTabContext'
import { MappingSourceTab } from './enums'
import { useSourceItemColumns } from './hooks'
import { CellLoading, FetchTrigger } from './types'

export const MappingSource = () => {
	const { t } = useAppContext()
	const mappings = useAppStore((state) => state.mapping.mappings)
	const {
		state: { node, systemNodeId, editMode },
	} = useDetailTabContext()

	const nodeId = node?.id ?? 0

	const data = mappings[nodeId]
	const sources = data?.form?.sources || []

	const [selectedTab, setSelectedTab] = useState(MappingSourceTab.Structure)
	const [fetchTrigger, setFetchTrigger] = useState<FetchTrigger | undefined>(
		undefined,
	)

	const [cellLoading, setCellLoading] = useState<CellLoading | undefined>(
		undefined,
	)

	const systemTables = useApi(
		editMode
			? getAvailableStructureForSourceObject(nodeId, 'SYSTEM')
			: getExistingStructuresForSourceMapping(nodeId, 'SYSTEM'),
	)

	const { data: systemUsers } = useGetSystemUsersQuery({
		structureId: systemNodeId,
	})

	const { data: nodeDetail, isSuccess: isNodeDetailSuccess } =
		useGetNodeDetailQuery(
			{ structureId: fetchTrigger?.structureId || -1 },
			{
				skip: !fetchTrigger?.structureId,
			},
		)

	const {
		dataKey,
		deleteRow,
		isUserInteracting,
		selectedRow,
		setSelectedRow,
		setTableData,
		tableData,
		updateTableData,
		updateCellValue,
	} = useTableHandlers<SourceItem, undefined>({
		initialData: data?.form?.sourceItems || [],
		reduxActions: {
			saveAction: saveMapping,
			updateAction: updateMapping,
			updateKey: 'sourceItems',
		},
		customUpdateCellValue: useCallback(
			(rowIndex: number, columnId: keyof SourceItem, value: unknown) => {
				if (!isUserInteracting.current || value === undefined || value === null)
					return
				let structureId: number

				if (columnId === 'objectCode' && systemTables.data) {
					const sourceStructure = systemTables?.data?.find(
						(st) => st?.code === value,
					)
					structureId = sourceStructure?.structureId || -1

					setCellLoading({ rowIndex, columnId: 'ownerId' })
					setFetchTrigger({ rowIndex, columnId, value, structureId })
				} else {
					updateTableData((draft) => {
						const draftRow = draft?.[rowIndex]
						if (!draftRow) return

						if (columnId === 'objectType') {
							draftRow.objectCustom = undefined
							draftRow.objectId = undefined
							draftRow.objectCode = undefined
						}

						if (columnId === 'ownerId' && systemUsers?.length) {
							const owner = systemUsers?.find((u) => u.id === value)

							if (owner) {
								draftRow.ownerName = owner.name
							}
						}

						if (draftRow && draftRow[columnId] !== value) {
							draftRow[columnId] = value
						}
					})
				}
			},
			[systemTables.data, systemUsers],
		),
	})

	useEffect(() => {
		if (isNodeDetailSuccess && fetchTrigger) {
			const { rowIndex, columnId, value } = fetchTrigger

			const sourceStructure = systemTables?.data?.find(
				(st) => st.code === value,
			)
			updateTableData((draft) => {
				const row = draft?.[rowIndex]
				if (!row) return

				if (columnId === 'objectCode' && sourceStructure) {
					// Use structureForm if available.
					if (nodeDetail) {
						const parsedNodeDetail = JSON.parse(nodeDetail?.data as string)

						row.ownerId = parsedNodeDetail.ownerId
						row.ownerName = parsedNodeDetail.ownerName
					}
					row.objectId = sourceStructure.structureId
				}

				if (row[columnId] !== value) {
					row[columnId] = value
				}
			})

			setCellLoading(undefined)
			setFetchTrigger(undefined)
		}
	}, [
		isNodeDetailSuccess,
		nodeDetail,
		fetchTrigger,
		systemTables,
		updateTableData,
	])

	const columns = useSourceItemColumns({
		cellLoading,
		deleteRow,
		tableData,
		updateCellValue,
	})

	const table = useTable<SourceItem>({
		columns,
		data: tableData,
		options: {
			initialState: {
				density: 'compact',
				columnPinning: { right: ['rightAction'] },
			},
			renderBottomToolbar: () => (
				<TableToolbar<SourceItem>
					updateTableData={updateTableData}
					newRowPayload={newSourceItemPayload as SourceItem}
				/>
			),
			muiRowDragHandleProps: ({ table }) =>
				muiRowDragHandleProps({ updateTableData, table }),
		},
		maxHeight: '400px',
	})

	const onTabChange = (event: any, newValue: MappingSourceTab) => {
		setSelectedTab?.(newValue)
	}

	const className = cn({
		'p-4': selectedTab === MappingSourceTab.Text,
	})

	return (
		<div
			onMouseEnter={() => {
				isUserInteracting.current = true
			}}
		>
			<Tabs
				className="mb-4"
				value={selectedTab}
				onChange={onTabChange}
				aria-label="Mapping source tabswitcher"
				textColor="secondary"
				indicatorColor="secondary"
			>
				<Tab label={t('STRUCTURED_SOURCES')} {...a11yProps(0)} />
				<Tab label={t('TEXT_SOURCES')} {...a11yProps(1)} />
			</Tabs>

			<MuiTabPanel isTable value={selectedTab as number} index={0}>
				<TableProvider<SourceItem>
					tableData={tableData}
					setTableData={setTableData}
					selectedRow={selectedRow}
					setSelectedRow={setSelectedRow}
					cellLoading={cellLoading}
					updateTableData={updateTableData}
					newRowPayload={newSourceItemPayload}
				>
					<MaterialReactTable key={dataKey} table={table} />
				</TableProvider>
			</MuiTabPanel>
			<Paper className={className} elevation={3}>
				<MuiTabPanel value={selectedTab as number} index={1}>
					<AceEditorField
						name="sources"
						title={t('MAPPING_SOURCES')}
						initialValue={sources}
						autocompleteConfig={{
							disableColumnsMasterElement: true,
						}}
					/>
				</MuiTabPanel>
			</Paper>
		</div>
	)
}
