import { MRT_Row, MRT_TableInstance } from 'material-react-table'

import { UpdateTableData } from './types'

interface MuiRowDragHandleProps<TData extends object> {
	table: MRT_TableInstance<TData>
	updateTableData: UpdateTableData<TData>
}

export const muiRowDragHandleProps = <TData extends object>({
	updateTableData,
	table,
}: MuiRowDragHandleProps<TData>) => ({
	onDragEnd: () => {
		const { draggingRow, hoveredRow } = table.getState()
		if (hoveredRow && draggingRow) {
			updateTableData((draft) => {
				return draft.splice(
					(hoveredRow as MRT_Row<TData>).index,
					0,
					draft.splice(draggingRow.index, 1)[0],
				)
			})
		}
	},
})
