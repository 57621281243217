import { createMRTColumnHelper, MRT_ColumnDef } from 'material-react-table'
import { useMemo } from 'react'

import { TextCell } from '@/components'
import { useAppContext } from '@/hooks'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'
import { useGetNodeRelationsQuery } from '@/rtkApi'
import { RelationDto } from '@/rtkApi/mddApiSliceGenerated'

import { DependenciesActions, DependenciesLinkCell } from '../components'

export const useDepColumns = () => {
	const { t } = useAppContext()
	const {
		state: { node, editMode },
	} = useDetailTabContext()

	const { refetch } = useGetNodeRelationsQuery({
		structureId: node.id,
	})

	const columnHelper = createMRTColumnHelper<RelationDto>()

	const columns = useMemo(
		() => [
			columnHelper.accessor('name', {
				header: t('NAME'),
				size: 300,
				Cell: ({ cell, table, row, column }) => (
					<TextCell
						cell={cell}
						column={column}
						table={table}
						row={row}
						isReadOnly
					/>
				),
			}),
			columnHelper.accessor('code', {
				header: t('CODE'),
				size: 300,
				Cell: ({ cell, table, row, column }) => (
					<TextCell
						cell={cell}
						column={column}
						table={table}
						row={row}
						isReadOnly
					/>
				),
			}),
			columnHelper.accessor('description', {
				header: t('DESCRIPTION'),
				size: 50,
				Cell: ({ cell, table, row, column }) => (
					<TextCell
						cell={cell}
						column={column}
						table={table}
						row={row}
						isReadOnly
					/>
				),
			}),
			columnHelper.accessor('relationType', {
				header: t('TYPE'),
				size: 50,
				Cell: ({ cell, table, row, column }) => (
					<TextCell
						cell={cell}
						column={column}
						table={table}
						row={row}
						isReadOnly
					/>
				),
			}),
			columnHelper.accessor('status', {
				header: t('STATUS'),
				size: 50,
				Cell: ({ cell, table, row, column }) => (
					<TextCell
						cell={cell}
						column={column}
						table={table}
						row={row}
						isReadOnly
					/>
				),
			}),
			columnHelper.accessor('rightSideStructure', {
				header: t('ELEMENT'),
				size: 100,
				Cell: ({ row }) => <DependenciesLinkCell row={row} node={node} />,
			}),
			columnHelper.accessor('rightSideColumn', {
				header: '',
				size: 50,
				enableColumnActions: false,
				enableSorting: false,
				Cell: ({ row }) => <DependenciesActions row={row} />,
			}),
		],
		[columnHelper, node, t],
	)

	return columns as MRT_ColumnDef<RelationDto>[]
}
