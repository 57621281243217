import { createMRTColumnHelper, MRT_ColumnDef } from 'material-react-table'
import { useMemo } from 'react'

import { MrtCellProps, RowActionsCell, SelectCell } from '@/components'
import { MappingPermission } from '@/endpoints/schemas'
import { useAppContext } from '@/hooks'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'
import { useGetSystemUsersQuery } from '@/rtkApi'

export const usePermissionsColumns = ({
	deleteRow,
	updateCellValue,
}: MrtCellProps<MappingPermission>) => {
	const { t } = useAppContext()
	const {
		state: { systemNodeId },
	} = useDetailTabContext()
	const systemUsers = useGetSystemUsersQuery({ structureId: systemNodeId })

	const columnHelper = createMRTColumnHelper<MappingPermission>()

	const permissionsOptions = [
		{
			value: 'EXECUTE',
			label: 'Execute',
		},
		{
			value: 'DEBUG',
			label: 'Debug',
		},
	]

	const columns = useMemo(
		() => [
			columnHelper.accessor('id', {
				header: t('SYSTEM_USER'),
				size: 200,
				meta: {
					selectOptions: systemUsers.data ?? [],
					optionValue: 'id',
					optionLabel: 'name',
					isNumeric: true,
				},
				Cell: ({ cell, table, row, column }) => (
					<SelectCell<MappingPermission>
						cell={cell}
						column={column}
						table={table}
						row={row}
						updateCellValue={updateCellValue}
					/>
				),
			}),
			columnHelper.accessor('permissions', {
				header: t('PERMISSIONS'),
				size: 200,
				meta: {
					selectOptions: permissionsOptions,
					isNumeric: true,
				},
				enableColumnActions: false,
				enableSorting: false,
				Cell: ({ cell, table, row, column }) => (
					<SelectCell<MappingPermission>
						cell={cell}
						column={column}
						table={table}
						row={row}
						updateCellValue={updateCellValue}
						isMulti
					/>
				),
			}),
			columnHelper.accessor('rightAction', {
				header: 'Actions',
				size: 50,
				meta: {
					hideFilter: true,
				},
				enableColumnActions: false,
				enableSorting: false,
				Cell: ({ table, row }) => (
					<RowActionsCell deleteRow={deleteRow} table={table} row={row} />
				),
			}),
		],
		[
			columnHelper,
			deleteRow,
			permissionsOptions,
			systemUsers.data,
			t,
			updateCellValue,
		],
	)

	return columns as MRT_ColumnDef<MappingPermission>[]
}
