import { MRT_RowData } from 'material-react-table'
import { useState } from 'react'

import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'
import { cn } from '@/utils/cn'

import { type MrtCellProps } from '../types'

export const TextCell = <TData extends MRT_RowData>({
	cell,
	row,
	column,
	updateCellValue,
	isReadOnly = false,
	customValue,
}: MrtCellProps<TData>) => {
	const getInitState = () => {
		if (!customValue && !cell?.getValue()) return 'N/A'

		return customValue ?? cell?.getValue()
	}

	const {
		state: { editMode },
	} = useDetailTabContext()
	const [value, setValue] = useState(getInitState)

	const className = cn('p-2 h-full w-full', {
		'disabled:text-gray-400 disabled:cursor-not-allowed': !editMode,
	})

	return (
		<input
			readOnly={isReadOnly}
			style={{ border: 'none' }}
			className={className}
			type="text"
			value={value}
			onBlur={(event) =>
				updateCellValue?.(
					row?.index as number,
					column?.id as string,
					event.target.value,
				)
			}
			onChange={(event) => setValue(event.target.value)}
			disabled={!editMode}
		/>
	)
}
