import { IdCodeName } from './general'
import { CustomAttributeForm } from '@/hooks'
import { TablePermission } from '@/rtkApi/mddApiSliceGenerated'

export interface TableData extends HistoryTableData {
	name: string
	code: string
	columns: TableColumn[]
	columnsLastId: number
	indexes: TableIndex[]
	indexesLastId: number
	constraints: TableConstraint[]
	constraintsLastId: number
	hasHistoryTable: boolean
	hasReferenceTable: boolean
	objectPermissions: TablePermission[]
	objectPermissionLastId: number
	historyTable?: HistoryTableData
	referenceTable?: HistoryTableData
	HISTORY?: HistoryTableData
}

export enum PartitioningStrategy {
	NO_PARTITION = 'no_partition',
	KEY = 'key',
	SOURCE_SYSTEM = 'source_system',
	SNAPSHOT_DATA = 'snapshot_data',
	SOURCE_AND_SNAPSHOT = 'source_and_snapshot',
	KEY_AND_SNAPSHOT = 'key_and_snapshot',
	SOURCE_AND_SNAPSHOT_AND_DATE_EFFECTIVE = 'source_and_snapshot_and_date_effective',
	KEY_AND_SNAPSHOT_AND_DATE_EFFECTIVE = 'key_and_snapshot_and_date_effective',
	SOURCE_SYSTEM_AND_VALID_TO = 'source_system_and_valid_to',
}

export interface HistoryTableData {
	name?: string
	code?: string
	description?: string
	comment?: string
	objectSettings?: IdCodeName[]
	stereotypeId?: number
	stereotypeCode?: string
	subStereotypeId?: number
	businessDomainId?: number
	businessDomainName?: string
	entityType?: TableOverview.EntityTypeEnum
	status?: TableOverview.TableStatusEnum
	retentionStrategy?: TableOverview.RetentionStrategyEnum
	columns?: TableColumn[]
	indexes?: TableIndex[]
	constraints?: TableConstraint[]
	partitioningColumnId?: number
	partitioningStrategy?: PartitioningStrategy
	partitioningColumnCode?: string
	responsiblePersonId?: number
	responsiblePersonName?: string
	responsibleAnalystId?: number
	responsibleAnalystName?: string
	nonSyncFields?: (keyof HistoryTableData)[]
	retentionStrategyFullHistoryValue?: number
	retentionStrategyFullHistoryUnit?: RetentionTimesEnum
	retentionStrategyEomValue?: number
	retentionStrategyEomUnit?: RetentionTimesEnum
	sequenceCode?: string
	sequenceColumnCode?: string
	script?: string
	preTableScript?: string
	postTableScript?: string
	ownerId?: number
	ownerName?: string
	customAttributes?: CustomAttributeForm[]
}

export interface TableColumn {
	id: number
	uuid: string
	order: number
	name: string
	code?: string
	description?: string
	comment?: string

	dataType?: string
	defaultValue?: string
	notNullFlag?: boolean

	/** true if the column is created automatically based on table stereotype */
	stereotypeColumn?: boolean
	/** true if the column is created automatically for history table */
	historyColumn?: boolean

	stereotype?: IdCodeName

	domain?: IdCodeName

	virtualColumnScript?: string

	technicalColumnId?: number

	customAttributes?: CustomAttributeForm[]
}

export interface TableIndex {
	id: number
	order: number
	name: string
	code?: string
	isUnique: boolean
	physicalOptions?: string
	description?: string
	comment?: string
	columns: TableIndexColumn[]
}

export interface TableIndexColumn {
	code: string
	order: number
	sortType: TableIndexColumn.SortTypeEnum
}

export interface TableConstraint {
	id: number
	name: string
	code: string
	description?: string
	comment?: string
	physicalOptions?: string
	enabled: boolean
	constraintType?: TableConstraint.ConstraintTypeEnum
	columns: TableConstraintColumn[]
	foreignKeyTableId?: number
	script?: string
	expression?: string
}

export interface TableConstraintColumn {
	code: string
	uuid: string
	name?: string
	foreignColumnCode?: string
	foreignColumnUuid?: string
	sortType?: TableIndexColumn.SortTypeEnum
	order: number
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace TableOverview {
	export enum EntityTypeEnum {
		AGGREGATE = 'aggregate',
		AUXILIARY = 'auxiliary',
		COMPOSITION = 'composition',
		FUNDAMENTAL = 'fundamental',
		METADATA = 'metadata',
		RELATION = 'relation',
		SNAPSHOT = 'snapshot',
		SPECIALISATION = 'specialisation',
		TRANSACTION = 'transaction',
	}

	export enum TableStatusEnum {
		LOGICAL_IN_PROGRESS = 'logical_in_progress',
		LOGICAL_COMPLETED = 'logical_completed',
		PHYSICAL_IN_PROGRESS = 'physical_in_progress',
		PHYSICAL_COMPLETED = 'physical_completed',
		FROZEN = 'frozen',
	}

	export enum RetentionStrategyEnum {
		INFINITE = 'infinite',
		KEEP_FULL_HISTORY = 'keep_full_history',
		KEEP_FULL_HISTORY_AND_EOM = 'keep_full_history_and_eom',
	}
}

export namespace TableIndexColumn {
	export enum SortTypeEnum {
		ASC = 'ASC',
		DESC = 'DESC',
	}
}

export namespace TableConstraint {
	export enum ConstraintTypeEnum {
		PRIMARY_KEY = 'primary_key',
		FOREIGN_KEY = 'foreign_key',
		CUSTOM = 'custom',
		UNIQUE = 'unique',
		CHECK = 'check',
	}
}

export enum RetentionTimesEnum {
	DAYS = 'day',
	MONTHS = 'month',
	YEARS = 'year',
}
