import React, { useState } from 'react'

import { TextArea } from '@/components/UberForm'
import { useAppContext } from '@/hooks'
import { cn } from '@/utils/cn'

import { ModalForm } from '../../Modal/ModalForm'
import { CopyTextButton } from './CopyTextButton'
import { ScriptModalCellProps } from './types'

export const TextModalCell = ({
	value,
	isDisabled,
	label,
	updateValue,
}: ScriptModalCellProps) => {
	const { t } = useAppContext()
	const [isModalOpen, setModalOpen] = useState(false)
	const [textAreaValue, setTextAreaValue] = useState<string>(value)

	const onSubmit = () => {
		setModalOpen(false)
		updateValue?.(textAreaValue.replace(/\n/g, ' '))
	}

	const onKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' && e.metaKey) {
			e.preventDefault()
			onSubmit()
		}
	}

	const className = cn('h-full w-full px-1.5 py-2', {
		'disabled:text-gray-400 disabled:cursor-not-allowed': isDisabled,
	})

	return (
		<>
			<input
				value={textAreaValue}
				style={{ border: 'none' }}
				className={className}
				onFocus={() => setModalOpen(true)}
				disabled={isDisabled}
			/>

			<ModalForm
				header={label}
				open={isModalOpen}
				onClose={() => setModalOpen(false)}
				onSubmit={onSubmit}
				hasSubmitButton={!isDisabled}
				cancelTitle={t('CANCEL')}
				footerBefore={<CopyTextButton text={textAreaValue} />}
			>
				<TextArea
					onChange={(value) => setTextAreaValue(value as string)}
					disabled={isDisabled}
					className="textInputWithoutBorder"
					value={textAreaValue}
					autoFocus={true}
					onKeyDown={onKeyDown}
					rows={17}
				/>
			</ModalForm>
		</>
	)
}
