import TextField, { TextFieldProps } from '@mui/material/TextField'
import { useEffect, useRef } from 'react'
import styled from 'styled-components'

export const AutoResizingTextFieldBase = ({ ...props }: TextFieldProps) => {
	const textFieldRef = useRef<HTMLTextAreaElement | null>(null) // Reference to the underlying textarea

	// Function to adjust height dynamically
	const adjustHeight = () => {
		const textarea = textFieldRef.current
		if (textarea) {
			textarea.style.height = '25px' // Reset height to calculate new scrollHeight
			textarea.style.height = `${textarea.scrollHeight}px` // Adjust height based on content
		}
	}

	useEffect(() => {
		adjustHeight() // Adjust the height when value changes
	}, [props.value])

	return (
		<TextField
			multiline
			inputRef={(ref) => {
				textFieldRef.current = ref // Access the underlying textarea
			}}
			{...props}
		/>
	)
}

export const AutoResizingTextField = styled(AutoResizingTextFieldBase)`
	& .MuiInputBase-formControl {
		padding: 8px;
	}
`
