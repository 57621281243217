import { Chip } from '@mui/material'
import { createMRTColumnHelper, MRT_ColumnDef } from 'material-react-table'
import { useMemo } from 'react'

import { TextCell } from '@/components'
import { useAppContext } from '@/hooks'
import { ValidationMessageDto } from '@/rtkApi/mddApiSliceGenerated'

import { getColor } from '../helpers'

export const useValidationColumns = () => {
	const { t } = useAppContext()

	const columnHelper = createMRTColumnHelper<ValidationMessageDto>()

	const columns = useMemo(
		() => [
			columnHelper.accessor('id', {
				header: t('VALIDATION_ID'),
				size: 5,
				Cell: ({ cell, table, row, column }) => (
					<TextCell
						cell={cell}
						column={column}
						table={table}
						row={row}
						isReadOnly
					/>
				),
			}),
			columnHelper.accessor('severity', {
				header: t('VALIDATION_SEVERITY'),
				size: 10,
				Cell: ({ cell }) => {
					const cellValue = cell.getValue() || ''
					const color = getColor(cellValue)

					return (
						<div className="py-1 px-2">
							<Chip label={cellValue} color={color} size="small" />
						</div>
					)
				},
			}),
			columnHelper.accessor('nodeName', {
				header: t('VALIDATION_ELEMENT'),
				size: 200,
				Cell: ({ cell, table, row, column }) => (
					<TextCell
						cell={cell}
						column={column}
						table={table}
						row={row}
						isReadOnly
						customValue={`${row.original.nodeName} (${row.original.nodeType})`}
					/>
				),
			}),
			columnHelper.accessor('elementName', {
				header: t('VALIDATION_ATTRIBUTE'),
				size: 200,
				Cell: ({ cell, table, row, column }) => (
					<TextCell
						cell={cell}
						column={column}
						table={table}
						row={row}
						isReadOnly
						customValue={`${row.original.elementName} (${row.original.elementType})`}
					/>
				),
			}),
			columnHelper.accessor('message', {
				header: t('VALIDATION_MESSAGE'),
				size: 200,
				Cell: ({ cell, table, row, column }) => (
					<TextCell
						cell={cell}
						column={column}
						table={table}
						row={row}
						isReadOnly
					/>
				),
			}),
			columnHelper.accessor('description', {
				header: t('VALIDATION_DESCRIPTION'),
				size: 200,
				Cell: ({ cell, table, row, column }) => (
					<TextCell
						cell={cell}
						column={column}
						table={table}
						row={row}
						isReadOnly
					/>
				),
			}),
		],
		[columnHelper, t],
	)

	return columns as MRT_ColumnDef<ValidationMessageDto>[]
}
