import { useEffect, useState } from 'react'

import { AceEditorField } from '@/components/AceEditor/AceEditorField'
import { ModalForm } from '@/components/Modal/ModalForm'
import { useAppContext } from '@/hooks'
import { cn } from '@/utils/cn'

import { CopyTextButton } from './CopyTextButton'
import { ScriptModalCellProps } from './types'

export const ScriptModalCell = ({
	value,
	isDisabled,
	label,
	updateValue,
}: ScriptModalCellProps) => {
	const { t } = useAppContext()
	const [isModalOpen, setModalOpen] = useState(false)
	const [aceValue, setAceValue] = useState<string>(value)

	const onSubmit = () => {
		setModalOpen(false)
		updateValue?.(aceValue)
	}

	useEffect(() => {
		setAceValue(value)
	}, [value])

	const className = cn('h-full w-full', {
		'disabled:text-gray-400 disabled:cursor-not-allowed': isDisabled,
	})

	return (
		<>
			<input
				style={{ border: 'none' }}
				className={className}
				type="text"
				onClick={() => setModalOpen(true)}
				value={aceValue}
				disabled={isDisabled}
			/>

			<ModalForm
				open={isModalOpen}
				onClose={() => setModalOpen(false)}
				onSubmit={onSubmit}
				header={label}
				contentStyle={{ width: '31.25rem' }}
				hasSubmitButton={!isDisabled}
				cancelTitle={isDisabled ? t('CANCEL') : t('CLOSE')}
				footerBefore={<CopyTextButton text={aceValue} />}
			>
				<AceEditorField
					name="scriptModal"
					value={aceValue}
					onChange={(value) => {
						setAceValue(value as string)
					}}
					onKeyPress={({ key, metaKey }) => {
						if (metaKey && key === 'Enter') {
							onSubmit()
						}
					}}
					autoFocus
					height={250}
					disabled={isDisabled}
				/>
			</ModalForm>
		</>
	)
}
