import {
	createMRTColumnHelper,
	MRT_ColumnDef,
	MRT_Row,
} from 'material-react-table'
import { useCallback, useMemo } from 'react'

import {
	MrtCellProps,
	RowActionsCell,
	ScriptEditCell,
	SelectCell,
	TextAreaCell,
} from '@/components'
import { HoverableCell } from '@/components/FieldRenderer/Fields/HoverableCell'
import { getUnavailableOptionClass } from '@/components/FieldRenderer/helper'
import { CheckboxCell } from '@/components/MrtTable/components/CheckboxCell'
import { MappingColumn } from '@/endpoints/schemas'
import { useAppContext } from '@/hooks'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'
import { generateColumnOptions } from '@/utils'

export const useMappingColumns = ({
	availableColumns,
	deleteRow,
	handleRowClick,
	invalidColumnCodes,
	isPanelOpen,
	parentTableColumns,
	tableData,
	updateCellValue,
}: MrtCellProps<MappingColumn>) => {
	const { t } = useAppContext()

	const {
		state: { editMode },
	} = useDetailTabContext()

	const getSelectOptions = useCallback(
		(row: MRT_Row<MappingColumn>) =>
			generateColumnOptions(
				row?.original.columnCode || '',
				parentTableColumns || [],
				availableColumns || [],
			),
		[parentTableColumns, availableColumns],
	)

	const columnHelper = createMRTColumnHelper<MappingColumn>()

	const properties = useMemo(
		() => [
			columnHelper.accessor('columnCode', {
				size: 250,
				maxSize: 350,
				header: t('MAPPING_COLUMNS_COLUMN'),
				meta: {
					selectOptions: getSelectOptions,
					optionValue: 'code',
					optionLabel: 'name',
					isNumeric: true,
				},
				Cell: ({ cell, table, row, column }) => {
					const className = getUnavailableOptionClass(
						row.original.columnCode,
						invalidColumnCodes,
					)

					return isPanelOpen ? (
						<HoverableCell
							className={className}
							onclick={() => handleRowClick?.(row.original)}
							value={cell.getValue()}
						/>
					) : (
						<SelectCell<MappingColumn>
							cell={cell}
							column={column}
							table={table}
							row={row}
							updateCellValue={updateCellValue}
						/>
					)
				},
			}),
			columnHelper.accessor('mapping', {
				header: t('MAPPING_COLUMNS_MAPPING'),
				size: 150,
				maxSize: 200,
				Cell: ({ cell, table, row, column }) => (
					<ScriptEditCell
						cell={cell}
						column={column}
						table={table}
						row={row}
						updateCellValue={updateCellValue}
					/>
				),
			}),
			columnHelper.accessor('outerMapping', {
				header: t('MAPPING_COLUMNS_OUTER_MAPPING'),
				size: 100,
				Cell: ({ cell, table, row, column }) => (
					<ScriptEditCell
						cell={cell}
						column={column}
						table={table}
						row={row}
						updateCellValue={updateCellValue}
					/>
				),
			}),
			columnHelper.accessor('mappingDescription', {
				header: t('MAPPING_COLUMNS_DESCRIPTION'),
				size: 100,
				Cell: ({ cell, table, row, column }) => (
					<TextAreaCell
						cell={cell}
						column={column}
						table={table}
						row={row}
						updateCellValue={updateCellValue}
					/>
				),
			}),
			columnHelper.accessor('mappingComment', {
				header: t('MAPPING_COLUMNS_COMMENT'),
				size: 100,
				Cell: ({ cell, table, row, column }) => (
					<TextAreaCell
						cell={cell}
						column={column}
						table={table}
						row={row}
						updateCellValue={updateCellValue}
					/>
				),
			}),
			columnHelper.accessor('keyFlag', {
				header: t('MAPPING_COLUMNS_KEY_FLAG'),
				size: 60,
				maxSize: 80,
				enableColumnActions: false,
				enableSorting: false,
				Cell: ({ cell, table, row, column }) => (
					<CheckboxCell<MappingColumn>
						cell={cell}
						column={column}
						table={table}
						row={row}
						updateCellValue={updateCellValue}
					/>
				),
			}),
			columnHelper.accessor('updateFlag', {
				header: t('MAPPING_COLUMNS_UPDATE_FLAG'),
				size: 60,
				maxSize: 80,
				enableColumnActions: false,
				enableSorting: false,
				Cell: ({ cell, table, row, column }) => (
					<CheckboxCell<MappingColumn>
						cell={cell}
						column={column}
						table={table}
						row={row}
						updateCellValue={updateCellValue}
					/>
				),
			}),
			columnHelper.accessor('groupByFlag', {
				header: t('MAPPING_COLUMNS_GROUP_BY_FLAG'),
				size: 60,
				maxSize: 80,
				enableColumnActions: false,
				enableSorting: false,
				Cell: ({ cell, table, row, column }) => (
					<CheckboxCell<MappingColumn>
						cell={cell}
						column={column}
						table={table}
						row={row}
						updateCellValue={updateCellValue}
					/>
				),
			}),
			columnHelper.accessor('rightAction', {
				header: 'Actions',
				size: 50,
				enableColumnActions: false,
				enableSorting: false,
				Cell: ({ table, row }) => (
					<RowActionsCell deleteRow={deleteRow} table={table} row={row} />
				),
			}),
		],
		[
			columnHelper,
			t,
			getSelectOptions,
			isPanelOpen,
			invalidColumnCodes,
			updateCellValue,
			handleRowClick,
			deleteRow,
			tableData,
			parentTableColumns,
			editMode,
		],
	)

	return properties as MRT_ColumnDef<MappingColumn>[]
}
