import {
	faChevronLeft,
	faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Drawer } from '@mui/material'
import debounce from 'debounce'
import React, { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'

import { FormFieldRenderer } from '@/components'
import { useTableContext } from '@/components/NewTable'
import {
	OpenButton,
	OpenButtonIcon,
	OpenButtonText,
	Title,
	ToggleButton,
} from '@/components/Properties/styles'
import { ItemPropertyType } from '@/enums'
import { useAppContext } from '@/hooks'
import { TableColumnFlat } from '@/store/modules/table/types'
import { TableColumnDefinition } from '@/types'
import { generateColumnOptions } from '@/utils'

interface NewPanelPropertiesProps<T extends object, TColumnVisibility> {
	formFields: TableColumnDefinition<T>[]
	parentColumns: TableColumnFlat[]
	setColumnVisibility: React.Dispatch<
		React.SetStateAction<TColumnVisibility | undefined>
	>
	setIsPanelOpen: React.Dispatch<React.SetStateAction<boolean>>
	updateSelectedRow: (updatedRow: T) => void
}

export const NewPanelProperties = <
	T extends Record<string, any>,
	TColumnVisibility,
>({
	parentColumns,
	formFields,
	setIsPanelOpen,
	updateSelectedRow,
	setColumnVisibility,
}: NewPanelPropertiesProps<T, TColumnVisibility>) => {
	const WIDTH = 650
	const { t } = useAppContext()
	const { isPanelOpen, selectedRow, availableColumns } = useTableContext()
	const { control, watch, reset } = useForm()

	const getSelectOptions = () => {
		if (selectedRow && 'columnCode' in selectedRow && availableColumns) {
			return generateColumnOptions(
				selectedRow.columnCode as string,
				parentColumns,
				availableColumns,
			)
		}
	}

	const selectOptions = useMemo(
		() => getSelectOptions(),
		[parentColumns, selectedRow],
	)

	const debouncedUpdate = debounce((formValues) => {
		updateSelectedRow(formValues)
	}, 500)

	// Monitor changes to `selectedRow` and update the form
	useEffect(() => {
		if (selectedRow) {
			reset({ ...selectedRow })
		}
	}, [selectedRow])

	useEffect(() => {
		const { unsubscribe } = watch((formValues) => {
			debouncedUpdate(formValues)
		})
		return () => unsubscribe()
	}, [watch])

	const handleOpenPanel = () => {
		setIsPanelOpen(true)
		setColumnVisibility((prevState) => {
			if (!prevState) return

			return {
				...prevState,
				outerMapping: false,
				mappingDescription: false,
				mappingComment: false,
				keyFlag: false,
				updateFlag: false,
				groupByFlag: false,
			}
		})
	}

	const handleClosePanel = () => {
		setIsPanelOpen(false)
		setColumnVisibility((prevState) => {
			if (!prevState) return

			return {
				...prevState,
				outerMapping: true,
				mappingDescription: true,
				mappingComment: true,
				keyFlag: true,
				updateFlag: true,
				groupByFlag: true,
			}
		})
	}

	const optionFields = formFields.filter(
		(prop) => prop.type === ItemPropertyType.CHECKBOX,
	)
	const otherFields = formFields.filter(
		(prop) => prop.type !== ItemPropertyType.CHECKBOX,
	)

	return (
		<>
			<OpenButton role="button">
				<div>
					<OpenButtonText onClick={handleOpenPanel}>
						{t('PROPERTIES_TITLE')}
					</OpenButtonText>
					<OpenButtonIcon>
						<FontAwesomeIcon icon={faChevronLeft} color="#888" />
					</OpenButtonIcon>
				</div>
			</OpenButton>
			<Drawer
				sx={{
					position: 'absolute',
					top: 0,
					right: 0,
					height: '100%',
					width: WIDTH,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: WIDTH,
						position: 'absolute',
						height: '100%',
					},
				}}
				variant="persistent"
				anchor="right"
				open={isPanelOpen}
			>
				<Title onClick={handleClosePanel}>
					<ToggleButton>
						<FontAwesomeIcon icon={faChevronRight} color="#888" />
					</ToggleButton>
					{t('PROPERTIES_TITLE')}
				</Title>
				{selectedRow ? (
					<div className="p-4">
						<form>
							{otherFields.map((prop) => {
								return (
									<FormFieldRenderer
										key={prop.accessorKey}
										fieldProp={prop}
										fieldName={prop.accessorKey}
										control={control}
										selectOptions={selectOptions}
									/>
								)
							})}

							{/* Wrap OPTION fields in a single div */}
							{optionFields.length > 0 && ( // Conditionally render the div if there are options
								<div className="grid grid-cols-3 gap-4 items-center">
									{optionFields.map((prop) => (
										<FormFieldRenderer
											key={prop.accessorKey}
											fieldProp={prop}
											fieldName={prop.accessorKey}
											control={control}
											selectOptions={selectOptions}
										/>
									))}
								</div>
							)}
						</form>
					</div>
				) : (
					<p className="font-bold text-amber-600 p-5">
						Please select a row to see the properties
					</p>
				)}
			</Drawer>
		</>
	)
}
