import AddIcon from '@mui/icons-material/Add'
import { Button as MuiButton } from '@mui/material'
import { isEmpty } from 'lodash'

import { TableToolbarProps } from '@/components'
import { addNewRow } from '@/components/NewTable'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'
import { StructureDto } from '@/rtkApi/mddApiSliceGenerated'

import { FillColumnsButton } from './FillColumnsButton'

export const TableToolbar = <T extends StructureDto>({
	availableColumns,
	newRowPayload,
	tableData,
	updateTableData,
}: TableToolbarProps<T>) => {
	const {
		state: { editMode },
	} = useDetailTabContext()

	const hasColumns = !isEmpty(availableColumns)
	const disabled = !editMode || !hasColumns

	return (
		<section className="flex items-center pt-4 gap-4">
			<MuiButton
				onClick={() => addNewRow<T>(updateTableData, newRowPayload)}
				startIcon={<AddIcon />}
				disabled={!editMode}
				variant="contained"
				color="primary"
			>
				New
			</MuiButton>

			<FillColumnsButton
				disabled={disabled}
				availableColumns={availableColumns}
				tableData={tableData}
				updateTableData={updateTableData}
			/>
		</section>
	)
}
