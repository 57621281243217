import { createContext, ReactNode, useContext, useMemo } from 'react'
import { Updater } from 'use-immer'

import { UpdateTableData } from '@/components'
import { CellLoading } from '@/pages/User/pages/Home/components/Source/types'
import { TableColumnFlat } from '@/store/modules/table/types'

// Define the shape of the context's value
interface TableContextProps<TData extends object> {
	availableColumns?: TableColumnFlat[]
	cellLoading?: CellLoading
	hasDnd?: boolean
	hasLastRowEdit?: boolean
	invalidColumnCodes?: string[]
	isPanelOpen?: boolean
	newRowPayload?: Record<string, any>
	selectedRow: TData | null
	setSelectedRow: React.Dispatch<React.SetStateAction<TData | null>>
	setTableData: Updater<TData[]>
	tableData: TData[]
	updateTableData?: UpdateTableData<TData>
}

const TableContext = createContext<TableContextProps<any> | undefined>(
	undefined,
)

export const useTableContext = <
	TData extends object,
>(): TableContextProps<TData> => {
	const context = useContext(
		TableContext as React.Context<TableContextProps<TData> | undefined>,
	)
	if (!context) {
		throw new Error('useTableContext must be used within a TableProvider')
	}
	return context
}

// Define the props expected by the provider
interface TableProviderProps<TData extends object> {
	availableColumns?: TableColumnFlat[]
	cellLoading?: CellLoading
	children: ReactNode
	hasDnd?: boolean
	hasLastRowEdit?: boolean
	invalidColumnCodes?: string[]
	isPanelOpen?: boolean
	newRowPayload?: Record<string, any>
	selectedRow: TData | null
	setSelectedRow: React.Dispatch<React.SetStateAction<TData | null>>
	setTableData: Updater<TData[]>
	tableData: TData[]
	updateTableData?: UpdateTableData<TData>
}

// The provider component which wraps around the part of your app that needs this context
export const TableProvider = <TData extends object>({
	availableColumns = [],
	children,
	hasDnd = false,
	hasLastRowEdit = false,
	invalidColumnCodes,
	cellLoading,
	isPanelOpen,
	selectedRow,
	setSelectedRow,
	setTableData,
	newRowPayload,
	tableData,
	updateTableData,
}: TableProviderProps<TData>) => {
	const value = useMemo(
		() => ({
			availableColumns,
			children,
			hasDnd,
			hasLastRowEdit,
			invalidColumnCodes,
			cellLoading,
			isPanelOpen,
			selectedRow,
			setSelectedRow,
			setTableData,
			newRowPayload,
			tableData,
			updateTableData,
		}),
		[
			availableColumns,
			children,
			hasDnd,
			hasLastRowEdit,
			invalidColumnCodes,
			cellLoading,
			isPanelOpen,
			selectedRow,
			setSelectedRow,
			setTableData,
			newRowPayload,
			tableData,
			updateTableData,
		], // Recalculate only when these dependencies change
	)

	return <TableContext.Provider value={value}>{children}</TableContext.Provider>
}
