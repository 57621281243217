import { MRT_RowData } from 'material-react-table'

import { TextModalCell } from '@/components/NewTable/Cell'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'

import { type MrtCellProps } from '../types'

export const TextAreaCell = <TData extends MRT_RowData>({
	cell,
	row,
	column,
	updateCellValue,
}: MrtCellProps<TData>) => {
	const {
		state: { editMode },
	} = useDetailTabContext()

	return (
		<TextModalCell
			value={cell?.getValue()}
			updateValue={(value) =>
				updateCellValue?.(row?.index as number, column?.id as string, value)
			}
			label={column?.header}
			isDisabled={!editMode}
		/>
	)
}
