import { Checkbox } from '@mui/material'
import { debounce } from 'lodash'
import { useState } from 'react'

import { MrtCellProps } from '@/components'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'

export const CheckboxCell = <TData extends object>({
	cell,
	row,
	column,
	updateCellValue,
}: MrtCellProps<TData>) => {
	const {
		state: { editMode },
	} = useDetailTabContext()

	const [checked, setChecked] = useState(cell?.getValue())

	// Debounced backend update
	const debouncedUpdateCellValue = debounce((rowIndex, columnId, value) => {
		updateCellValue?.(rowIndex, columnId, value)
	}, 300)

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.checked
		setChecked(value) // Update local state immediately for UI responsiveness
		debouncedUpdateCellValue(row?.index, column?.id, value)
	}

	return (
		<div className="flex justify-center w-full">
			<Checkbox
				style={{ height: '28px' }}
				checked={checked}
				disabled={!editMode}
				name={column?.header}
				onChange={handleChange}
			/>
		</div>
	)
}
