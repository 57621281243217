import { ReactNode, useState } from 'react'

import { NewCheckBox } from '@/components'
import { ScriptModalCell, TextModalCell } from '@/components/NewTable/Cell'
import { SelectWithoutForm } from '@/components/UberForm/Input/Select/Select'
import { ItemPropertyType } from '@/enums'
import { TableFieldSwitchProps } from './types'
import { HoverableCell } from '@/components/FieldRenderer/Fields/HoverableCell'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'
import { useTableContext } from '@/components/NewTable'
import { getUnavailableOptionClass } from '@/components/FieldRenderer/helper'
import { getDisabled } from './helper'
import { Text } from '@/components/UberForm'
import { IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

export const tableFieldSwitch: Record<
	ItemPropertyType,
	(props: TableFieldSwitchProps<any, any>) => ReactNode
> = {
	[ItemPropertyType.ACE_EDITOR]: ({ cellValue, fieldProp, tableContext }) => {
		const {
			state: { editMode },
		} = useDetailTabContext()
		const { column, row, table } = tableContext
		const updateCellValue = table?.options?.meta?.updateCellValue
		const { isPanelOpen } = useTableContext()

		const isDisabled = getDisabled({
			editMode,
			row,
			column,
		})

		return isPanelOpen ? (
			<div className="p-2 cursor-default overflow-hidden whitespace-nowrap text-ellipsis max-w-full">
				{cellValue?.toString().slice(0, 200)}
			</div>
		) : (
			<ScriptModalCell
				value={cellValue}
				isDisabled={isDisabled}
				label={fieldProp.header as string}
				updateValue={(value) => updateCellValue?.(row.index, column?.id, value)}
			/>
		)
	},
	[ItemPropertyType.TEXTAREA]: ({ cellValue, fieldProp, tableContext }) => {
		const {
			state: { editMode },
		} = useDetailTabContext()
		const { column, row, table } = tableContext
		const updateCellValue = table?.options?.meta?.updateCellValue

		return (
			<TextModalCell
				value={cellValue}
				updateValue={(value) =>
					updateCellValue?.(row?.index, column?.id, value)
				}
				label={fieldProp.header as string}
				isDisabled={!editMode}
			/>
		)
	},
	[ItemPropertyType.TEXT]: ({ cellValue, tableContext }) => {
		const {
			state: { editMode },
		} = useDetailTabContext()
		const { column, row, table } = tableContext
		const [value, setValue] = useState(cellValue)
		const updateCellValue = table?.options?.meta?.updateCellValue
		return (
			<Text
				value={value}
				onBlur={() => updateCellValue?.(row?.index, column?.id, value)}
				onChange={(inputValue) => setValue(inputValue)}
				disabled={!editMode}
			/>
		)
	},
	[ItemPropertyType.CHECKBOX]: ({ cellValue, fieldProp, tableContext }) => {
		const {
			state: { editMode },
		} = useDetailTabContext()
		const { column, row, table } = tableContext
		const updateCellValue = table?.options?.meta?.updateCellValue
		return (
			<NewCheckBox
				checked={cellValue}
				disabled={!editMode}
				onChange={(e) =>
					updateCellValue?.(row.index, column?.id, e.target.checked)
				}
				name={fieldProp.header as string}
			/>
		)
	},
	[ItemPropertyType.OPTION]: ({
		cellValue,
		fieldProp,
		tableContext,
		updateCellValue,
	}) => {
		const customStyles = { borderWidth: '0px', background: 'none' }
		const { column, row, table } = tableContext
		const handleRowClick = table?.options?.meta?.handleRowClick
		const {
			state: { editMode },
		} = useDetailTabContext()
		const { isPanelOpen, invalidColumnCodes, cellLoading } = useTableContext()

		const disabled = getDisabled({
			editMode,
			row,
			column,
		})

		const className = getUnavailableOptionClass(
			row.original.columnCode,
			invalidColumnCodes,
		)

		const fieldMetaOptions = column.columnDef.meta?.options
		const isColumnLoading = column.columnDef.meta?.isLoading
		const isFunction = typeof fieldMetaOptions === 'function'
		const options = isFunction ? fieldMetaOptions?.(row) : fieldMetaOptions
		const isCellLoading =
			cellLoading?.rowIndex === row.index && cellLoading?.columnId === column.id
		const isLoading = isColumnLoading || isCellLoading

		return isPanelOpen ? (
			<HoverableCell
				className={className}
				onclick={() => handleRowClick?.(row.original)}
				value={cellValue}
			/>
		) : (
			<SelectWithoutForm
				className={className}
				options={options}
				onChange={(value) => updateCellValue?.(row.index, column?.id, value)}
				valueKey={fieldProp.optionValue}
				labelKey={fieldProp.optionLabel}
				value={cellValue}
				customStyles={customStyles}
				clearable
				disabled={disabled}
				isNumeric={fieldProp.isNumeric}
				isLoading={isLoading}
			/>
		)
	},
	[ItemPropertyType.RIGHT_ACTION]: ({ tableContext }) => {
		const {
			state: { editMode },
		} = useDetailTabContext()
		const { row, table } = tableContext
		const deleteRow = table?.options?.meta?.deleteRow
		return (
			<div className="flex justify-center items-center">
				<IconButton
					onClick={(some) => deleteRow?.(row.original.id)}
					aria-label="remove"
					color="error"
					size="small"
					disabled={!editMode}
				>
					<DeleteIcon />
				</IconButton>
			</div>
		)
	},
}
