import { MRT_RowData } from 'material-react-table'

import { getDisabled } from '@/components/FieldRenderer/helper'
import { ScriptModalCell } from '@/components/NewTable/Cell'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'

import { type MrtCellProps } from '../types'

export const ScriptEditCell = <TData extends MRT_RowData>({
	cell,
	column,
	row,
	updateCellValue,
}: MrtCellProps<TData>) => {
	const {
		state: { editMode },
	} = useDetailTabContext()

	const isDisabled = getDisabled({
		editMode,
		row,
		column,
	})

	return (
		<ScriptModalCell
			value={cell?.getValue()}
			isDisabled={isDisabled}
			label={column?.header}
			updateValue={(value) =>
				updateCellValue?.(row?.index as number, column?.id as string, value)
			}
		/>
	)
}
