export type MappingColVisibility = typeof mappingColInitVisibility

export const mappingColInitVisibility = {
	columnCode: true,
	mapping: true,
	outerMapping: true,
	mappingDescription: true,
	mappingComment: true,
	keyFlag: true,
	updateFlag: true,
	groupByFlag: true,
	rightAction: true,
}
